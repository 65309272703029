import React, { useEffect, useRef, useState } from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
    Box,
    Button,
    Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from "@chakra-ui/react";
import {
    AiFillLeftCircle,
    AiFillRightCircle,
    AiFillCaretRight,
    AiFillCaretLeft,
    AiOutlineFilePdf,
    AiOutlineFileExcel,
    AiOutlineFileText,
} from "react-icons/ai";



import moment from 'moment'
import Card from "../../../../components/card/Card";
import { useBrandBg } from "../../../../theme/globalColorTheme";


const AccountBilling = props => {
    const brandBg = useBrandBg();
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true)
    const [months, setMonths] = useState(null)
    const [hoveredDate, setHoveredDate] = useState(null);
    const calanderContainer = useRef()
    const activeCalanderRef = useRef()
    const [selectedDates, setSelectedDates] = useState([])


    const [selectedRange, setSelectedRange] = useState([null, null]);
    const [year, setYear] = useState(new Date().getFullYear())


    useEffect(() => {
        const monthsArr = Array.from({ length: 12 }, (v, i) => new Date(year, i));
        setMonths(monthsArr)
        const today = new Date()
        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setSelectedRange([firstDayOfCurrentMonth, lastDayOfCurrentMonth])

        const monthsSelectButton = document.querySelectorAll(".react-calendar__navigation__label");
        monthsSelectButton.forEach((element, index) => {
            element.addEventListener("click", () => {
                const labelText = element.querySelector('.react-calendar__navigation__label__labelText').innerText;
                console.log(`Month clicked: `, labelText, `Index: `, index);
            });
        });
        return () => {
            monthsSelectButton.forEach((element) => {
                element.removeEventListener("click", () => { });
            });
        };

    }, [year])

    useEffect(() => {
        if (calanderContainer.current && activeCalanderRef.current && selectedRange[1]) {
            const positionOfActiveCalanderRespToParent = activeCalanderRef.current.offsetTop

            calanderContainer.current.scroll({
                top: positionOfActiveCalanderRespToParent - 15,
                behavior: "smooth"
            })
        }
        if (selectedRange[1]) {
            let dateArray = [];
            let currentDate = new Date(selectedRange[0]);
            while (currentDate <= selectedRange[1]) {
                dateArray.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1)
            }
            setSelectedDates(dateArray);
        }
    }, [selectedRange])

    const handleDateChange = (date) => {
        if (!selectedRange[0]) {
            setSelectedRange([date, null]);
        } else if (selectedRange[0] && !selectedRange[1]) {
            setSelectedRange([selectedRange[0], date]);
        } else {
            setSelectedRange([date, null]);
        }
        setHoveredDate(null);
    };

    const handleCloseRightTab = () => {
        setConfiguationCollapseFlag(!configurationCollapseFlag);
    };

    const disableOtherMonthDates = ({ date, activeStartDate }) => {
        return date.getMonth() !== activeStartDate.getMonth();
    };

    const changeYear = (increment) => {
        setYear((prevYear) => prevYear + increment);
    };

    function handleMouseHover(e) {
        const target = e.target;
        if (target.tagName.toLowerCase() === 'button') {
            const hasReactCalendarTileClass = target.classList.contains('react-calendar__tile');
            const hasReactCalendarMonthViewClass = target.classList.contains('react-calendar__month-view__days__day');

            if (hasReactCalendarTileClass && hasReactCalendarMonthViewClass) {
                let date = e.target?.firstChild?.ariaLabel
                setHoveredDate(new Date(date))
            }
        }
    }

    const isDateInHoveredRange = (date) => {
        if (!selectedRange[0] || selectedRange[1] || !hoveredDate) return false;
        return date >= selectedRange[0] && date <= hoveredDate;
    };

    const isDateInRange = (date) => {
        if (!selectedRange[0] || !selectedRange[1]) return false;
        return date >= selectedRange[0] && date <= selectedRange[1];
    };

    function handleMouseLeave() {
        setHoveredDate(null)
    }

    function handleStoreActiveCalander(node, month) {
        if (node && selectedRange[0]) {
            const monthStart = new Date(month.getFullYear(), month.getMonth(), 1);
            const monthEnd = new Date(month.getFullYear(), month.getMonth() + 1, 0);
            if (selectedRange[0] >= monthStart && selectedRange[0] <= monthEnd) {
                activeCalanderRef.current = node;
            }
        }
    };

    return (
        <Flex direction="column" >
            <Card w={"100%"} rounded={"md"} p="1px">
                <Flex h='calc(100vh - 160px)' w="100%">
                    <Box
                        w={!configurationCollapseFlag ? "95%" : { xl: "69.1%", "2xl": "78.5%" }}
                        id="calander-container"
                        bg={brandBg}
                        p="1"
                        borderRadius={"10px"}
                        overflow="hidden">
                        <Card w={"100%"} p={2} rounded={"md"} h="full">
                            <Flex w="full" justifyContent="space-between" alignItems="center" h="full">

                                <Tabs variant='enclosed' w="full" h="full">
                                    <TabList pb={2}>
                                        <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Billing Details</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Payment Details</Tab>
                                    </TabList>
                                    <TabPanels h="90%">
                                        <TabPanel bg={brandBg} h="full" rounded="lg" p={2}>
                                            <Flex direction="column" gap="2" h="full" overflow="hidden">
                                                <Flex w="full">
                                                    <Flex alignItems="center">
                                                        <Text fontWeight="bold">{moment(selectedRange[0]).format("MMM Do YY")} to {selectedRange[1] ? moment(selectedRange[1]).format("MMM Do YY") : "select end date"}</Text>
                                                    </Flex>
                                                </Flex>
                                                <Flex direction="column" gap={2} pb={"16"} overflow="auto" className="custom-scrollbar" h="90%">
                                                    {selectedDates.map(date => (
                                                        <Card>
                                                            {moment(date).format("MMM Do YY")}
                                                            <Flex>Human Interpretation:  total usage, total number of calls</Flex>
                                                            <Flex>Secure AI: total Usage, total number of calls</Flex>
                                                        </Card>
                                                    ))}
                                                </Flex>
                                            </Flex>
                                        </TabPanel>
                                        <TabPanel bg={brandBg} h="full" rounded="lg" overflow="auto" className="custom-scrollbar" p={2}>
                                            two
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Flex>
                        </Card>
                    </Box>
                    {configurationCollapseFlag ? (
                        <Flex overflow={"hidden"} flex={1}>
                            <Box minW="97%" position="relative" p="3" borderRadius="15px" bg={brandBg} ml="2" pb={7}>
                                <Flex direction="column" gap="2">
                                    <Flex alignItems="center" w="66%" justifyContent="space-between">
                                        <Text fontWeight={"500"}>Select Date</Text>
                                        <Flex gap={2} alignItems="center">
                                            <AiFillCaretLeft onClick={() => changeYear(-1)} />
                                            <Button colorScheme="blue">{year}</Button>
                                            <AiFillCaretRight onClick={() => changeYear(+1)} />
                                        </Flex>
                                        <Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
                                            <AiFillRightCircle
                                                cursor="pointer"
                                                size="20px"
                                                onClick={() => {
                                                    setConfiguationCollapseFlag(!configurationCollapseFlag);
                                                }}
                                            />
                                        </Box>
                                    </Flex>
                                    <Card p={2} ml="auto">
                                        <Flex gap={2} alignItems="center" >
                                            <Text fontWeight="bold" fontSize="small">
                                                Export Report
                                            </Text>
                                            <Button size="sm" leftIcon={<AiOutlineFilePdf />} colorScheme="red">PDF</Button>
                                            <Button size="sm" leftIcon={<AiOutlineFileText />} colorScheme="blue">CSV</Button>
                                            <Button size="sm" leftIcon={<AiOutlineFileExcel />} colorScheme="green">EXCEL</Button>
                                        </Flex>
                                    </Card>
                                </Flex>
                                <Card
                                    w="100%"
                                    h="95%"
                                    borderRadius={"10px"}
                                    mt="3"
                                    p={2}
                                >
                                    <Flex flexDirection="column" alignItems="center" ref={calanderContainer} h="100%" overflowY="auto"
                                        pb="-10"
                                        overflowX="hidden"
                                        gap={2}
                                        className="custom-scrollbar">
                                        {months && months.map((month, index) => (
                                            <Box mt={index === 0 ? "-10" : "-20"} key={index} transform={{ lg: "scale(0.6)", "2xl": "scale(0.6)" }} className="calendar-container" onMouseOver={handleMouseHover} onMouseLeave={handleMouseLeave} ref={(node) => new Date(selectedRange[0]).getMonth() === new Date(month).getMonth() ? handleStoreActiveCalander(node, month) : null}
                                            >
                                                <Calendar
                                                    value={selectedRange}
                                                    onChange={handleDateChange}
                                                    view="month"
                                                    activeStartDate={month}
                                                    selectRange={false}
                                                    tileDisabled={disableOtherMonthDates}
                                                    tileClassName={({ date }) => {
                                                        const isDisabled = disableOtherMonthDates({ date, activeStartDate: month });
                                                        if (isDisabled) {
                                                            return "react-calander_disabled-dates"
                                                        }
                                                        if (isDateInRange(date) && !isDisabled) {
                                                            return "react-calander_selected-range";
                                                        }
                                                        if (isDateInHoveredRange(date) && !isDisabled) {
                                                            return "react-calander_hovered-range";
                                                        }
                                                        return null;
                                                    }}
                                                />

                                            </Box>
                                        ))}
                                    </Flex>
                                </Card>
                            </Box>
                        </Flex>
                    ) : (
                        <Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
                            <Box w={"100%"} h="100%">
                                <AiFillLeftCircle
                                    cursor="pointer"
                                    onClick={() => {
                                        handleCloseRightTab();
                                    }}
                                    size="20px"
                                />
                                <Text
                                    fontSize="16px"
                                    mt="2"
                                    css={{
                                        writingMode: "vertical-lr",
                                        textOrientation: "mixed",
                                    }}>
                                    Select Date
                                </Text>
                            </Box>
                        </Box>
                    )}
                </Flex>
            </Card>
        </Flex>

    );
};

export default AccountBilling;
