// Chakra Imports
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Joyride, { STATUS } from 'react-joyride';
import React, { useState, useRef, useEffect, useCallback } from "react";
import {
	Box,
	Flex,
	Button,
	Icon,
	Text,
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Input,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Badge,
	Radio, RadioGroup,
	color,
	Stack,
	IconButton,
	Menu,
	MenuButton,
	MenuList,
	MenuItem
} from "@chakra-ui/react";
import AWS from "aws-sdk";

// Component
import TagsInput from "react-tagsinput";
import AudioPlayer from "../AudioPlayer";
import "react-tagsinput/react-tagsinput.css";
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import BasicAITranslateSelector from "./BasicAITranslateSelector";
import { RiSplitCellsHorizontal, RiSplitCellsVertical } from "react-icons/ri";
import {
	AiOutlineAudio,
	AiOutlineCaretRight,
	AiOutlineAudioMuted,
	AiOutlineSave,
	AiOutlineClear,
	AiFillClockCircle,
	AiOutlineHome,
	AiOutlineMail,
} from "react-icons/ai";
import { MdOutlinePermPhoneMsg } from "react-icons/md";

// API
import { addFeedBack, basicAiCallSetupEx, callCompletedEx } from "../../../../services/dynamicPageSvc";
import { lingoEmailTranscript, saveEmailTranscript } from "../../../../services/accountServices";
import { setHomeRouteFlag } from "../../../../store/actions/topNotificationActions";
import silentAudio from "../../../../assets/audio/silence_no_sound.mp3"
import FeedbackModal from "./FeedbackModal";
import { HiOutlineSpeakerWave, HiOutlineSpeakerXMark } from "react-icons/hi2";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { GrScorecard } from "react-icons/gr";

const BasicAICommDemo = props => {
	const toast = useToast();
	const dispatch = useDispatch();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure()
	const launchKey = props.launchKey ? props.launchKey : null; // this is key for dynamic_link
	const launchCode = props.launchCode ? props.launchCode : null; // code for LingoLink
	const sessionKey = props.sessionKey ? props.sessionKey : null;
	const callProviderCode = props.callProviderCode ? props.callProviderCode : null;
	const sourceLanguageCode = props?.selectedSettings?.sourceLangCode ? props?.selectedSettings?.sourceLangCode : null;
	const targetLanguageCode = props?.selectedSettings?.targetLangCode ? props?.selectedSettings?.targetLangCode : null;
	let socket = null;
	const basicAITutorialFlag = props?.basicAITutorialFlag
	const audioRef = useRef(new Audio())
	const mediaFormatRef = useRef({
		mimeType: '',
		audioEncoding: '',
		sampleRate: 0
	});
	const [participants, setParticipants] = useState([]);
	const participantsRef = useRef(participants);
	const call_control = process.env.REACT_APP_CALL_CONTROL !== '0'
	const showConfidence = process.env.REACT_APP_SHOW_CONFIDENCE !== '0'

	useEffect(() => {
		participantsRef.current = participants;
	}, [participants]);

	useEffect(() => {
		return () => toggleRecording(true)
	}, [])

	// ----- Deepgram + Anthropic -------- //
	// const [combineTranscript, setCombineTranscript] = useState([]);
	// const [outputTranscript, setOutputTranscript] = useState([]);
	const [interimTranscript, setInterimTranscript] = useState("");
	const [interimConfidence, setInterimConfidence] = useState(0);
	const [recordingState, setRecordingState] = useState(false);
	const [micDisabled, setMicDisabled] = useState(true);
	const [translatedTranscript, setTranslatedTranscript] = useState([]);
	const location = useLocation();
	const [callCompleted, setCallCompleted] = useState(false);
	const [saveButtonDisable, setSaveButtonDisable] = useState(false);
	const [value, setValue] = useState(null)
	const [comment, setComment] = useState("");
	const [rating, setRating] = useState(0);
	const [selectedComment, setSelectedComment] = useState([]);
	const [showOptionsMenu, setShowOptionsMenu] = useState(false)


	const sttSocketRef = useRef(null);
	const micRef = useRef(null);
	const micStreamRef = useRef(null)
	const targetLanguageRef = useRef("");
	const cancelRef = React.useRef()

	const [spitFlag, setSplitFlag] = useState("vertical");
	// THIS ONE VARIABLE CONTROLS WHO SPEAKS
	const [activeLanguage, setActiveLanguage] = useState("source"); // 'source' or 'target' SOURCE IS SPEAKER 1, AND TARGET IS SPEAKER 2
	const activeLanguageRef = useRef(activeLanguage);
	const [skillsKeyword, setSkillsKeyword] = useState([]);
	const [invalidEmail, setInvalidEmail] = useState([]);
	const [emailLoader, setEmailLoader] = useState(false);
	const [isTabActive, setIsTabActive] = useState(true);

	useEffect(() => {
		activeLanguageRef.current = activeLanguage;
	}, [activeLanguage]);

	// useEffect(()=>{
	// 	if(!isTabActive){
	// 		toggleRecording(true)
	// 	}
	// }, [isTabActive])

	const [sourceLanguageTranscript, setSourceLanguageTranscript] = useState([]);
	const [targetLanguageTranscript, setTargetLanguageTranscript] = useState([]);
	const [communicationFragments, setCommunicationFragments] = useState([]);
	const [autoSwitchLanguage, setAutoSwitchLanguage] = useState(false);
	const autoLanguageSpeakerRef = useRef(autoSwitchLanguage);
	const [candidateLang, setCandidateLang] = useState(targetLanguageCode)
	const muteSpeaker = useRef(false)
	const isAudioPlaying = useRef(false)
	const [btnLoading, setBtnLoading] = useState({
		submitFeedback:false
	})

	const [run, setRun] = useState(true);
	const [stepIndex, setStepIndex] = useState(0);

	function handleAddFeedBack() {
		if (launchKey != null && sessionKey != null && basicAiCallUuid != null){
			let user_comment = comment.length ? [comment] : []
			const paramsObj = {
				call_uuid: basicAiCallUuid,
				link_key: launchKey,
				link_session: sessionKey,
				rating: rating,
				comments: [...selectedComment, ...user_comment],
			}
			addFeedBack(paramsObj).then(resp => {
				if (resp?.data[0]?.status) {
					toast(toastFunctionToaster(resp?.message, "success"))
					handleOnFeedBackClose()
					return
				} else {
					toast(toastFunctionToaster(resp?.message, "error"))
				}
			}).catch(err => {
				toast(toastFunctionToaster(err?.message, "error"))
			}).finally(()=>{
				setBtnLoading(state=>({...state, submitFeedback:false}))
			})
		}
	}

	function handleOnFeedBackClose() {
		onFeedBackClose()
		setRating(0)
		setSelectedComment([])
		setComment("")
	}

	useEffect(() => {
		if (callCompleted) {
			onFeedBackOpen()
		}
	}, [callCompleted])

	const steps = [
		{
			target: '.call',
			content: 'This is the first step when clicked your call will start.',
		},
		{
			target: '.stop',
			content: 'This is to stop the call.',
		},
		{
			target: '.pause',
			content: 'This button is to pause and play the call.',
		},

		{
			target: '.time',
			content: 'This shows the total time call was on. Click "Next" to continue.',
		},
		{
			target: '.audioType',
			content: 'This returns the generated language in audio type',
		},
		{
			target: '.save',
			content: 'This step is to save the generated transccript. Click "Next" to continue.',
		},
		{
			target: '.email',
			content: 'This step is to mail the generated transccript. Click "Next" to continue.',
		},
		{
			target: '.home',
			content: 'Return Back Home.',
		},
	];

	useEffect(() => {
		autoLanguageSpeakerRef.current = autoSwitchLanguage;
	}, [autoSwitchLanguage]);

	const [targetLanguage, setTargetLanguage] = useState("");
	const [targetLanguageDisplay, setTargetLanguageDisplay] = useState(props?.selectedSettings?.targetLangCode);
	const translateInput = useRef("");
	const [buttonConnectedLoad, setButtonConnectedLoad] = useState(false);
	const intervalRef = useRef();
	const [time, setTime] = useState(0);
	const [isRunning, setIsRunning] = useState(false);
	const [demoHistoryTag, setDemoHistoryTag] = useState(true);
	const [pauseFlag, setPauseFlag] = useState(true);
	const [basicAiCallUuid, setBasicAiCallUuid] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState('+16507139055');
	const [avgConfidencePer, setAvgConfidencePer] = useState({
		avg: 0,
		count: 0
	})
	const [currentDevice, setCurrentDevice] = useState(null);
	const { isOpen: isFeedBackOpen, onOpen: onFeedBackOpen, onClose: onFeedBackClose } = useDisclosure()
	// NOTE: this is Interpretation confidence only! Translation confidence is not available yet.

	const [audioContext, setAudioContext] = useState(null);

	useEffect(() => {
		const context = new (window.AudioContext || window.webkitAudioContext)();
		setAudioContext(context);

		// Check MP3 support
		const audioElement = document.createElement('audio');
		const canPlayMP3 = audioElement.canPlayType('audio/mpeg;') !== '';
		const canPlayOggOpus = audioElement.canPlayType('audio/ogg; codecs="opus"') !== '';

		if (!canPlayMP3 && !canPlayOggOpus) {
			console.warn('This browser may not support MP3 or OGG Opus playback');
			toast({
				title: "Audio Playback Warning",
				description: "Your browser may not support audio playback. Please try a different browser.",
				status: "warning",
				duration: 5000,
				isClosable: true,
			});
		}

		// if (!canPlayMP3 && !canPlayOggOpus) {
		// 	console.warn('This browser may not support MP3 or OGG Opus playback');
		// 	toast(toastFunctionToaster("Your browser may not support audio playback. Please try a different browser.", "warning"));
		// }

		const handleDeviceChange = async () => {
			console.log("device changed")
			const devices = await navigator.mediaDevices.enumerateDevices();
			const outputDevices = devices.filter(device => device.kind === 'audiooutput');
			
			if (outputDevices.length > 0) {
			  const selectedDevice = outputDevices[0];			  
			  if (selectedDevice.deviceId !== currentDevice) {
				setCurrentDevice(selectedDevice.deviceId);
				setAudioOutputDevice(selectedDevice.deviceId);
			  }
			}
		  };
	  
		  const setAudioOutputDevice = async (deviceId) => {
			if (audioElement && typeof audioElement.setSinkId === 'function') {
			  try {
				await audioElement.setSinkId(deviceId);
				console.log(`Audio output set to device: ${deviceId}`);
				audioElement.play().catch(error => console.error('Playback error after device change:', error));
			  } catch (error) {
				console.error('Error setting audio output device:', error);
			  }
			} else {
			  console.warn('Browser does not support setSinkId for audio elements.');
			}
		  };

		navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);
		return () => {
			navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
			if (audioContext) {
			  audioContext.close();
			}
		  };
	}, [currentDevice]);

	const playAudio = async (audioBuffer) => {
		if (!audioContext) return;

		// iOS specific: resume AudioContext if it's suspended
		if (audioContext.state === 'suspended') {
			// toast(toastFunctionToaster("Suspended...", "warning"));
			await audioContext.resume();
		}
		
		const source = audioContext.createBufferSource();
		source.buffer = audioBuffer;
		source.connect(audioContext.destination);

		// // iOS specific: try to play on touch event
		// document.body.addEventListener('touchstart', function() {
		// 	audio.play().catch(e => console.error('iOS touch play failed:', e));
		// }, { once: true });
		
		source.start(0);
		source.onended = () => {
			// toast(toastFunctionToaster("ended....", "warning"));
			isAudioPlaying.current = false;
		};
	};

	// Add this useEffect to handle iOS audio unlock
	useEffect(() => {
		const unlockAudio = () => {
		if (audioContext && audioContext.state === 'suspended') {
			audioContext.resume();
		}
		document.body.removeEventListener('touchstart', unlockAudio);
		document.body.removeEventListener('touchend', unlockAudio);
		};

		document.body.addEventListener('touchstart', unlockAudio);
		document.body.addEventListener('touchend', unlockAudio);

		return () => {
		document.body.removeEventListener('touchstart', unlockAudio);
		document.body.removeEventListener('touchend', unlockAudio);
		};
	}, [audioContext]);

	// ----- Deepgram + Anthropic -------- //


	useEffect(() => {
		if (props?.selectedSettings?.sourceLangCode === "Demo" || props?.selectedSettings?.targetLangCode === "Demo") {
			setDemoHistoryTag(false);
		}
	}, [props?.selectedSettings]);

	useEffect(() => {
		if (demoHistoryTag) {
			if (targetLanguageDisplay) {
				targetLanguageRef.current = targetLanguageDisplay;
				setTargetLanguage(targetLanguageDisplay);
				setTargetLanguageDisplay(targetLanguageDisplay);
			}
			// if (sourceLanguageCode != null && callProviderCode == 21) {
			//     callSetupProcess();
			// }
		}
	}, [targetLanguageDisplay]);

	function calculateAvgPre(avgPreObj, newPer) {
		const oldAvgPer = avgPreObj?.avg
		const oldCount = avgPreObj?.count

		const newAvgPer = ((oldAvgPer * oldCount) + newPer) / (oldCount + 1)

		return {
			avg: newAvgPer,
			count: oldCount + 1
		}
	}

	function stopRecording() {
		if (micRef.current) {
			micRef.current.stop();
		}
		if (micStreamRef.current) {
			micStreamRef.current.getTracks().forEach(track => {

				track.stop()
				micStreamRef.current.removeTrack(track)
			});
		}
	}

	function getAiReportBadgeColor(num) {
		if (num > 0.95) {
			return { bg: "green.700", color: "white" };
		} else if (num <= 0.95 && num > 0.90) {
			return { bg: "green.400", color: "white" }
		} else if (num <= 0.90 && num > 0.80) {
			return { bg: "orange.400", color: "white" };
		} else if (num <= 0.80 && num >= 0.70) {
			return { bg: "red.200", color: "white" }
		} else {
			return { bg: "red.500", color: "white" }
		}
	}

	const callSetupProcess = () => {
		if (launchKey != null && launchCode != null && sessionKey != null && callProviderCode != null) {
			let paramObj = {
				feature: "bi",
				provider: callProviderCode,
				launchSession: sessionKey,
				launchKey: launchKey,
				launchCode: launchCode,
				source_language: sourceLanguageCode,
				target_language: targetLanguageCode,
			};
			basicAiCallSetupEx(paramObj)
				.then(res => {
					if (res?.data[0]?.status) {
						setBasicAiCallUuid(res?.data[0]?.uuid);
						setRecordingState(true);
						// launch2PartySocket(res?.data[0]?.uuid);
					}
				})
				.catch(err => {
					// updateLogDetails("Unable to get call started working...");
				});
		}
	};

	const callCompletedProcess = () => {
		if (launchKey != null && sessionKey != null && basicAiCallUuid != null) {
			let paramObj = {
				feature: "hi",
				provider: callProviderCode,
				callUuid: basicAiCallUuid,
				launchKey: launchKey,
				launchSession: sessionKey,
				callLog: "none",
				agentInfo: !demoHistoryTag ? "Demo Call" : '{"agent": "Lingolet Secure AI"}',
				"callSuccessStatus": true
			};
			callCompletedEx(paramObj)
				.then(res => {
					if (res?.data[0]?.status) {
						setCallCompleted(true);
						//Toast -> "You have completed Secure AI voice call with total call time: " + formatTime(time) + "."
					}
				})
				.catch(err => {
					// updateLogDetails("Unable to get call stopped correctly...");
				});
		}
	};

	AWS.config.update({
		accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
		region: process.env.REACT_APP_AWS_REGION,
	});

	const polly = new AWS.Polly();
	const [audioFile, setAudioFile] = useState();
	const [playNowState, setPlayNowState] = useState(false);

	const convertTextToSpeech = (local_text, voiceName) => {
		if (polly != null && polly != undefined) {
			polly.synthesizeSpeech(
				{
					Text: local_text,
					OutputFormat: "mp3",
					VoiceId: voiceName ? voiceName : "Salli",
				},
				(error, data) => {
					if (error) {
						//
						toast(toastFunctionToaster("Something went wrong!", "error"));
					} else {
						setPlayNowState(true);
						setAudioFile(data);
						// Show toast notification on successful conversation
						// toast(toastFunctionToaster('Text converted to speech successfully!', "success"));
					}
				}
			);
		}
	};

	async function getMicrophone() {
		try {
			const supportedFormats = [
				'audio/webm;codecs=opus',
				'audio/webm',
				'audio/ogg;codecs=opus',
				'audio/ogg',
				'audio/mp4;codecs=mp4a',
				'audio/mp4',
				'audio/mpeg',
				'audio/wav',
				'audio/aac'
			];

			let selectedMimeType = '';
			for (const format of supportedFormats) {
				const isSupported = MediaRecorder.isTypeSupported(format);

				if (isSupported && !selectedMimeType) {
					selectedMimeType = format;
				}
			}

			if (!selectedMimeType) {
				throw new Error('No supported audio format found');
			}

			const audioEncoding = selectedMimeType.split(';')[0].split('/')[1];

			const stream = await navigator.mediaDevices.getUserMedia({
				audio: true,
				video: false
			});

			micStreamRef.current = stream

			// Create AudioContext to get sample rate
			const audioContext = new (window.AudioContext || window.webkitAudioContext)();
			const source = audioContext.createMediaStreamSource(stream);
			const sampleRate = audioContext.sampleRate;

			mediaFormatRef.current = {
				mimeType: selectedMimeType,
				audioEncoding: audioEncoding,
				sampleRate: sampleRate
			};




			return new MediaRecorder(stream, { mimeType: selectedMimeType });
		} catch (error) {

			throw error;
		}
	}

	async function openMicrophone(microphone, socket) {
		return new Promise(resolve => {
			let isFirstPacket = false;  // Add this flag

			microphone.onstart = () => {

				document.body.classList.add("recording");
				resolve();
			};

			microphone.onstop = () => {

				if (socket.readyState === WebSocket.OPEN) {

				}
				document.body.classList.remove("recording");
			};

			microphone.ondataavailable = event => {
				if (isFirstPacket) {
					isFirstPacket = false;

					return;
				}
				if (event.data.size > 0 && socket.readyState === WebSocket.OPEN && isTabActive) {

					socket.send(event.data);
				}
			};
			microphone.start(1000);
		});
	}

	async function reOpenMicrophone() {
		if (micRef.current != null && micRef.current != undefined && micRef.current.state != "closed") {
			stopRecording();
			micRef.current = null;
		}
		micRef.current = await getMicrophone();
		await openMicrophone(micRef.current, sttSocketRef.current);
	}

	useEffect(() => {
		if (recordingState && pauseFlag) {
			intervalRef.current = setInterval(() => {
				setTime(prevTime => prevTime + 1);
			}, 1000);

		} else {

			clearInterval(intervalRef.current)
		}
		return () => {
			clearInterval(intervalRef.current)
		}
	}, [pauseFlag, recordingState])

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.hidden) {
				setIsTabActive(false);
			} else {
				setIsTabActive(true);
			}
		};
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	async function toggleRecording(closeSocketAndMic = false) {
		if ((recordingState == null || !recordingState) && !closeSocketAndMic) {
			setParticipants([]);
			setRecordingState(true);
			startTimer();
			callSetupProcess();
		}
		else {
			setRecordingState(false);
			stopTimer();
			callCompletedProcess();
			closeSocket(); // we need to close socket when call is completed
			stopRecording();
		}
	}

	const socketAddListener_open = async socket => {
		setButtonConnectedLoad(true);
		socket.addEventListener("open", async () => {
			setButtonConnectedLoad(false);
			// socketRef.current.setTimeout // HOW TO SET Socket timeout if there is no Data for 1 minute
			try {
				micRef.current = await getMicrophone();
				await openMicrophone(micRef.current, sttSocketRef.current);

				socket.send(JSON.stringify({
					type: "start_transcribing",
					media_format: mediaFormatRef.current
				}));
				socket.send(
					JSON.stringify({
						type: "auto_switch_language",
						value: autoLanguageSpeakerRef.current,
					})
				);
				micRef.current.resume();
				setMicDisabled(false);
			} catch (error) {

			}
		});
	};

	useEffect(() => {
		// send the message to server if socket is open, send the current mode of autoSwitchSpeaker
		if (sttSocketRef?.current && sttSocketRef.current.readyState === WebSocket.OPEN && isTabActive) {
			sttSocketRef.current.send(
				JSON.stringify({
					type: "auto_switch_speaker",
					autoSwitchSpeaker: autoLanguageSpeakerRef.current,
				})
			);
		}
	}, [autoSwitchLanguage]);

	const add_communication_fragment = data => {
		if (data.text != undefined && data.text.trim() !== "") {
			setCommunicationFragments(t => [...t, data]);
		}
	};

	// NOTE: below unused for now, but will be used when we add chat messages
	const add_communication_polyglot = data => {
		// iterate over communication fragments and find the matching entry with communication_fragment_uuid
		// if found, update the text with the new text
		for (let i = communicationFragments.length - 1; i >= 0; i--) {
			if (communicationFragments[i].communication_fragment_uuid === data.communication_fragment_uuid) {
				if (!communicationFragments[i].polyglots) {
					communicationFragments[i].polyglots = [];
				}
				communicationFragments[i].polyglots.push(data);
			}
		}
	};

	// This method is using Paras Code
	const socketAddListener_messageXXX = async socket => {
		socket.addEventListener("message", async event => {
			const data = JSON.parse(event.data);

			if (data.type === "UtteranceEnd") {

				setInterimTranscript("");
				setInterimConfidence(0);
			} else if (data.type === "Results" && data.transcript !== "") {

				if (data.is_final) {

					add_communication_fragment(data);
					// Check if there's a phone call participant and if their language matches the current audio

					const phoneCallParticipant = participantsRef.current.find(p => p.is_phone_call_participant);

					const shouldPlayAudio = !phoneCallParticipant ||
						(phoneCallParticipant && phoneCallParticipant.language !== data.communication_polyglots[0].language);

					if (shouldPlayAudio && !muteSpeaker.current && !isAudioPlaying.current) {

						console.log("called at 627")
						const audioContent = data.communication_polyglots[0].audio_content;
						const audioUrl = `data:audio/mpeg;base64,${audioContent}`;
						const audio = new Audio(audioUrl);

						audio.oncanplaythrough = function () {
							setTimeout(() => {
								audio.play();
							}, 1);
						};
						audio.addEventListener('ended', () =>{
							isAudioPlaying.current = false
						})
						audio.onerror = function (e) {

						};

						// iOS specific: try to play on touch event
						document.body.addEventListener('touchstart', function() {
							audio.play().catch(e => console.error('iOS touch play failed:', e));
						}, { once: true });

						audio.load();

					} else {

					}
					setInterimTranscript("");
					setInterimConfidence(0);
				} else {
					setInterimTranscript(data.text);
					setInterimConfidence(data.confidence);

					const avgPer = calculateAvgPre(avgConfidencePer, data?.confidence)
					setAvgConfidencePer(avgPer)
				}
			} else if (data.type === "participants_info") {

				setParticipants(data.participants);
			}
		});
	};


	// This method is using Nishant Code
	const socketAddListener_message = async socket => {
		socket.addEventListener("message", async event => {
			const data = JSON.parse(event.data);

			if (data.type === "UtteranceEnd") {

				setInterimTranscript("");
				setInterimConfidence(0);
			} else if (data.type === "Results" && data.transcript !== "") {

				if (data.is_final) {

					add_communication_fragment(data);
					// Check if there's a phone call participant and if their language matches the current audio

					const phoneCallParticipant = participantsRef.current.find(p => p.is_phone_call_participant);

					const shouldPlayAudio = !phoneCallParticipant ||
						(phoneCallParticipant && phoneCallParticipant.language !== data.communication_polyglots[0].language);

					if (shouldPlayAudio && !muteSpeaker.current && !isAudioPlaying.current) {
						console.log("called at 627-686")

						// 1. local Audio content from backend
						const audioContent = data.communication_polyglots[0].audio_content;

						// Decode base64 to ArrayBuffer
						const binaryString = atob(audioContent);
						const len = binaryString.length;
						const bytes = new Uint8Array(len);

						for (let i = 0; i < len; i++) {
							bytes[i] = binaryString.charCodeAt(i);
						}

						try {
							const audioBuffer = await audioContext.decodeAudioData(bytes.buffer);
							await playAudio(audioBuffer);
						} catch (error) {
							console.error('Error decoding audio data:', error);
							toast(toastFunctionToaster("Error playing audio. Please check your audio settings.", "error"));
							isAudioPlaying.current = false;
						}

						// 2. (Option A) - Create base 64 encoded audio url t play
						// const audioUrl = `data:audio/mpeg;base64,${audioContent}`;

						// 2. (Option B) - Creating Binary blob first and then storing audioContent 
						// const binaryString = atob(audioContent);
						// const len = binaryString.length;
						// const bytes = new Uint8Array(len);
						// for (let i = 0; i < len; i++) {
						//   bytes[i] = binaryString.charCodeAt(i);
						// }
						// 2.A - Assuming audioContent is a base64 encoded string of OGG-Opus/MP3 data
						// const blob = new Blob([bytes], { type: 'audio/ogg; codecs=opus' });

						// 2.B - Assuming audioContent is a base64 encoded string of OGG-Opus/MP3 data
						// const blob = new Blob([bytes], { type: 'audio/mpeg;' });
						// const audioUrl = URL.createObjectURL(blob);

						// 3. Creating Audio Object from Audio URL (audioUrl is collected from Step 2)
						// const audio = new Audio(audioUrl);

						// audio.oncanplaythrough = function () {
						// 	const playPromise = audio.play();
						// 	if (playPromise !== undefined) {
						// 	  playPromise.then(_ => {
						// 		console.log('Playback started successfully');
						// 	  }).catch(error => {
						// 		console.error('Audio playback failed:', error);
						// 	  });
						// 	}
						// };

						// audio.onended = function () {
						// 	isAudioPlaying.current = false;
						// 	URL.revokeObjectURL(audioUrl);
						// };

						// audio.onerror = function (e) {
						// 	console.error('Audio error:', e);
						// 	isAudioPlaying.current = false;
						// 	URL.revokeObjectURL(audioUrl);
						// };

						// // iOS specific: try to play on touch event
						// document.body.addEventListener('touchstart', function() {
						// 	audio.play().catch(e => console.error('iOS touch play failed:', e));
						// }, { once: true });

						// audio.load();

					} else {

					}

					setInterimTranscript("");
					setInterimConfidence(0);
				} else {
					setInterimTranscript(data.text);
					setInterimConfidence(data.confidence);

					const avgPer = calculateAvgPre(avgConfidencePer, data?.confidence)
					setAvgConfidencePer(avgPer)
				}
			} else if (data.type === "participants_info") {

				setParticipants(data.participants);
			}
		});
	};

	const socketAddListener_close = async socket => {
		socket.addEventListener("close", () => {

			setRecordingState(false);
			setMicDisabled(true);
			setRun(false)
			if (micRef != null && micRef != undefined) {
				if (micRef.current != null && micRef.current != undefined) {
					// send socket message to stop-transcribing
					socket.send(JSON.stringify({ type: "stop_transcribing" }));
					stopRecording();
					micRef.current = null;
				}
			}
		});
	};

	const launch2PartySocket = callUuid => {
		var localCallUuid = null;
		if (basicAiCallUuid != null) {
			localCallUuid = basicAiCallUuid;
		} else {
			localCallUuid = callUuid;
		}
		if (launchKey != null && launchCode != null && localCallUuid != null && sessionKey != null && sourceLanguageCode != null && demoHistoryTag) {
			let socket_url = "";
			if (window.location.hostname.includes("localhost")) {
				// Suraj > "wss://lingolet.ai/api/stt";
				// socket_url = "wss://lingolet.ai/api/stt?key=" + launchKey + "&code=" + launchCode + "&session=" + sessionKey + "&langCode=" + sourceLanguageCode;
				socket_url =
					"ws://localhost:9110/api/stt_twoparty?key=" +
					launchKey +
					"&code=" +
					launchCode +
					"&session=" +
					sessionKey +
					"&sourceLanguageCode=" +
					sourceLanguageCode +
					"&targetLanguageCode=" +
					targetLanguageCode +
					"&basicAiCallUuid=" +
					basicAiCallUuid
					;
			} else {
				// stt > single Party
				socket_url =
					"wss://" +
					window.location.hostname +
					"/api/stt_twoparty?key=" +
					launchKey +
					"&code=" +
					launchCode +
					"&session=" +
					sessionKey +
					"&sourceLanguageCode=" +
					sourceLanguageCode +
					"&targetLanguageCode=" +
					targetLanguageCode +
					"&basicAiCallUuid=" +
					basicAiCallUuid
					;
			}
			socket = new WebSocket(socket_url);
			sttSocketRef.current = socket;
			if (recordingState !== null) {
				if (recordingState) {
					setActiveLanguage("source");
					socketAddListener_open(socket);
					socketAddListener_message(socket);
					socketAddListener_close(socket);
				} else {
					closeSocket();
				}
			} else {
				toast(toastFunctionToaster("Critical communication error, please contact support team.", "error"));
			}
		}
	};

	function playSilentAudio() {
		audioRef.current.src = silentAudio
		audioRef.current.load();
		audioRef.current.oncanplaythrough = function () {
			setTimeout(() => {
				audioRef.current.play();
			}, 1);
		};
		audioRef.current.onerror = function (e) {

		};
	}

	useEffect(() => {
		launch2PartySocket();
	}, [basicAiCallUuid]);


	// useEffect(() => {
	//     
	//     if (launchKey != null && launchCode != null
	//             && basicAiCallUuid != null
	//             && sessionKey != null && sourceLanguageCode != null && demoHistoryTag) {
	//         let socket_url = ''
	//         if (window.location.hostname.includes('localhost')) {
	//             // Suraj > "wss://lingolet.ai/api/stt";
	//             // socket_url = "wss://lingolet.ai/api/stt?key=" + launchKey + "&code=" + launchCode + "&session=" + sessionKey + "&langCode=" + sourceLanguageCode;
	//             socket_url = "ws://localhost:9110/api/stt_twoparty?key=" + launchKey + "&code=" + launchCode + "&session=" + sessionKey + "&sourceLanguageCode=" + sourceLanguageCode+"&targetLanguageCode="+targetLanguageCode;

	//         } else {
	//             // stt > single Party
	//             socket_url = "wss://" + window.location.hostname + "/api/stt_twoparty?key=" + launchKey + "&code=" + launchCode + "&session=" + sessionKey + "&sourceLanguageCode=" + sourceLanguageCode+"&targetLanguageCode="+targetLanguageCode;
	//         }
	//         socket = new WebSocket(socket_url);
	//         sttSocketRef.current = socket;
	//         if (recordingState !== null) {
	//             if (recordingState) {
	//                 setActiveLanguage('source')
	//                 socketAddListener_open(socket);
	//                 socketAddListener_message(socket);
	//                 socketAddListener_close(socket)
	//             }
	//             else {
	//                 closeSocket()
	//             }
	//         }
	//     }
	//     else if(!demoHistoryTag) {

	//     }
	//     else {
	//         toast(toastFunctionToaster('Critical communication error, please contact support team.', "error"));
	//     }
	// }, [recordingState]);

	const handleSelectedAudio = data => {
		if (data) {
			const translatedLocalText = translatedTranscript[translatedTranscript?.length - 1];
			convertTextToSpeech(translatedLocalText, data);
		}
	};

	// Todo:When Url is changes socket is closed
	// useEffect(() => {
	//     closeSocket()
	// }, [location]);

	const closeSocket = () => {
		setRun(false)
		if (sttSocketRef?.current != null && sttSocketRef?.current !== undefined) {
			sttSocketRef.current.close();
		}
	};

	// Step 2: Create a function that toggles the active language
	const toggleActiveLanguage = () => {
		setActiveLanguage(prevLanguage => (prevLanguage === "source" ? "target" : "source"));
	};
	const getActiveSourceLanguageCode = () => {
		return activeLanguageRef.current === "source" ? sourceLanguageCode : targetLanguageCode;
	};
	const getActiveTargetLanguageCode = () => {
		return activeLanguageRef.current === "target" ? sourceLanguageCode : targetLanguageCode;
	};
	useEffect(() => {
		const updateLanguage = async () => {
			if (sttSocketRef?.current && sttSocketRef.current.readyState === WebSocket.OPEN && isTabActive) {

				if (pauseFlag) {
					await reOpenMicrophone();
				}
				sttSocketRef.current.send(
					JSON.stringify({
						type: "change_language",
						language: getActiveSourceLanguageCode(),
						target_language: getActiveTargetLanguageCode(),
					})
				);
			}
		};

		updateLanguage();
	}, [activeLanguage]);


	const makeCall = (phoneNumber) => {
		if (sttSocketRef.current && isTabActive) {

			sttSocketRef.current.send(JSON.stringify({
				type: "add_call_participant_request",
				'phone_number': phoneNumber,
				'language': candidateLang,
				'target_language': candidateLang === getActiveSourceLanguageCode() ? getActiveTargetLanguageCode() : getActiveSourceLanguageCode()
			}));
			setPhoneNumber('');
		}
	};
	// ------ Code main Starts here

	// Time Start Stop Function
	const startTimer = () => {
		resetTimer();
		if (!isRunning) {
			setIsRunning(true);
		}
	};

	const stopTimer = () => {
		clearInterval(intervalRef.current);
		setIsRunning(false);
		// Todo:Once call is hangup reset timer
	};

	const resetTimer = () => {
		clearInterval(intervalRef.current);
		setIsRunning(false);
		setTime(0);
	};

	const formatTime = timeInSeconds => {
		const minutes = Math.floor(timeInSeconds / 60)
			.toString()
			.padStart(2, "0");
		const seconds = (timeInSeconds % 60).toString().padStart(2, "0");
		return `${minutes}:${seconds}`;
	};
	// ------ Code main Ends here

	//   Todo:Set home button route to primary page by updating redux value to dynamic home
	const handleHomeRouteFlag = () => {
		dispatch(setHomeRouteFlag(true));
	};

	//   Todo:Play Pause function
	const pauseTranslationCall = () => {
		if (pauseFlag) {
			micRef.current.pause();
			setPauseFlag(!pauseFlag);
		} else {
			setMicDisabled(true);
			micRef.current.resume();
			setPauseFlag(!pauseFlag);
		}
	};

	const renderTranscript = () => {
		const transformedString = transformData(communicationFragments);
		return transformedString;
	};

	const transformData = (data) => {
		return data.map(item => {
			const sourceText = item?.text || "";
			const targetLanguage = item?.communication_polyglots[0]?.language;
			const targetText = (item?.communication_polyglots.find(polyglot => polyglot?.language === targetLanguage) || {}).text || "";
			return `${item?.language}: ${sourceText}<br>${targetLanguage}: ${targetText}<br>`;
		}).join('<br>');
	};

	const saveTranscript = () => {
		const letObj = {
			linkKey: launchKey,
			linkSession: sessionKey,
			languages: `${sourceLanguageCode},${targetLanguageCode}`,
			transcript_text: JSON.stringify(communicationFragments),
			call_uuid: basicAiCallUuid,
		};
		saveEmailTranscript(letObj)
			.then(res => {
				if (res?.data[0]?.status) {
					toast(toastFunctionToaster("The transcript is saved.", "success"));
					setSaveButtonDisable(true);
				} else {
					toast(toastFunctionToaster("Unable to save the transcripts...", "error"));
				}
			})
			.catch(err => {

			});
	};

	const emailTranscript = () => {
		setEmailLoader(true);
		const letObj = {
			linkKey: launchKey,
			linkSession: sessionKey,
			languages: `${sourceLanguageCode}${targetLanguageCode}`,
			transcript_text: renderTranscript(),
			call_uuid: basicAiCallUuid,
			emails: skillsKeyword.join(", "),
		};

		lingoEmailTranscript(letObj)
			.then(res => {
				if (res?.data[0]?.status) {
					setEmailLoader(false);
					toast(toastFunctionToaster("The transcript is emailed as suggested.", "success"));
					onClose();
				} else {
					toast(toastFunctionToaster("Unable to email the transcript, due to some error..", "error"));
				}
			})
			.catch(err => {
				setEmailLoader(false);
				toast(toastFunctionToaster("Unable to email the transcript, due to some error..", "error"));
			});
	};

	const onTagChange = tags => {
		setSkillsKeyword(tags);
	};

	const validateEmails = emails => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emails.filter(email => !emailRegex.test(email));
	};

	useEffect(() => {
		setInvalidEmail(validateEmails(skillsKeyword));
	}, [skillsKeyword]);

	useEffect(() => {
		const transformedData = communicationFragments.map(item => {
			const englishText = item.text;
			const sourceLang = item.communication_polyglots[0].language;
			const hindiText = item.communication_polyglots[0].text;
			const confidence = item.confidence;
			return { [item.language]: englishText, sourceLang, hindiText, confidence };
		});

	}, [communicationFragments]);

	// Todo:Test with multipled scenarios
	const handleJoyrideCallback = useCallback((data) => {
		const { status, index, type } = data;
		const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

		if (finishedStatuses.includes(status)) {
			setRun(false);
		} else if (type === 'step:after') {
			if (index === 0) {
				setRun(false);  // Stop the tour after the last step
			} else if (index === 2) {
				setRun(false);  // Stop the tour after the last step
			}
			else {
				setStepIndex(index + 1);
			}
		}
	}, [steps.length]);

	const handleStartTour = () => {
		setRun(true);
		setStepIndex(0);
	};

	const handleStopTour = () => {
		setRun(false);
	};

	const handleNextStep = () => {
		setRun(true);
		setStepIndex(prevIndex => prevIndex + 1);
	};

	return (
		<>
			{basicAITutorialFlag ? (
				<Joyride steps={steps} run={run} stepIndex={stepIndex} callback={handleJoyrideCallback} continuous={true} showSkipButton={false} styles={{ options: { zIndex: 10000 }, }} />
			) : null}
			{/* {!run && (
			<button onClick={handleNextStep} className="continue-button">
			Continue
			</button>
		)} */}
			<Flex mt={0}>
				<Flex w="100%" justifyContent={"center"} mt={0}>
					<Flex borderRadius={"10px"} color="#fff" alignItems={"center"} bg="#000" p="1" h={'40px'}>
						{recordingState === false && (
							<Text>
								{callCompleted ? 'Call Completed (' + formatTime(time) + ") Seconds" : 'Welcome to LingoLink'}
							</Text>
						)}
						{/* <Flex borderWidth={"0.5px"} borderRadius={"5px"} bg="#000">
							{spitFlag === "horizontal" ? (
								<Icon
									w="50px"
									cursor={"pointer"}
									fontSize="30px"
									color="#42aa42"
									as={RiSplitCellsVertical}
									onClick={() => {
										setSplitFlag("vertical");
									}}
								/>
							) : (
								<Icon
									w="50px"
									cursor={"pointer"}
									fontSize="30px"
									color="#42aa42"
									as={RiSplitCellsHorizontal}
									onClick={() => {
										setSplitFlag("horizontal");
									}}
								/>
							)}
						</Flex> */}
						{recordingState && (
							<IconButton 
								color="black" 
								fontSize="20px"
								icon={ spitFlag === "vertical" ? <RiSplitCellsHorizontal /> : <RiSplitCellsVertical />} 
								onClick={() => {
									spitFlag === "vertical" ? setSplitFlag("horizontal") : setSplitFlag("vertical")
								}}
							/>
						)}
						{/* {recordingState && time > 0 ? (
							<>
								<Flex className="time" h="100%" ml={1} rounded={"md"} minW={"80px"} maxW={"80px"} bg={"#bee3f8"} color="#000" align={"center"} display={{sm:"none", md:'flex'}}>
									<Icon as={AiFillClockCircle} ml={1} mr={1} h={5} w={5} />
									{formatTime(time)}
								</Flex>
							</>
						) : null} */}
						{/* <Flex>
							<Box p={1} className="audioType">
								<BasicAITranslateSelector targetLanguage={targetLanguage} returnSelectedAudioType={handleSelectedAudio} />
							</Box>
						</Flex> */}
						{recordingState && (
							<Button
								size={"sm"}
								p="10px"
								ml={2}
								borderRadius={"5px"}
								mr="3"
								onClick={toggleActiveLanguage}
								colorScheme="cyan"
							>
								{getActiveSourceLanguageCode()} To: {getActiveTargetLanguageCode()}
							</Button>
						)}
						{recordingState && 
							<IconButton 
								fontSize="20px"
								color="black" 
								icon={muteSpeaker.current ? <HiOutlineSpeakerXMark /> : <HiOutlineSpeakerWave />} 
								onClick={() => muteSpeaker.current = !muteSpeaker.current} 
							/>
						}
					</Flex>
				</Flex>
			</Flex>
			<Box overflow={"hidden"} bgColor="#252628">
				<Box bg="#fff" h="calc(100vh - 194px)" >
					<AudioPlayer playNow={playNowState} audioFile={audioFile} />
					<Flex justifyContent={"space-between"} flexWrap="wrap" h="100%" border="1px solid #efefef">
						<Box
							w={{
								base: spitFlag === "vertical" ? "49%" : "100%",
								md: spitFlag === "vertical" ? "49%" : "100%",
								lg: spitFlag === "vertical" ? "49%" : "100%",
								xl: spitFlag === "vertical" ? "49%" : "100%",
							}}
							borderRightWidth={"1px"}
							p="10px"
							borderColor="#efefef"
							borderBottomWidth={spitFlag === "horizontal" ? "1px" : "0px"}
						>
							<Box
								// h="100%"
								overflow="auto"
								className="custom-scrollbar"
								mt="3"
							>
								<Text color="#000" fontSize={"14px"}>
									{communicationFragments?.map((item, index) => (
										<Flex key={index} direction={spitFlag === "horizontal" ? "column" : "row"}>
											{(item.language === sourceLanguageCode || spitFlag === "horizontal") && (
												<Text>
													<Icon mr="1" as={AiOutlineCaretRight} />
													{item.language}: {item.text}
													{showConfidence && item.confidence && typeof item.confidence === 'number' ?
														` (${(item.confidence * 100).toFixed(2)}%)` :
														''
													}
												</Text>
											)}
											{item.communication_polyglots?.map(
												(polyglot, index) =>
													(polyglot.language === sourceLanguageCode || spitFlag === "horizontal") && (
														<Text key={index} direction="row">
															{spitFlag !== "vertical" && (
																<span>
																	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
																	<Icon mr="1" as={AiOutlineCaretRight} />
																</span>
															)}
															{polyglot.language}: {polyglot.text}
														</Text>
													)
											)}
										</Flex>
									))}
								</Text>
								{(activeLanguage === "source" || spitFlag === "horizontal") && interimTranscript ? (
									<Text color="#c6d704" fontSize={"14px"}>
										{interimTranscript} {showConfidence ? `${(interimConfidence * 100).toFixed(2)}%` : ''}
									</Text>
								) : null}
							</Box>
						</Box>
						<Box
							w={{
								base: spitFlag === "vertical" ? "49%" : "100%",
								md: spitFlag === "vertical" ? "49%" : "100%",
								lg: spitFlag === "vertical" ? "49%" : "100%",
								xl: spitFlag === "vertical" ? "49%" : "100%",
							}}
							p="10px"
						>
							<Box
								// // h="calc(100vh - 175px)"
								// h="100%"
								bg={'red.100'}
								overflow="auto"
								className="custom-scrollbar"
								mt="3"
							>
								{spitFlag == "vertical" && (
									<Text color="#000" fontSize={"14px"}>
										{communicationFragments?.map((item, index) => (
											<Flex key={index}>
												<Icon mr="1" as={AiOutlineCaretRight} />
												{item.language === targetLanguageCode && (
													<Text>
														{item.language}: {item.text}
														{showConfidence && item.confidence && typeof item.confidence === 'number' ?
															` (${(item.confidence * 100).toFixed(2)}%)` :
															''
														}
													</Text>
												)}
												{item.communication_polyglots?.map(
													(polyglot, index) =>
														polyglot.language === targetLanguageCode && (
															<Text key={index}>
																{polyglot.language}: {polyglot.text}
															</Text>
														)
												)}
											</Flex>
										))}
									</Text>
								)}
								{activeLanguage === "target" && spitFlag !== "horizontal" && interimTranscript ? (
									<Text color="#c6d704" fontSize={"14px"}>
										{interimTranscript} - {showConfidence ? `${(interimConfidence * 100).toFixed(2)}%` : ''}
									</Text>
								) : null}
							</Box>
						</Box>
					</Flex>
				</Box>
				{callCompleted ? (
					<Flex 
						alignItems={"center"} justifyContent={"center"} p={2} className="pause-step" bg={"blue.100"} gap='2'>
						<Flex bg={"blue.200"} rounded={'lg'} borderWidth={'0.5px'} borderColor={'blue.300'} p={1}>
							<Button
								ml={1}
								isDisabled={saveButtonDisable ? true : false}
								size="sm"
								p="10px"
								mr={1}
								borderRadius={"5px"}
								onClick={e => {
									saveTranscript();
								}}
								colorScheme="cyan"
								leftIcon={<Icon as={AiOutlineSave}
								/>}
							>
								<Text
									as="span"
									color={'gray.700'}
									className="save"
								>
									Save
								</Text>
							</Button>
							<Box m={0}>
								<Menu placement="top">
									<MenuButton
										size={'sm'}
										bg={'#0bc5ea'}
										// colorScheme="blue"
										// colorScheme="cyan"
										as={IconButton}
										aria-label='Options'
										icon={showOptionsMenu ? <MinusIcon /> : <AddIcon />}
										variant='outline'
										onClick={() => setShowOptionsMenu(!showOptionsMenu)}
									/>
									<MenuList>
										<MenuItem 
											_hover={{bg:'blue.100'}}
											onClick={() => {
											setCommunicationFragments([]);
											setInterimTranscript("");
											setInterimConfidence(0);
											setShowOptionsMenu(false); 
										}} icon={<AiOutlineClear />}>
											Clear
										</MenuItem>
										<MenuItem 
											_hover={{bg:'blue.100'}}
											icon={<AiOutlineHome />} onClick={() => {
											handleHomeRouteFlag();
											setShowOptionsMenu(false); 
										}}>
											Home
										</MenuItem>
										<MenuItem 
											_hover={{bg:'blue.100'}}
											icon={<GrScorecard />} onClick={() => {
											onAlertOpen();
											setShowOptionsMenu(false); 
										}}>
											AI Report
										</MenuItem>
									</MenuList>
								</Menu>

							</Box>
							<Button
								size="sm"
								p="10px"
								ml={1}
								borderRadius={"5px"}
								mr="2"
								onClick={e => {
									onOpen();
								}}
								colorScheme="cyan"
								leftIcon={<Icon as={AiOutlineMail} />}
							>
								<Text
									as="span"
									color={'gray.700'}
									className="email"
								>
									Email
								</Text>
							</Button>
						</Flex>
					</Flex>
				) : (
					<Flex flexDir="column" gap={2} bg={"red.50"}>
					{/* {recordingState && (
							<Flex flexWrap="wrap" gap={2} w="full" justifyContent="center">
								{props.securePhoneSupport && call_control && participants.length <= 1 && (
									<Flex direction="column" alignItems="flex-start" gap={2} pt={2}>
										<Flex direction="row" ml={1} alignItems="center">
											<Input
												placeholder="Enter phone number"
												value={phoneNumber}
												onChange={(e) => setPhoneNumber(e.target.value)}
												size="sm"
												width="130px"
											/>
											<Button
												colorScheme="blue"
												size="sm"
												ml={2}
												onClick={() => {
													if (phoneNumber) {
														makeCall(phoneNumber);
													}
												}}
											>
												Add {getActiveTargetLanguageCode()} Speaker
											</Button>
										</Flex>
									</Flex>
								)}
								{participants.some(participant => participant.is_phone_call_participant) && (
									<Flex direction="row" ml={3} alignItems="center">
										{participants
											.filter(participant => participant.is_phone_call_participant)
											.map((participant, index) => (
												<Text key={index}>{participant.phone_number} - {participant.call_status}</Text>
											))
										}
									</Flex>
								)}
							</Flex>
						)} */}
					<Flex justifyContent={"center"} bg={"red.50"} p={2} flexWrap="wrap" gap="2">
						{/* {basicAiCallUuid != null */}
						<Flex p={1} bg={'blue.100'} rounded={'lg'} borderColor={'blue.100'} borderWidth={'0.5px'}>
							<Button
								size={{
									base: "xs",
									md: "sm",
									lg: "sm",
									xl: "sm",
								}}								
								p="10px"
								borderRadius={"5px"}
								onClick={() => {
									playSilentAudio()
									toggleRecording();
									setRun(true);
									setStepIndex(prevIndex => prevIndex + 1);
								}}
								colorScheme={recordingState ? "red" : "green"}
								// isDisabled={micDisabled || !targetLanguageRef.current}
								isDisabled={buttonConnectedLoad}
								leftIcon={<Icon as={recordingState ? AiOutlineAudioMuted : AiOutlineAudio} />}
							>
								<Text
									className={recordingState ? 'stop' : 'call'}
									as="span"
									fontSize={{sm:"smaller", md:"md"}}
								>
									{recordingState ? "Stop " : "Start "} Call
								</Text>
							</Button>
							{recordingState && (
							<>
								<Flex ml={1} mr={1} p={0} pl={1} py={0}
									borderWidth={'0.5px'} borderColor={'blue.500'}
									rounded={"md"} minW={"50px"} maxW={"50px"} bg={'blue.200'}
									color="#000" align={"center"} 
									display={{sm:"flex", md:'flex'}}>
									{/* <Icon as={AiFillClockCircle} ml={1} mr={1} h={5} w={5} /> */}
									{formatTime(time)}
								</Flex>
							</>
							)}
							{recordingState && 
								<Box m={0}>
									{props.securePhoneSupport && call_control && participants.length <= 1 && (
										<Menu placement="top">
											<MenuButton
												ml={1}
												size={'sm'}
												bg={'#0bc5ea'}
												as={IconButton}
												aria-label='Options'
												icon={<MdOutlinePermPhoneMsg />}
												//icon={showOptionsMenu ? <MinusIcon /> : <AddIcon />}
												variant='outline'
												onClick={() => setShowOptionsMenu(!showOptionsMenu)}
											/>
											<MenuList>
											<Flex direction="row" ml={1} alignItems="center">
												<Input
													placeholder="Enter phone number"
													value={phoneNumber}
													onChange={(e) => setPhoneNumber(e.target.value)}
													size="sm"
													width="130px"
												/>
												<Button
													colorScheme="blue"
													size="sm"
													ml={2}
													onClick={() => {
														if (phoneNumber) {
															makeCall(phoneNumber);
														}
													}}
												>
													Add {getActiveTargetLanguageCode()} Speaker
												</Button>
											</Flex>												
											</MenuList>
										</Menu>
									)}
									{participants.some(participant => participant.is_phone_call_participant) && (
										<Menu placement="top">
											<MenuButton
												ml={1}
												size={'sm'}
												bg={'#0bc5ea'}
												as={IconButton}
												aria-label='Options'
												icon={<MdOutlinePermPhoneMsg />}
												//icon={showOptionsMenu ? <MinusIcon /> : <AddIcon />}
												variant='outline'
												onClick={() => setShowOptionsMenu(!showOptionsMenu)}
											/>
											<MenuList>
												<Flex direction="row" ml={3} alignItems="center">
													{participants
														.filter(participant => participant.is_phone_call_participant)
														.map((participant, index) => (
															<Text key={index}>{participant.phone_number} - {participant.call_status}</Text>
														))
													}
												</Flex>
											</MenuList>
										</Menu>
									)}
								</Box>
							}
							{recordingState && <Button
								ml={1}
								w={'100px'}
								size={{
									base: "xs",
									md: "sm",
									lg: "sm",
									xl: "sm",
								}}
								p="10px"
								borderRadius={"5px"}
								mr="1"
								colorScheme={pauseFlag ? "red" : "green"}
								leftIcon={<Icon as={pauseFlag ? AiOutlineAudioMuted : AiOutlineAudio} />}
								onClick={() => {
									pauseTranslationCall();
								}}
								className="pause"
							>
								{pauseFlag ? "Pause" : "Resume"}
							</Button>}
							{/* :<Text p={2}>Call Completed</Text>} */}
						</Flex>
					</Flex>
					</Flex>
				)}
				{/* Popup Modal */}
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Send Email</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Text>Please use enter to multiple emails in the textbox.</Text>
							<TagsInput value={skillsKeyword} onChange={onTagChange} inputProps={{ placeholder: "Enter Email" }} />
							<Flex flexWrap="wrap" alignItems={"center"} mt="2">
								{invalidEmail.map((email, index) => (
									<Text p="1" mr="1" bg="red.100" color="#000" size="sm" key={index}>
										{email}
									</Text>
								))}
								{invalidEmail?.length ? <Text>Not Valid Email </Text> : null}
							</Flex>
						</ModalBody>
						<ModalFooter>
							<Button
								colorScheme="green"
								mr={3}
								onClick={() => {
									emailTranscript();
								}}
								isLoading={emailLoader ? true : false}
								isDisabled={invalidEmail?.length > 0 || !skillsKeyword?.length ? true : false}
							>
								Send Mail
							</Button>
							<Button colorScheme="blue" onClick={onClose}>
								Close
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>

				{/**Ai report  */}
				<AlertDialog
					isOpen={isAlertOpen}
					leastDestructiveRef={cancelRef}
					onClose={onAlertClose}
				>
					<AlertDialogOverlay>
						<AlertDialogContent w="90%">
							<AlertDialogHeader fontSize='lg' fontWeight='bold'>
								AI Report
							</AlertDialogHeader>

							<AlertDialogBody>
								<Text as="h4" fontSize="md">Average Confidence score <Badge fontSize="xl" as="span" ml={2} bg={() => getAiReportBadgeColor(0.60).bg} color={() => getAiReportBadgeColor().color}>
									{(avgConfidencePer.avg * 100).toFixed(2)}%
								</Badge>
								</Text>
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button ref={cancelRef} onClick={onAlertClose}>
									Close
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			</Box>
			<FeedbackModal isFeedBackOpen={isFeedBackOpen} onFeedBackClose={handleOnFeedBackClose} rating={rating} setRating={setRating} comment={comment} setComment={setComment} selectedComment={selectedComment} setSelectedComment={setSelectedComment} handleAddFeedBack={handleAddFeedBack} submitLoading={btnLoading?.submitFeedback}/>
		</>
	);
};

export default BasicAICommDemo;
