// Chakra React Imports
import React, { useState } from "react";
import { Text, Box, Flex, Spinner, Icon, Heading, Button, useToast, Tabs, TabList, Tab, TabPanels, TabPanel, useColorModeValue, Switch, FormLabel, FormControl } from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle, AiOutlineCheckCircle } from "react-icons/ai";
// import Markdown from 'react-markdown';

// Component Imports
import { useBrandBg, useGreenBg, useGreenButton, useRedButton, useTextColor } from "../../../../../theme/globalColorTheme";
import { ACC_CALL_HISTORY } from "../constants";
import { calculateUserLinkCalls, getAllLinks, getAllUserLinkCalls } from "../../../../../services/businessServices";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import { useEffect } from "react";
import Card from "../../../../../components/card/Card";
import LingoLinkHistory from "../../components/LingoLinkHistory";

function AccCallHistory() {
    const brandBg = useBrandBg();
    const [configurationCollapseFlag, setConfiguationCollapseFlag] =
        useState(true);
    const redBg = useRedButton();
    const greenBg = useGreenButton();
    const [linkFilter, setLinkFilter] = useState(ACC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE)
    const [linksPageId, setLinksPageId] = useState(1)
    const [links, setLinks] = useState(null)
    const toast = useToast()
    const [linkreport, setLinkReport] = useState([]);
    const [linksLoading, setLinksLoading] = useState(true)
    const [isLoadMoreDisabled, setLoadMoreDisabled] = useState(false)
    const [showLoadMoreLoading, setShowLoadMoreLoading] = useState(false)
    const [linkSessions, setLinkSessions] = useState(null)
    const [selectedLingoLink, setSelectedLingoLink] = useState('')
    const [linkHistoryLoader, setLinkHistoryLoader] = useState(false)
    const [billAmount, setBillAmount] = useState(0.0);
    const [listTypeFilter, setListTypeFilter] = useState(ACC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS)
    const [duration, setDuration] = useState(ACC_CALL_HISTORY.DURATION.TODAY)
    const [selectedFilter, setSelectedFilter] = useState(ACC_CALL_HISTORY.FILTERS.TRANSCRIPT)
    const [splitTabs, setSplitTabs] = useState(false)

    const blackWhiteColor = useTextColor()
    const pillButtonBg = useColorModeValue("blue.100", "gray.600");
    const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");

    const calculateBilling = (link) => {
        let linkUuid = link?.uuid
        let linkCode = link?.code
        let linkKey = link?.key
        if (linkUuid != null && linkCode != null && linkKey != null) {
            // isLoading(true);
            let paramObj = {
                linkUuid: linkUuid,
                linkKey: linkKey
            };
            calculateUserLinkCalls(paramObj)
                .then((res) => {
                    // isLoading(false);
                    if (res?.data[0]?.status) {
                        // isLoading(false);
                        setBillAmount(res?.data[0]?.amount);
                        // mergeBillingData(res?.data[0]?.sessions);
                    } else {
                        // isLoading(false);
                    }
                })
                .catch((err) => {
                    // isLoading(false);
                });
        }
    };

    const handleSelectedFilter = (filter) => {
        if (selectedFilter === filter) {
            setSelectedFilter(null)
        } else {
            setSelectedFilter(filter)
        }
    }


    async function getAllLingoLinks() {
        try {
            const paramObj = {
                filter_key: "",
                filter_value: linkFilter,
                page_id: linksPageId,
                records_per_page: 10,
            };

            links?.length ? setShowLoadMoreLoading(true) : setLinksLoading(true);

            const resp = await getAllLinks(paramObj);

            const records = resp.data[0]?.records;

            if (!records?.length) {
                setLoadMoreDisabled(true);
                setLinksLoading(false);
                setShowLoadMoreLoading(false);
                return;
            }

            setLoadMoreDisabled(false);
            setLinks(prevLinks => links?.length ? [...prevLinks, ...records] : records);

        } catch (err) {
            toast(toastFunctionToaster("Failed to get lingoLinks", "error"));
            setLinks([]);
        } finally {
            setLinksLoading(false);
            setShowLoadMoreLoading(false);
        }
    }

    function getLinkHistory(uuid, key) {
        const paramObj = {
            page_id: 1,
            records_per_page: 10,
            linkUuid: uuid,
            "linkKey": key,
            duration: duration,
            filter: selectedFilter
        }
        setLinkHistoryLoader(true)
        getAllUserLinkCalls(paramObj).then(res => {
            if (res?.data?.[0]?.sessions?.length) {
                setLinkSessions(res?.data?.[0]?.sessions);
                setLinkReport(res?.data[0]?.report);
            } else {
                setLinkSessions([])
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to get Link history", "error"))
            setLinkSessions([])

        }).finally(() => {
            setLinkHistoryLoader(false)
        })
    }

    function handleLoadMoreLinks() {
        setLinksPageId(linksPageId => linksPageId + 1)
    }

    function handleShowLinkBilling(item) {
        setSelectedLingoLink({
            uuid: item?.uuid,
            key: item?.key
        })
        getLinkHistory(item?.uuid, item?.key)
    }

    useEffect(() => {
        getAllLingoLinks()
    }, [linkFilter, linksPageId])

    useEffect(() => {
        setLinksPageId(1)
        setLinks([])
        setSelectedLingoLink(null)
        setLinkSessions(null)
    }, [linkFilter])


    return (
        <Flex h='calc(100vh - 160px)'>
            <Box
                w={!configurationCollapseFlag ? "95%" : "80%"}
                bg={brandBg}
                borderRadius={"10px"}
                h="full"
                p={1}
            >
                <Card p={1} h='calc(100vh - 170px)'>
                    {
                        (linkHistoryLoader) ? <Flex w="100%" justifyContent="center">
                            <Spinner
                                thickness='2px'
                                speed='0.65s'
                                size='xl'
                            />
                        </Flex> :
                            <LingoLinkHistory selectedLingoLink={selectedLingoLink} linkSessions={linkSessions} listTypeFilter={listTypeFilter} linkreport={linkreport} billAmount={billAmount} calculateBilling={calculateBilling} />
                    }

                </Card>
            </Box>
            {configurationCollapseFlag ? (
                <Box
                    w="20%"
                    minW={'220px'}
                    position="relative"
                    borderRadius="15px"
                    bg={brandBg}
                    overflow="hidden"
                    ml="2"
                >
                    <Flex w={'100%'} 
                        p={2}
                        //bg={'gray.100'}
                        borderBottomWidth={'0.5px'}
                        borderBottomColor={'gray.300'}
                        >
                        <Box w={'80%'}>
                            <FormControl display='flex' alignItems='center' mb={2} mt="1">
                                <FormLabel htmlFor='splitTabs' mb='0'>
                                    Split Tabs
                                </FormLabel>
                                <Switch id='splitTabs' value={splitTabs} onChange={() => setSplitTabs(splitTab => !splitTab)} />
                            </FormControl>
                        </Box>
                        <Box
                            align={'end'}
                            w={'20%'}
                            position="absolute"
                            top="0"
                            right="0"
                            mr="2"
                            mt="3"
                            zIndex="999"
                        >
                            <AiFillRightCircle
                                cursor="pointer"
                                size="20px"
                                onClick={() => {
                                    setConfiguationCollapseFlag(
                                        !configurationCollapseFlag
                                    );
                                }}
                            />
                        </Box>
                    </Flex>
                    {splitTabs ?
                        <Flex direction="column" h="full" p={2}>
                            <Card h="42%" w="full" p={1} mt="4">
                                <Flex flexDir="column" w="full" alignItems="center" h="100%" overflow="hidden">
                                    <Flex flexDir="column" overflow="auto" w="100%" h="89%" p='1' mt={2} className="custom-scrollbar"
                                    >
                                        <Flex flexDir="column" minH="100%">
                                            {
                                                linksLoading ? <Flex w="100%" justifyContent="center">
                                                    <Spinner
                                                        thickness='2px'
                                                        speed='0.65s'
                                                        size='xl'
                                                    />
                                                </Flex> :
                                                    <Flex flexDir="column">
                                                        {
                                                            (links && links?.length) ? links.map((link) => (
                                                                <Flex flexDir="column" gap={1} p="2"
                                                                    cursor="pointer"
                                                                    _hover={{ bg: "blue.100", color: 'black' }}
                                                                    mb="2"
                                                                    borderWidth="1px"
                                                                    borderColor="#d7d7d7"
                                                                    borderRadius="5px"
                                                                    bg={selectedLingoLink?.uuid === link.uuid ? greenBg : null}
                                                                    onClick={() => handleShowLinkBilling(link)}
                                                                >
                                                                    <Text fontWeight="bold">{link?.title}</Text>
                                                                    <Text fontSize="smaller">{link?.url}</Text>
                                                                </Flex>
                                                            )) : <Flex
                                                                w={"100%"}
                                                                p={5}
                                                                bg={'red.100'}
                                                                borderLeftWidth={"5px"}
                                                                borderLeftColor={"red.500"}
                                                            >
                                                                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                                                                    No LingoLinks found!
                                                                </Heading>
                                                            </Flex>
                                                        }
                                                        {(links && links?.length) ? <Button colorScheme="green" p={3} rounded="md" isLoading={showLoadMoreLoading} onClick={handleLoadMoreLinks} isDisabled={isLoadMoreDisabled}>Load more</Button> : null}
                                                    </Flex>
                                            }
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Card>

                            <Card h="42%" w="full" p={1} mt="4" >
                            <Flex flexDir="column" w="full" alignItems="center" h="full" overflow="hidden">
                                <Flex flexDir="column" w="full" alignItems="center" h="100%" overflowY="auto" className="custom-scrollbar">
                                    <Flex align={"center"} p={1} w="full" justifyContent="center">
                                        <Box
                                            p={1}
                                            align={"center"}
                                            w={"50%"}
                                            _hover={{ cursor: "pointer" }}
                                            roundedLeft={"lg"}
                                            bg={linkFilter === ACC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE ? "green.600" : greenBg}
                                            color={linkFilter === ACC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE ? "white" : "inherit"}
                                            size="sm"
                                            onClick={() => {
                                                setLinkFilter(ACC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE);
                                            }}>
                                            {linkFilter === ACC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                            Active
                                        </Box>

                                        <Box
                                            p={1}
                                            align={"center"}
                                            w={"50%"}
                                            _hover={{ cursor: "pointer" }}
                                            roundedRight={"lg"}
                                            bg={linkFilter === ACC_CALL_HISTORY.LINGO_LINKS_FILTER.CLOSED ? "red.400" : redBg}
                                            color={linkFilter === ACC_CALL_HISTORY.LINGO_LINKS_FILTER.CLOSED ? "white" : "inherit"}
                                            size="sm"
                                            onClick={() => {
                                                setLinkFilter(ACC_CALL_HISTORY.LINGO_LINKS_FILTER.CLOSED);
                                            }}>
                                            {linkFilter === ACC_CALL_HISTORY.LINGO_LINKS_FILTER.CLOSED ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                            Closed
                                        </Box>
                                    </Flex>

                                    <Flex alignItems="center" p={2} h="max-content">
                                        <Box
                                            h="full"
                                            p={1}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            w={"50%"}
                                            _hover={{ cursor: "pointer" }}
                                            roundedLeft={"lg"}
                                            bg={listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION ? "green.600" : useGreenBg}
                                            color={listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION ? "white" : "inherit"}
                                            size="sm"
                                            fontSize="sm"
                                            onClick={() => {
                                                setListTypeFilter(ACC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION);
                                            }}>
                                            {listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                            Calls with Session
                                        </Box>
                                        <Box
                                            p={1}
                                            h="full"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            w={"50%"}
                                            _hover={{ cursor: "pointer" }}
                                            roundedRight={"lg"}
                                            fontSize="sm"
                                            bg={listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS ? "green.600" : useGreenBg}
                                            color={listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS ? "white" : "inherit"}
                                            size="sm"
                                            onClick={() => {
                                                setListTypeFilter(ACC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS);
                                            }}>
                                            {listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                            Calls only
                                        </Box>
                                    </Flex>
                                    <Flex direction="column" w="100%" pt="1" borderTop="1px" mt={2}>
                                        <Text fontWeight="bold" mb="3">Call History</Text>
                                        <Flex alignItems="center" p={2}>
                                            <Box
                                                h="full"
                                                p={1}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                w={"50%"}
                                                _hover={{ cursor: "pointer" }}
                                                roundedLeft={"lg"}
                                                bg={selectedFilter === ACC_CALL_HISTORY.FILTERS.FEEDBACK ? "green.600" : useGreenBg}
                                                color={selectedFilter === ACC_CALL_HISTORY.FILTERS.FEEDBACK ? "white" : "inherit"}
                                                size="sm"
                                                borderRight="1px"
                                                borderColor="white"
                                                fontSize="sm"
                                                onClick={() => {
                                                    handleSelectedFilter(ACC_CALL_HISTORY.FILTERS.FEEDBACK);
                                                }}>
                                                {selectedFilter === ACC_CALL_HISTORY.FILTERS.FEEDBACK ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                Feedbacks
                                            </Box>
                                            <Box
                                                p={1}
                                                h="full"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                w={"50%"}
                                                _hover={{ cursor: "pointer" }}
                                                roundedRight={"lg"}
                                                fontSize="sm"
                                                bg={selectedFilter === ACC_CALL_HISTORY.FILTERS.TRANSCRIPT ? "green.600" : useGreenBg}
                                                color={selectedFilter === ACC_CALL_HISTORY.FILTERS.TRANSCRIPT ? "white" : "inherit"}
                                                size="sm"
                                                onClick={() => {
                                                    handleSelectedFilter(ACC_CALL_HISTORY.FILTERS.TRANSCRIPT);
                                                }}>
                                                {selectedFilter === ACC_CALL_HISTORY.FILTERS.TRANSCRIPT ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                Transcripts
                                            </Box>
                                        </Flex>
                                    </Flex>
                                    <Flex direction="column" w="100%" pt="1" borderTop="1px" mt={2}>
                                        <Text fontWeight="bold" mb="3">Duration</Text>
                                        <Flex alignItems="center" w="100%">
                                            <Box
                                                h="full"
                                                p={1}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                w={"100px"}
                                                _hover={{ cursor: "pointer" }}
                                                roundedLeft={"lg"}
                                                bg={duration === ACC_CALL_HISTORY.DURATION.TODAY ? "green.600" : useGreenBg}
                                                color={duration === ACC_CALL_HISTORY.DURATION.TODAY ? "white" : "inherit"}
                                                size="sm"
                                                fontSize="sm"
                                                onClick={() => { setDuration(ACC_CALL_HISTORY.DURATION.TODAY) }}
                                            >
                                                {duration === ACC_CALL_HISTORY.DURATION.TODAY ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}

                                                Today
                                            </Box>
                                            <Box
                                                h="full"
                                                p={1}
                                                borderRight="1px"
                                                borderLeft="1px"
                                                borderColor="white"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                w={"100px"}
                                                _hover={{ cursor: "pointer" }}
                                                bg={duration === ACC_CALL_HISTORY.DURATION.WEEK ? "green.600" : useGreenBg}
                                                color={duration === ACC_CALL_HISTORY.DURATION.WEEK ? "white" : "inherit"}
                                                size="sm"
                                                fontSize="sm"
                                                onClick={() => { setDuration(ACC_CALL_HISTORY.DURATION.WEEK) }}
                                            >
                                                {duration === ACC_CALL_HISTORY.DURATION.WEEK ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                This Week
                                            </Box>
                                            <Box
                                                p={1}
                                                h="full"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                w={"100px"}
                                                _hover={{ cursor: "pointer" }}
                                                roundedRight={"lg"}
                                                fontSize="sm"
                                                bg={duration === ACC_CALL_HISTORY.DURATION.MONTH ? "green.600" : useGreenBg}
                                                color={duration === ACC_CALL_HISTORY.DURATION.MONTH ? "white" : "inherit"}
                                                size="sm"
                                                onClick={() => { setDuration(ACC_CALL_HISTORY.DURATION.MONTH) }}
                                            >
                                                {duration === ACC_CALL_HISTORY.DURATION.MONTH ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                This Month
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Button size={'sm'} ml={1}
                                    onClick={() => getLinkHistory(selectedLingoLink?.uuid, selectedLingoLink?.key)}
                                    _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
                                    bg={pillButtonBg}>Get History</Button>
                            </Flex>
                            </Card>
                        </Flex>
                        : <Tabs variant='enclosed-colored' colorScheme="green" h="81%" isFitted>

                            <TabList>
                                <Tab fontSize="sm" fontWeight="bold" >LingoLinks</Tab>
                                <Tab fontSize="sm" fontWeight="bold">Filters</Tab>
                            </TabList>
                            <Box w="100%" h="100%" bg={brandBg} p={1}>
                                <TabPanels h="full" p="0">
                                    <TabPanel h="full" p="0">
                                        <Card h="full" w="full" p={1} mt="4">
                                            <Flex flexDir="column" w="full" alignItems="center" h="100%">
                                                <Flex flexDir="column" overflow="auto" w="100%" h="89%" p='1' mt={2} className="custom-scrollbar"
                                                >
                                                    <Flex flexDir="column" minH="100%">
                                                        {
                                                            linksLoading ? <Flex w="100%" justifyContent="center">
                                                                <Spinner
                                                                    thickness='2px'
                                                                    speed='0.65s'
                                                                    size='xl'
                                                                />
                                                            </Flex> :
                                                                <Flex flexDir="column">
                                                                    {
                                                                        (links && links?.length) ? links.map((link) => (
                                                                            <Flex flexDir="column" gap={1} p="2"
                                                                                cursor="pointer"
                                                                                _hover={{ bg: "blue.100", color: 'black' }}
                                                                                mb="2"
                                                                                borderWidth="1px"
                                                                                borderColor="#d7d7d7"
                                                                                borderRadius="5px"
                                                                                bg={selectedLingoLink?.uuid === link.uuid ? greenBg : null}
                                                                                onClick={() => handleShowLinkBilling(link)}
                                                                            >
                                                                                <Text fontWeight="bold">{link?.title}</Text>
                                                                                <Text fontSize="smaller">{link?.url}</Text>
                                                                            </Flex>
                                                                        )) : <Flex
                                                                            w={"100%"}
                                                                            p={5}
                                                                            bg={'red.100'}
                                                                            borderLeftWidth={"5px"}
                                                                            borderLeftColor={"red.500"}
                                                                        >
                                                                            <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                                                                                No LingoLinks found!
                                                                            </Heading>
                                                                        </Flex>
                                                                    }
                                                                    {(links && links?.length) ? <Button colorScheme="green" p={3} rounded="md" isLoading={showLoadMoreLoading} onClick={handleLoadMoreLinks} isDisabled={isLoadMoreDisabled}>Load more</Button> : null}
                                                                </Flex>
                                                        }
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    </TabPanel>
                                    <TabPanel h="full" p="0">
                                        <Card h="full" w="full" p={1} mt="4">
                                            <Flex flexDir="column" w="full" alignItems="center" h="100%">
                                                <Flex align={"center"} p={1} w="full" justifyContent="center">
                                                    <Box
                                                        p={1}
                                                        align={"center"}
                                                        w={"50%"}
                                                        _hover={{ cursor: "pointer" }}
                                                        roundedLeft={"lg"}
                                                        bg={linkFilter === ACC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE ? "green.600" : greenBg}
                                                        color={linkFilter === ACC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE ? "white" : "inherit"}
                                                        size="sm"
                                                        onClick={() => {
                                                            setLinkFilter(ACC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE);
                                                        }}>
                                                        {linkFilter === ACC_CALL_HISTORY.LINGO_LINKS_FILTER.ACTIVE ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                        Active
                                                    </Box>

                                                    <Box
                                                        p={1}
                                                        align={"center"}
                                                        w={"50%"}
                                                        _hover={{ cursor: "pointer" }}
                                                        roundedRight={"lg"}
                                                        bg={linkFilter === ACC_CALL_HISTORY.LINGO_LINKS_FILTER.CLOSED ? "red.400" : redBg}
                                                        color={linkFilter === ACC_CALL_HISTORY.LINGO_LINKS_FILTER.CLOSED ? "white" : "inherit"}
                                                        size="sm"
                                                        onClick={() => {
                                                            setLinkFilter(ACC_CALL_HISTORY.LINGO_LINKS_FILTER.CLOSED);
                                                        }}>
                                                        {linkFilter === ACC_CALL_HISTORY.LINGO_LINKS_FILTER.CLOSED ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                        Closed
                                                    </Box>
                                                </Flex>
                                                <Flex alignItems="center" w={'100%'} p={1}>
                                                    <Box
                                                        h="full"
                                                        p={1}
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        w={"50%"}
                                                        _hover={{ cursor: "pointer" }}
                                                        roundedLeft={"lg"}
                                                        bg={listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION ? "green.600" : useGreenBg}
                                                        color={listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION ? "white" : "inherit"}
                                                        size="sm"
                                                        fontSize="sm"
                                                        onClick={() => {
                                                            setListTypeFilter(ACC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION);
                                                        }}>
                                                        {listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.CALLS_WITH_SESSION ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                        Calls with Session
                                                    </Box>
                                                    <Box
                                                        p={1}
                                                        h="full"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        w={"50%"}
                                                        _hover={{ cursor: "pointer" }}
                                                        roundedRight={"lg"}
                                                        fontSize="sm"
                                                        bg={listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS ? "green.600" : useGreenBg}
                                                        color={listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS ? "white" : "inherit"}
                                                        size="sm"
                                                        onClick={() => {
                                                            setListTypeFilter(ACC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS);
                                                        }}>
                                                        {listTypeFilter === ACC_CALL_HISTORY.CALL_LIST_TYPE.ONLY_CALLS ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                        Calls only
                                                    </Box>
                                                </Flex>
                                                <Flex direction="column" w="100%" pt="1" borderTop="1px" mt={2}>
                                                    <Text fontWeight="bold" mb="3">Call History</Text>
                                                    <Flex alignItems="center" p={2}>
                                                        <Box
                                                            h="full"
                                                            p={1}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            w={"100px"}
                                                            _hover={{ cursor: "pointer" }}
                                                            roundedLeft={"lg"}
                                                            bg={selectedFilter === ACC_CALL_HISTORY.FILTERS.FEEDBACK ? "green.600" : useGreenBg}
                                                            color={selectedFilter === ACC_CALL_HISTORY.FILTERS.FEEDBACK ? "white" : "inherit"}
                                                            size="sm"
                                                            borderRight="1px"
                                                            borderColor="white"
                                                            fontSize="sm"
                                                            onClick={() => {
                                                                handleSelectedFilter(ACC_CALL_HISTORY.FILTERS.FEEDBACK);
                                                            }}>
                                                            {selectedFilter === ACC_CALL_HISTORY.FILTERS.FEEDBACK ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                            Feedbacks
                                                        </Box>
                                                        <Box
                                                            p={1}
                                                            h="full"
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            w={"100px"}
                                                            _hover={{ cursor: "pointer" }}
                                                            roundedRight={"lg"}
                                                            fontSize="sm"
                                                            bg={selectedFilter === ACC_CALL_HISTORY.FILTERS.TRANSCRIPT ? "green.600" : useGreenBg}
                                                            color={selectedFilter === ACC_CALL_HISTORY.FILTERS.TRANSCRIPT ? "white" : "inherit"}
                                                            size="sm"
                                                            onClick={() => {
                                                                handleSelectedFilter(ACC_CALL_HISTORY.FILTERS.TRANSCRIPT);
                                                            }}>
                                                            {selectedFilter === ACC_CALL_HISTORY.FILTERS.TRANSCRIPT ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                            Transcripts
                                                        </Box>
                                                    </Flex>
                                                </Flex>
                                                <Flex direction="column" w="100%" pt="1" borderTop="1px" mt={2}>
                                                    <Text fontWeight="bold" mb="3">Duration</Text>
                                                    <Flex alignItems="center" w="100%">
                                                        <Box
                                                            h="full"
                                                            p={1}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            w={"100px"}
                                                            _hover={{ cursor: "pointer" }}
                                                            roundedLeft={"lg"}
                                                            bg={duration === ACC_CALL_HISTORY.DURATION.TODAY ? "green.600" : useGreenBg}
                                                            color={duration === ACC_CALL_HISTORY.DURATION.TODAY ? "white" : "inherit"}
                                                            size="sm"
                                                            fontSize="sm"
                                                            onClick={() => { setDuration(ACC_CALL_HISTORY.DURATION.TODAY) }}
                                                        >
                                                            {duration === ACC_CALL_HISTORY.DURATION.TODAY ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}

                                                            Today
                                                        </Box>
                                                        <Box
                                                            h="full"
                                                            p={1}
                                                            borderRight="1px"
                                                            borderLeft="1px"
                                                            borderColor="white"
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            w={"100px"}
                                                            _hover={{ cursor: "pointer" }}
                                                            bg={duration === ACC_CALL_HISTORY.DURATION.WEEK ? "green.600" : useGreenBg}
                                                            color={duration === ACC_CALL_HISTORY.DURATION.WEEK ? "white" : "inherit"}
                                                            size="sm"
                                                            fontSize="sm"
                                                            onClick={() => { setDuration(ACC_CALL_HISTORY.DURATION.WEEK) }}
                                                        >
                                                            {duration === ACC_CALL_HISTORY.DURATION.WEEK ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                            This Week
                                                        </Box>
                                                        <Box
                                                            p={1}
                                                            h="full"
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            w={"100px"}
                                                            _hover={{ cursor: "pointer" }}
                                                            roundedRight={"lg"}
                                                            fontSize="sm"
                                                            bg={duration === ACC_CALL_HISTORY.DURATION.MONTH ? "green.600" : useGreenBg}
                                                            color={duration === ACC_CALL_HISTORY.DURATION.MONTH ? "white" : "inherit"}
                                                            size="sm"
                                                            onClick={() => { setDuration(ACC_CALL_HISTORY.DURATION.MONTH) }}
                                                        >
                                                            {duration === ACC_CALL_HISTORY.DURATION.MONTH ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                            This Month
                                                        </Box>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                            <Button size={'sm'} ml={1}
                                                onClick={() => getLinkHistory(selectedLingoLink?.uuid, selectedLingoLink?.key)}
                                                _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
                                                bg={pillButtonBg}>Get History</Button>
                                        </Card>
                                    </TabPanel>
                                </TabPanels>
                            </Box>
                        </Tabs>}
                </Box >
            ) : (
                <Box
                    w="5%"
                    p="3"
                    borderRadius="10px"
                    bg={brandBg}
                    h="100%"
                    ml="2"
                    pl="5"
                >
                    <Box w={"100%"} h="100%">
                        <AiFillLeftCircle
                            cursor="pointer"
                            onClick={() => {
                                setConfiguationCollapseFlag(
                                    !configurationCollapseFlag
                                );
                            }}
                            size="20px"
                        />
                        <Text
                            fontSize="16px"
                            mt="2"
                            css={{
                                writingMode: "vertical-lr",
                                textOrientation: "mixed",
                            }}
                        >
                            Expand Report Settings
                        </Text>
                    </Box>
                </Box>
            )
            }
        </Flex >
    );
}

export default AccCallHistory;
