import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

// Chakra Import
import {
    Box,
    Flex,
    Text,
    Icon,
    Input,
    Stack,
    Image,
    Avatar,
    Button,
    Switch,
    Spacer,
    Heading,
    Spinner,
    useToast,
    InputGroup,
    useDisclosure,
    InputLeftAddon,
    useColorModeValue,
    Select,
    Menu,
    MenuButton,
    MenuList,
    MenuItem
} from "@chakra-ui/react";
import Card from "../../../components/card/Card";
import {
    AiOutlineSearch ,
    AiFillLeftCircle,
    AiFillRightCircle,
    AiOutlinePlus,
    AiOutlineHolder,
} from "react-icons/ai";

// Api Call Imports
import { fetchLocationInfo, fetchLocationDetails, manageLocationStatus } from "../../../services/accountServices";

// Component Imports
import HumanAIBg from "../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../assets/communicator/SecureAI.png";
import PremiumAIBg from "../../../assets/communicator/Premium_AIIcon.png";
import MultichannelBg from "../../../assets/communicator/multichannelai.png";
import DocumentAiLightBg from "../../../assets/communicator/DocumentAILight.png";
import AddUpdateLocation from "./AddUpdateLocation";
import { useBrandBg, useListItemHoverBg, useTextColor, useBlueBorder } from "../../../theme/globalColorTheme";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { ChevronDownIcon } from "@chakra-ui/icons";

function LocationsListing() {
    const toast = useToast();
    const brandBg = useBrandBg();
    const localListItemHoverBg = useListItemHoverBg();

    const navigate = useNavigate();
    const [loading, isLoading] = useState(true);
    const [accountUuid, setAccountUuid] = useState(null);
    const [accountInfo, setAccountInfo] = useState([]);

    const [activeCount, setActiveCount] = useState(0)
    const [closedCount, setClosedCount] = useState(0)
    const [locationList, setLocationList] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedLocationData, setSelectedLocationData] = useState({})
    const [currentSelection, setCurrentSelection] = useState('active');
    const [subDetailsModalFlag, setSubDetailsModalFlag] = useState('');
    const [quotaInfo, setQuotaInfo] = useState([]);
    const [locationListClone, setLocationListClone] = useState([]);
    const [configurationCollapseFlag, setConfiguationCollapseFlag] = useState(true);
    const [sort, setSort] = useState('')

    const pillButtonBg = useColorModeValue("blue.100", "gray.600");
    const textColor = useColorModeValue("secondaryGray.700", "white");
    const pillButtonBgHover = useColorModeValue("blue.400", "red.200");
    const pillButtonBgSelected = useColorModeValue("blue.300", "red.400");
    const pillButtonTextColorHover = useColorModeValue("secondaryGray.200", "gray.700");
    const blackWhiteColor = useTextColor() 
    const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");

    //   Todo:Fetch all location details
    useEffect(() => {
        collectLocationInfo();
        collectLocationList(currentSelection);
    }, [accountUuid]);

    useEffect(()=>{
        sortLocations()
    }, [sort])

    const collectLocationInfo = () => {
        isLoading(true);
        let paramObj = {
            key: 'location',
        };
        fetchLocationInfo(paramObj)
            .then(res => {
                isLoading(false);
                if (res?.data[0]?.status) {
                    isLoading(false);
                    setAccountUuid(res?.data[0]?.uuid);
                    setAccountInfo(res?.data[0]?.info);
                    setQuotaInfo(res?.data[0]?.quota)
                } else {
                    isLoading(false);
                    setAccountUuid([]);
                }
            })
            .catch(err => {
                isLoading(false);
                setAccountUuid([]);
            });
    };

    const collectLocationList = (currentSelection) => {
        if (accountUuid != null) {
            isLoading(true);
            let paramObj = {
                "link_account_uuid": accountUuid,
                key: currentSelection,
                sub_location: true,
                type: 'full',
                records_per_page: 0,
                page_id: 0,
            };
            fetchLocationDetails(paramObj)
                .then(res => {
                    isLoading(false);
                    if (res?.data[0]?.total_records) {
                        isLoading(false);
                        setLocationList(res?.data[0]?.records);
                        setLocationListClone(res?.data[0]?.records);
                        setActiveCount(res?.data[0]?.active_locations ? res?.data[0]?.active_locations : 0);
                        setClosedCount(res?.data[0]?.closed_location ? res?.data[0]?.closed_location : 0);                
                    } else {
                        isLoading(false);
                        setLocationList([]);
                        setLocationListClone([]);
                    }
                })
                .catch(err => {
                    isLoading(false);
                    setLocationList([]);
                    setLocationListClone([]);
                });
        }
    };

    // Todo:Update Location Status
    const uploadLocationStatus = (locationData, parent_location_uuid) => {
        if (locationData?.link_location_uuid != null && locationData?.link_account_uuid !== null) {
            let objBody = {
                link_location_uuid: locationData?.link_location_uuid,
                link_account_uuid: locationData?.link_account_uuid,
                parent_location_uuid: parent_location_uuid
            };
            manageLocationStatus(objBody)
                .then(res => {
                    if (res?.result) {
                        collectLocationList(currentSelection);
                        toast(toastFunctionToaster(res.message, "success"));
                    } else {

                        toast(toastFunctionToaster(res.message, "error"));
                    }
                })
                .catch(err => {
                    toast(toastFunctionToaster(err.message, "error"));
                });
        } else {
        }
    };

    const renderSubLocations = (data, parentData) => {
       return data !== null && data?.length > 0 ? (
            data?.map((item, index) => (
               ((currentSelection === 'active' && item?.is_enabled)  || (currentSelection === 'closed' && (!item?.is_enabled || !parentData?.is_enabled))) &&
                <>
                    <Flex
                        w={"100%"}
                        key={index}
                        rounded={"md"}
                        align={"center"}
                        p={1}
                        mt={1}
                        borderWidth={"0.05px"}
                        borderColor={ useBlueBorder}>
                        <Flex
                            _hover={{ cursor: "pointer", bg: localListItemHoverBg }}
                            rounded={"md"}
                            px={1}
                            onClick={() => {
                                navigate("/account/locations/subscribers", {
                                    state: {
                                        accountUuid: accountUuid,
                                        parentData: parentData,
                                        locationDetails: item,
                                        accountInfo: accountInfo
                                    },
                                });
                            }}
                            w={"100%"}
                        >
                            <Box minW={"100px"} maxW={'100px'} align='center'>
                                <Avatar w={'80px'} h={'80px'} src={item?.link_location_avatar?.length ? item?.link_location_avatar : null} />
                            </Box>
                            <Flex w={'100%'} >
                                <Flex minW={"100px"} p={1} direction={"column"} flexWrap={'wrap'} borderRightWidth={'1px'} pr='2'>
                                    {/* <Text fontWeight={'bold'}>
                                        {item?.link_location_title}
                                    </Text> */}
                                    <Text w="100%">{item?.link_location_name}</Text>
                                </Flex>
                                {(item?.link_location_address1 || item?.link_location_address2 || item?.link_location_country || item?.item?.link_location_state || item?.link_location_city)&&(
                                    <Flex borderColor={useBlueBorder} borderWidth={'0.5px'} rounded={'md'}
                                        minW={"100px"} p={2} ml="2" pl="2" direction={"column"} flexWrap={'wrap'}>
                                        <Text>
                                            {item?.link_location_address1}
                                        </Text>
                                        <Text w="100%">{item?.link_location_address2}</Text>
                                        <Text w="100%" fontSize={'sm'}>{item?.link_location_country},{item?.link_location_state},{item?.link_location_city}</Text>
                                    </Flex>)}
                            </Flex>
                        </Flex>
                        <Flex w={'250px'} direction={'column'} align={'center'}> 
                            <Flex flexWrap={'wrap'}>
                                <Flex
                                    px={2}
                                    rounded={'md'}
                                    direction={'column'}
                                    alignItems={"center"}
                                    borderWidth={"1px"}
                                    borderColor={"gray.400"}
                                    minW={"100px"}
                                    align={"end"}>
                                        <Heading>
                                            {item?.subscriber_count}
                                        </Heading>
                                        <Text>
                                        Subscribers
                                        </Text>
                                    
                                </Flex>
                                <Flex
                                    pt={2}
                                    alignItems={"center"}
                                    minW={"100px"}
                                    align={"end"}>
                                    <Switch
                                            onChange={() => {
                                                uploadLocationStatus(item, parentData?.link_location_uuid);
                                            }}
                                            isChecked={item?.is_enabled ? true : false}
                                            colorScheme="green"
                                        />
                                    <Icon
                                        as={AiOutlineHolder}
                                        ml={1}
                                        p={1}
                                        bg={"gray.200"}
                                        rounded={"md"}
                                        fontSize={"24px"}
                                        cursor={"pointer"}
                                        color="black"
                                        onClick={() => {
                                            onOpen()
                                            setSelectedLocationData(item)
                                            setSubDetailsModalFlag(parentData?.link_location_uuid)
                                        }}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </>
            ))
        ) : (
            <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                    You do not have any location added.
                </Heading>
            </Flex>
        );
    }

    const addSubdetailsData = (data) => {
        setSubDetailsModalFlag(data?.link_location_uuid);
        onOpen()
    }

    // Todo:Seach Function Based on Location & Sub Location Name
    const handleSearch = (event) => {
        const term = event.target.value;
        if (term.length >= 2) {
          // Filter based on the search term if it's at least 2 characters long
          const results = locationList.filter(location => {
            return (
              location.link_location_name.toLowerCase().includes(term.toLowerCase()) ||
              location.sub_locations.some(subLocation =>
                subLocation.link_location_name.toLowerCase().includes(term.toLowerCase())
              )
            );
          });
          setLocationListClone(results);
        } else {
          // If less than 2 characters, show all locations or clear filtered results
          setLocationListClone(locationList);
        }
      };

      function sortLocations() {
        if (locationListClone && locationListClone.length > 1) {
            const sortedList = [...locationListClone].sort((a, b) => {
                if (sort === 'asc') {
                    return a?.link_location_title?.localeCompare(b?.link_location_title);
                } else if (sort === 'desc') {
                    return b?.link_location_title?.localeCompare(a?.link_location_title);
                }
                return 0;
            });
            sortedList.forEach(location => {
                if (location.sub_locations && location.sub_locations.length > 1) {
                    location.sub_locations.sort((a, b) => {
                        if (sort === 'asc') {
                            return a?.link_location_title?.localeCompare(b?.link_location_title);
                        } else if (sort === 'desc') {
                            return b?.link_location_title?.localeCompare(a?.link_location_title);
                        }
                        return 0;
                    });
                }
            });
            setLocationListClone(sortedList);
        }
    }
    

    // Todo:Render Location Details
    const renderLocationListing = () => {
        if (loading) {
            return <Flex alignItems={'center'} justifyContent={'center'}> <Spinner size={"xl"} /> </Flex>;
        } else if( locationListClone !== null && locationListClone?.length > 0) { 
           return locationListClone?.map((item, index) => (
                <>
                    <Card w={'100%'} 
                        rounded={'lg'} p={1} mt={1} 
                        borderWidth={"0.05px"} borderColor={item?.is_enabled ? useBlueBorder : "red.200"}>
                        <Flex
                            w={"100%"}
                            key={index}
                            rounded={"md"}
                            align={"center"}
                            p={1}
                            mt={1}>
                            <Flex
                                onClick={() => {
                                    navigate("/account/locations/subscribers", {
                                        state: {
                                            accountUuid: accountUuid,
                                            parentData: null,
                                            locationDetails: item,
                                            accountInfo: accountInfo
                                        },
                                    });
                                }}
                                _hover={{ cursor: "pointer", bg: localListItemHoverBg }}
                                rounded={"md"}
                                px={1}
                                w={"100%"}
                            >
                                <Box minW={"100px"} maxW={'100px'} align='center'>
                                    <Avatar w={'80px'} h={'80px'} src={item?.link_location_avatar?.length ? item?.link_location_avatar : null} />
                                </Box>
                                <Flex w={'100%'} direction={'column'}>
                                    <Flex minW={"100px"} p={1} direction={"column"} flexWrap={'wrap'}>
                                        <Text w="100%" fontWeight={'bold'}>{item?.link_location_name}</Text>
                                        {/* <Text fontWeight={'bold'}>
                                            {item?.link_location_title}
                                        </Text> */}
                                        {/* <Text w="100%" fontSize={'sm'}>{item?.link_location_title}</Text> */}
                                    </Flex>
                                    {(item?.link_location_address1 || item?.link_location_address2 || item?.link_location_country || item?.item?.link_location_state || item?.link_location_city)&&(<Flex borderColor={useBlueBorder} borderWidth={'0.5px'} rounded={'md'}
                                        minW={"100px"} p={2} ml="2" pl="2" direction={"column"} flexWrap={'wrap'}>
                                        <Text>
                                            {item?.link_location_address1}
                                        </Text>
                                        <Text w="100%">{item?.link_location_address2}</Text>
                                        <Text w="100%" fontSize={'sm'}>{item?.link_location_country},{item?.link_location_state},{item?.link_location_city}</Text>
                                    </Flex>)}
                                </Flex>
                            </Flex>
                            <Flex w={'250px'} direction={'column'} align={'center'}> 
                                <Flex flexWrap={'wrap'}>
                                    <Flex
                                        px={2}
                                        rounded={'md'}
                                        direction={'column'}
                                        alignItems={"center"}
                                        borderWidth={"1px"}
                                        borderColor={"gray.400"}
                                        minW={"100px"}
                                        align={"end"}>
                                            <Heading>
                                                {item?.subscriber_count}
                                            </Heading>
                                            <Text>
                                            Subscribers
                                            </Text>
                                        
                                    </Flex>
                                    <Flex
                                        px={2}
                                        mt="1.5"
                                        alignItems={"center"}
                                        minW={"100px"}
                                        align={"end"}>
                                        {(currentSelection === 'closed' && item?.is_enabled) ? null : <Switch
                                            onChange={() => {
                                                uploadLocationStatus(item, null);
                                            }}
                                            isChecked={item?.is_enabled ? true : false}
                                            colorScheme="green"
                                        />}
                                        <Icon
                                            as={AiOutlineHolder}
                                            ml={1}
                                            p={1}
                                            bg={"gray.200"}
                                            rounded={"md"}
                                            fontSize={"24px"}
                                            cursor={"pointer"}
                                            onClick={() => {
                                                onOpen();
                                                setSelectedLocationData(item)
                                            }}
                                            color="black"
                                        />
                                    </Flex>
                                </Flex>
                                <Box mt='2'>
                                    <Button size="sm" borderRadius={'5px'} 
                                    // colorScheme="blue" 
                                    _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
                                    bg={pillButtonBg}
                                    onClick={()=>{ addSubdetailsData(item) }}>Add SubLocation</Button>
                                </Box>
                            </Flex>
                        </Flex>
                        {item?.sub_locations && item?.sub_locations?.length > 0
                        ?<Flex minW={"100px"} p={1} pl="2" direction={"column"}>
                            <Text>Sub Locations ({item?.sub_locations?.length})</Text>
                            <Box>{renderSubLocations(item?.sub_locations, item)}</Box>
                            {/* :<Text p="3" borderRadius={'5px'} bg='red.500'>No Sub Locations</Text>} */}
                        </Flex>
                        :null}
                    </Card>
                </>
            ))
        }
    }

    // Todo:Return Active Count
    // useEffect(() => {
    //     if (locationList?.length) {
    //         const returnActiveCount = locationList?.filter((data) => { return data?.is_enabled === true })
    //         setActiveCount(returnActiveCount?.length)
    //     }
    // }, [locationList])

    // Todo:Close Drawer
    const handleClose = () => {
        onClose();
        setSelectedLocationData({});
        setSubDetailsModalFlag('');
    }

    const fetchLocationData = (data) => {
        if (data) {
            onClose();
            setSelectedLocationData({});
            setSubDetailsModalFlag('');
            collectLocationList(currentSelection);
        }
    }

    const renderAppData = (data) => {
        const appData = data.split(',')
        return appData?.map((featureName, index) => (
            <Box h="50px" w="50px" mb="3" mr="1">
                <Image cursor={'pointer'} key={index} mr="1" borderRadius="50%" h="100%" w="100%" src={renderFeatureImage(featureName)} />
            </Box>
        ))
    }

    // Todo:Render Feature Image By Code
	const renderFeatureImage = name => {
		if (name === "hi") {
			return HumanAIBg;
		} else if (name === "bi") {
			return BasicAIBg;
		// } else if (name === "pi") {
		// 	return PremiumAIBg;
		} else if (name === "di") {
			return DocumentAiLightBg;
		} else if (name === "mi") {
			return MultichannelBg;
		}  
        else return;
	};

    const renderAccountInfo = () => {
        if (loading) {
            return <Flex alignItems={'center'} justifyContent={'center'}> <Spinner size={"xl"} /> </Flex>;
        } else if (accountInfo !== null && accountInfo?.length > 0) {
            return accountInfo.map((accountData, index) => (
                <Flex w={'100%'} key={index} bg={brandBg} rounded={'md'} h={'100%'} align={'center'}>
                    <Flex w={"110px"} justifyContent={'space-between'} alignItems="center" p="5px">
                        <Box minW={"100px"}>
                            <Avatar h={'80px'} w={'80px'} src={accountData?.avatar} />
                        </Box>
                    </Flex>
                    <Flex w={"110px"}>
                        <Text bg='blue.100' p={1} rounded={'md'} as="span" ml="3" color="black">{accountData?.type}</Text>
                    </Flex>
                    <Flex w={'50%'} direction={'column'}>
                        <Text ml={3} fontSize={"xs"}>
                            Account Details
                        </Text>
                        <Text as="span" ml="3">{accountData?.name}</Text>
                        <Text as="span" ml="3">{accountData?.title}</Text>
                        <Text fontSize={'sm'} as="span" ml="3">{accountData?.details}</Text>
                    </Flex>
                    <Flex w={'50%'}>
                        {accountData?.apps != null ? (
                            <Text fontWeight='600' textAlign={'center'}>Supported Apps: 
                                <Flex justifyContent={'space-between'} flexWrap={'wrap'}> 
                                    {renderAppData(accountData?.apps)} 
                                </Flex>
                            </Text>
                            
                        ) : (
                            <Text>No Apps</Text>
                        )}
                    </Flex>
                    {/* Quota Lising Added Here Too  */}
                    {/* <Flex w={'50%'} mr="3" h="75px" overflow="hidden">
                        {quotaInfo?.length > 0
                            ?<Card w={'100%'} rounded={'lg'} fontSize='14px' p="0px">
                                {renderAccountQuota}
                            </Card>
                            :<Card w={'100%'} rounded={'lg'} fontSize='14px' p="0px">
                                <Text>No Account quota info..</Text>
                        </Card>}
                    </Flex> */}
                    <Flex w={"110px"} justifyContent={'space-between'} alignItems="center" p="5px">
                        <Box minW={"100px"}>
                            {accountData?.logo && <Image src={accountData?.logo} height={"86px"} />}
                        </Box>
                    </Flex>
                </Flex>
            ));
        } else {
            return (
                <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                    <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color={"black"}>
                        Unable to collect Account Info
                    </Heading>
                </Flex>
            );
        }
    };

    // Todo:Render Quota Details
    const renderAccountQuota =
    quotaInfo?.map((quotaItem, idx) => {
        return (
            <Flex key={idx} pt={'3px'} px="5px">
                <Flex w={'60%'}>
                <Text>{quotaItem?.quota_name}</Text>
                </Flex>
                <Flex w={'60%'}>
                <Text><b>{quotaItem?.quota_value}</b>{' Minutes Monthlty'}</Text>
                </Flex>
            </Flex>
        );
    });

    return (
        <>
            <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
                <Card w={"100%"} rounded={"lg"} mx={3} mt={1} p={1} h={'100px'}>
                    {renderAccountInfo()}
                </Card>
                <Card p={1} mt={1} mx={3} alignItems="center" rounded={'md'}>
                    <Flex w={'100%'} align={'center'} direction={'row'} maxH={'60px'}>
                        <Text fontWeight={"500"} fontSize={"18px"} >
                            Locations {locationList?.length > 0 ? locationList?.length : 0} ({currentSelection})
                        </Text>
                        <Spacer />
                        <Flex minW={'480px'} maxW={'480px'} bg={pillButtonBg} rounded={'md'} p={1} pl={2}>
                            <Text
                                minW={'70px'} maxW={'70px'}
                                _hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
                                onClick={() => {
                                    setCurrentSelection('active');
                                    collectLocationList('active');
                                }}
                                p={2} roundedLeft={'md'}
                                color={currentSelection === 'active' ? 'white' : textColor}
                                bg={currentSelection === 'active' ? pillButtonBgSelected : pillButtonBg}>
                                Active
                            </Text>
                            <Text
                                minW={'80px'} maxW={'80px'}
                                _hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
                                onClick={() => {
                                    setCurrentSelection('closed');
                                    collectLocationList('closed');
                                }}
                                color={currentSelection === 'closed' ? 'white' : textColor}
                                p={2}
                                bg={currentSelection === 'closed' ? pillButtonBgSelected : pillButtonBg}>
                                Closed
                            </Text>
                            <Text
                                _hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
                                onClick={() => {
                                    collectLocationList(currentSelection);
                                }}
                                color={currentSelection === 'refresh' ? 'white' : textColor}
                                p={2} bg={currentSelection === 'refresh' ? pillButtonBgSelected : pillButtonBg} pl={5}
                                pr={5}>Refresh
                            </Text>
                            <Text
                                _hover={{ cursor: 'pointer', bg: pillButtonBgHover, color: pillButtonTextColorHover }}
                                onClick={() => {
                                    onOpen();
                                }}
                                roundedRight={'md'}
                                color={currentSelection === 'new' ? 'white' : textColor}
                                p={2} bg={currentSelection === 'new' ? pillButtonBgSelected : pillButtonBg} pl={5}
                                pr={5}>
                                <Icon pt={1} as={AiOutlinePlus} mr={1} />
                                Add New Location
                            </Text>
                        </Flex>
                    </Flex>
                </Card>
                <Card w={"100%"} rounded={"lg"} mx={3} mt={1} p={1}>
                    <Flex h="70vh">
                        <Box
                            w={!configurationCollapseFlag ? "95%" : "85%"}
                            bg={brandBg}
                            p={1}
                            borderRadius={"10px"}
                            overflow="hidden" h="100%">
                            <Flex w={"100%"} h="100%" direction={"column"}>
                                {loading ? (
                                    <Flex w={"100%"} p={10} justifyContent={'center'} direction={"column"} align={"center"} h="70vh">
                                        <Spinner size={"xl"}  />
                                    </Flex>
                                ) : (
                                    <Flex w={"100%"} h="100%" direction={"column"}>
                                    <Flex w='100%' justifyContent={'end'} mb="3">
                                        <Menu>
                                            <MenuButton mr={2} as={Button} rightIcon={<ChevronDownIcon />} >{sort? sort === 'asc' ?'By Name (Ascending)' :'By Name (Descending)' : 'Sort Locations'}</MenuButton>
                                            <MenuList>
                                                <MenuItem onClick={()=>setSort('asc')}>By Name (Ascending)</MenuItem>
                                                <MenuItem onClick={()=>setSort('desc')}>By Name (Descending)</MenuItem>
                                            </MenuList>
                                        </Menu>
                                        <Stack spacing={4} w="25%">
                                            <InputGroup>
                                                <InputLeftAddon>
                                                    <AiOutlineSearch />
                                                </InputLeftAddon>
                                                <Input type='text' color={blackWhiteColor} placeholder='Search Location' onChange={handleSearch}/>
                                            </InputGroup>
                                        </Stack>
                                    </Flex>
                                    {locationListClone?.length && !loading ? ( 
                                        <Flex
                                            direction={"column"}
                                            w={"100%"}
                                            h="100%"
                                            overflow="auto"
                                            overflowX="hidden"
                                            className="custom-scrollbar">
                                            {renderLocationListing()}
                                        </Flex>) :
                                    (
                                        <Flex w={"100%"} p={5} bg="red.100" borderLeftWidth={"5px"} borderLeftColor={"red.400"}>
                                            <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                                                No location found.
                                            </Heading>
                                        </Flex> 
                                    )}
                                    </Flex>
                                )}
                            </Flex>
                        </Box>
                        {configurationCollapseFlag ? (
                            <Flex w="15%">
                                <Box
                                    w="100%"
                                    position="relative"
                                    p="3"
                                    borderRadius="15px"
                                    bg={brandBg}
                                    ml="2">
                                    <Box position="absolute" top="0" right="0" mr="2" mt="3" zIndex="999">
                                        <AiFillRightCircle
                                            cursor="pointer"
                                            size="20px"
                                            onClick={() => {
                                                setConfiguationCollapseFlag(!configurationCollapseFlag);
                                            }}
                                        />
                                    </Box>
                                    <Box w="100%" h="95%">
                                        <Text fontWeight={'600'} >Location Stats</Text>
                                        <Card mt='10px' borderRadius='10px'>
                                            <Text textAlign={'center'} fontWeight={'600'} color="green.500">Active Locations</Text>
                                            <Heading>
                                            <Text textAlign={'center'} fontWeight={'600'}>{activeCount}</Text>
                                            </Heading>
                                        </Card>
                                        <Card mt='10px' borderRadius='10px'>
                                            <Text textAlign={'center'} fontWeight={'600'} color="red.500">Disable Locations</Text>
                                            <Heading>
                                                <Text textAlign={'center'} fontWeight={'600'}>{closedCount}</Text>
                                            </Heading>
                                        </Card>
                                    </Box>
                                </Box>
                            </Flex>
                        ) : (
                            <Box w="5%" p="3" borderRadius="10px" bg={brandBg} h="100%" ml="2" pl="5">
                                <Box w={"100%"} h="100%">
                                    <AiFillLeftCircle
                                        cursor="pointer"
                                        onClick={() => {
                                            setConfiguationCollapseFlag(!configurationCollapseFlag);
                                        }}
                                        size="20px"
                                    />
                                    <Text
                                        fontSize="16px"
                                        mt="2"
                                        fontWeight='bold'
                                        css={{
                                            writingMode: "vertical-lr",
                                            textOrientation: "mixed",
                                        }}>
                                        Location Stats
                                    </Text>
                                </Box>
                            </Box>
                        )}
                    </Flex>
                </Card>
            </Box>
            <AddUpdateLocation 
                isOpen={isOpen} 
                close={handleClose} 
                accountUuid={accountUuid}
                callList={fetchLocationData} 
                locationData={selectedLocationData} 
                subDetailsModalFlag={subDetailsModalFlag}
            />
        </>
    );
}

export default LocationsListing;
