import React, { useState } from 'react';
import {
    Box,
    Text,
    Flex,
    Image,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    IconButton,
    useToast,
} from '@chakra-ui/react';
import 'react-tagsinput/react-tagsinput.css';


// Component
import HumanAIBg from "../../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../../assets/communicator/SecureAI.png";
import PremiumAIBg from "../../../../assets/communicator/Premium_AIIcon.png";
import DocumentAiLightBg from "../../../../assets/communicator/DocumentAILight.png";
import MultichannelBg from "../../../../assets/communicator/multichannelai.png";

import { BiDotsHorizontal } from "react-icons/bi";
import { useGreenBg, useRedBg, useBlueBg, useDummyBlueButtonTextColor } from '../../../../theme/globalColorTheme';
import { toastFunctionToaster } from '../../../../utils/toastFunction';

// Api Services
import { updateLinkFeature } from '../../../../services/businessServices';
import { useEffect } from 'react';

function LinkApp({ linkItem, supportedApps, reListLink }) {
    const toast = useToast()
    const greenBg = useGreenBg();
    const blueBg = useBlueBg();    
    const redBg = useRedBg()
    const [loading, setLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [featureData, setFeatureData] = useState([]);
    const linkFeatureArr = linkItem?.features ? linkItem.features.split(',') : [];
    // const [supportedLinkApps, setSupportedLinkApps] = useState([]);
    const supportedLinkApps = supportedApps ? supportedApps : ['hi', 'bi'];

    useEffect(() => {
        if (linkFeatureArr?.length) {
            setFeatureData(linkFeatureArr);
        }
    }, [linkItem]);

    const updateFeatures = () => {
        setLoading(true)
        if (linkItem?.key !== null) {
            const obj = {
                link_key: linkItem?.key,
                features: featureData.join(','),
            };
            updateLinkFeature(obj).then((res) => {
                setLoading(false)
                if (res?.status) {
                    reListLink('reRender')
                    toast(toastFunctionToaster(res?.message, "success"));
                    onClose()
                }
                else {
                    toast(toastFunctionToaster(res?.message, "error"));

                }
            }).catch((err) => {
                setLoading(false)

                toast(toastFunctionToaster(err?.message, "error"));

            })
        }
        else {
            setLoading(true)
            toast(toastFunctionToaster("Something went wrong", "error"));
        }

    };

    const onCloseModal = () => {
        onClose();
    };

    const renderLinkFeature = () => {
        return linkFeatureArr?.length ? (
            <Flex>
                {linkFeatureArr.filter(item => item !== "mi").map((featureName, index) => (
                    <Flex alignItems={"center"} px="2" py="1" bg={blueBg} borderRadius={"5px"} key={index} mr="2">
                        <Image mr="2" borderRadius="50px" h="20px" w="20px" src={renderFeatureImage(featureName)} />
                        <Text mr="1" fontWeight={"200"} color={useDummyBlueButtonTextColor}>
                            {renderFeatureName(featureName)}
                        </Text>
                    </Flex>
                ))}
            </Flex>
        ) : (
            <Text mr="1" p="1" bg={redBg} borderRadius={'5px'} color={"black"}>No Features Available</Text>
        );
    }

    // Todo:Render Feature Name By Code
    const renderFeatureName = (name) => {
        if (name === 'hi') {
            return 'Human Interpretation';
        } else if (name === 'bi') {
            return 'Secure AI';
        // } else if (name === 'pi') {
        //     return 'Premium AI';
        } else if (name === 'di') {
            return 'Document AI';
        } else if (name === 'mi'){
            return 'Multichannel AI';
        }
    }

    // Todo:Render Feature Image By Code
    const renderFeatureImage = name => {
        if (name === "hi") {
            return HumanAIBg;
        } else if (name === "bi") {
            return BasicAIBg;
        } else if (name === "pi") {
            return PremiumAIBg;
        } else if (name === "di") {
            return DocumentAiLightBg;
        } else return;
    };

    const handleFeatureUpdate = (value) => {
        setFeatureData((prevItems) => {
            const updatedItems = [...prevItems];
            const index = updatedItems.indexOf(value);
            if (index > -1) {
                updatedItems.splice(index, 1);
            } else {
                updatedItems.push(value);
            }
            return updatedItems;
        });
    };

    return (
        <>
            <Flex mt="2">
                <Flex alignItems={'center'}>
                    <Text as="span" mr='2' w={'200px'} fontWeight={'bold'}>
                        LingoLink Applications:
                    </Text>
                    {renderLinkFeature()}

                    <IconButton
                        borderRadius={'5px'}
                        size='sm'
                        fontSize={'22px'}
                        _hover={{
                            color:"white",
                            backgroundColor:"blue.300"
                        }}
                        ml='2'
                        bg={'blue.100'}
                        color="black"
                        // colorScheme='blue'
                        icon={<BiDotsHorizontal />}
                        onClick={onOpen}
                    />
                </Flex>

                <Modal isOpen={isOpen} onClose={onCloseModal}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Update Link Applications</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody bg="#000">
                            <Flex justifyContent={'space-between'}>
                                {supportedLinkApps?.length > 0 && supportedLinkApps.includes('hi')
                                ?<Box w="49%" cursor="pointer" 
                                    onClick={() => handleFeatureUpdate('hi')} 
                                    border={featureData.includes('hi') ? '5px solid #50785e' : 'null'} 
                                    borderRadius={'20px'}>
                                    <Image h="150px" w="100%" src={HumanAIBg} />
                                </Box>:null}
                                {supportedLinkApps?.length > 0 && supportedLinkApps.includes('bi')
                                ?<Box w="49%" cursor="pointer" onClick={() => handleFeatureUpdate('bi')} border={featureData.includes('bi') ? '5px solid #50785e' : 'null'} borderRadius={'20px'}>
                                    <Image h="150px" w="100%" src={BasicAIBg} />
                                </Box>:null}
                            </Flex>
                            <Flex justifyContent={'space-between'} mt="2">
                                {supportedLinkApps?.length > 0 && supportedLinkApps.includes('pi')
                                ?<Box w="49%" cursor="pointer" onClick={() => handleFeatureUpdate('pi')} border={featureData.includes('pi') ? '5px solid #50785e' : 'null'} borderRadius={'20px'}>
                                    <Image h="150px" w="100%" src={PremiumAIBg} />
                                </Box>:null}
                                {supportedLinkApps?.length > 0 && supportedLinkApps.includes('di')
                                ?<Box w="49%" cursor="pointer" onClick={() => handleFeatureUpdate('di')} border={featureData.includes('di') ? '5px solid #50785e' : 'null'} borderRadius={'15px'}>
                                    <Image h="150px" w="100%" src={DocumentAiLightBg} />
                                </Box>:null}
                            </Flex>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="green" borderRadius="5px" size="sm" mr={3} onClick={updateFeatures} isDisabled={featureData?.length ? false : true}
                                isLoading={loading ? true : false}
                            >
                                Update Applications
                            </Button>
                            <Button colorScheme="blue" borderRadius="5px" size="sm" onClick={onCloseModal}>
                                Cancel
                            </Button>

                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Flex>
        </>
    );
}

export default LinkApp;
