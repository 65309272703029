import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, json } from "react-router-dom";
import QRCode from "react-qr-code";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";

import HumanAIBg from "../../../../assets/communicator/HumanIcon.png";
import BasicAIBg from "../../../../assets/communicator/SecureAI.png";
import PremiumAIBg from "../../../../assets/communicator/Premium_AIIcon.png";
import DocumentAiLightBg from "../../../../assets/communicator/DocumentAILight.png";

// Chakra Import
import {
    Box,
    Flex,
    Text,
    Link,
    Image,
    Button,
    Select,
    Icon,
    Spacer,
    Alert,
    Drawer,
    Spinner,
    Tooltip,
    RadioGroup,
    Stack,
    Radio,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    Tab,
	Tabs,
	TabList,
	TabPanel,
	TabPanels,
    AlertIcon,
    FormLabel,
    SimpleGrid,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Textarea,
    useToast,
    Checkbox,
    AlertDialog,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    FormControl,
    FormHelperText,
    Input,
    CheckboxGroup,
} from "@chakra-ui/react";
import InputField from "../../../../components/fields/InputField";
import moment from "moment";
import { defaultThemeColor } from "../../../../constants";
import Card from "../../../../components/card/Card";
import {
    AiOutlineCheckCircle,
    AiOutlineCopy,
    AiOutlineBulb,
    AiOutlineLink,
    AiOutlineMail,
    AiOutlineStop,
    AiOutlineSync,
    AiTwotoneAudio,
    AiOutlineVideoCamera,
    AiOutlineClockCircle,
    AiOutlineAntDesign
} from "react-icons/ai";
// Api Call Imports
import {
    getSingleLink,
    addNewLink,
    getAllLinks,
    disableLink,
    shareLinkViaEmail,
    shareLinkViaSms, 
    refreshLink,
    getLinkCustomerEnabled,
    shareLinkWithCustomer,
    resetLeaderCode,
    updatelingoLink
} from "../../../../services/businessServices";
import { formatTime, formatSeconds } from '../../../../utils/TimeFunctions';
import MultichannelBg from "../../../../assets/communicator/multichannelai.png";

// Component Imports
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import {
    useBlueButton, useGrayTextColor, useGray500TextColor, useHOrange,
    useGrayShadeCardBg, useGrayShadeCardBorderBg,
    useNoResultFoundText, usePillButtonBg, useRedButton, useTextColor, useYellowButton,
    useCardHeaderActiveBg,
    useCardHeaderNonActiveBg,
    useGradientColor,
    useBlackWhiteBg,
    useLinkColor,
    usePillButtonHighLight
} from "../../../../theme/globalColorTheme";
import LinkEmailLeaderCode from "./LinkEmailLeaderCode";
import LinkQrPdf from "../../../subscriber/links/LinkQrPdf";
import TagsInput from "react-tagsinput";
import { fetchLocationInfo } from "../../../../services/accountServices";


function LinkManager() {
    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();
    const yellowButtonBg = useYellowButton();
    const ErrorText = useNoResultFoundText()
    const buttonText = useTextColor()
    const grayText = useGrayTextColor()
    const buttonBg = usePillButtonBg()
    const blueButtonBg = useBlueButton()
    const redButton = useRedButton()
    const blueButton = useBlueButton()
    const orangeBg = useHOrange();
    const localGray500TextColor = useGray500TextColor();
    const linkColor = useLinkColor();
    const pillButtonBgHighlight = usePillButtonHighLight()

    const currentHost = window.location.hostname.includes('localhost') ? 'https://lingolet.ai/' : window.location.protocol + "//" + window.location.hostname + "/";
    const currentUseType = window.location.href.includes('/business/') ? "businessadmin" : "accountadmin";
    const btnRef = React.useRef();
    const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure()
    const { isOpen: isSideBarOpen, onOpen: onSideBarOpen, onClose: onSideBarClose } = useDisclosure()
    const cancelRef = useRef()
    const [activeLink, setActiveLink] = useState(null)
    const [loading, isLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);

    const {
        isOpen: isOpenQr,
        onOpen: onOpenQr,
        onClose: onCloseQr,
    } = useDisclosure();
    const qrRef = React.useRef();
    const linkUuid = state?.linkUuid ? state?.linkUuid : null;
    const type = state?.type ? state?.type : null;

    const [linkInfo, setLinkInfo] = useState([]);
    const [saveDetailsLoader, setSaveDetailsLoader] = useState(false);

    const [showTooltip, setShowTooltip] = React.useState(false);
    const [linkUrl, setLinkUrl] = useState(null);
    const [modalQrCode, setModalQrCode] = useState(null);
    const [customerInfo, setCustomerInfo] = useState([]);
    const [linkCustomer, setLinkCustomer] = useState([]);
    const [linkCustomers, setLinkCustomers] = useState([]);
    const [linkUrlWithCode, setLinkUrlWithCode] = useState(null);
    const [subAppDetails, setSubAppDetails] = useState([]);

    const [toEmails, setToEmails] = useState([]);
    const [toSms, setToSms] = useState([]);
    const [urlGroup, setUrlGroup] = useState(1);
    const [sendCode, setSendCode] = React.useState(false);
    const [linkTitleFlag, setLinkTitleFLag] = useState(false)
    const [errors, setErrors] = useState(null)
    const localCardHeaderNonActiveBg = useCardHeaderNonActiveBg();
    const localCardHeaderActiveBg = useCardHeaderActiveBg();
    const gradientColor = useGradientColor()
    const blackWhiteBg = useBlackWhiteBg()


    const [updatedValues, setUpdatedValues] = useState({
        title: '',
        linkStartDateTime: new Date(),
        linkEndDateTime: new Date(),
        isCallDurationUnlimited: false,
        callTimeValue: 50,
        selectedApps: []
    })
    const touched = useRef({})
    const [isUpdateDisabled, setIsUpdateDisabled] = useState(false)

    function getValues() {
        return {
            title: linkInfo[0]?.title,
            linkStartDateTime: moment.utc(linkInfo[0]?.starttime).local().format('YYYY-MM-DD HH:mm'),
            linkEndDateTime: moment.utc(linkInfo[0]?.endtime).local().format("YYYY-MM-DD HH:mm"),
            isCallDurationUnlimited: linkInfo[0]?.max_time_min === 9999,
            callTimeValue: linkInfo[0]?.max_time_min,
            selectedApps: linkInfo[0]?.features?.split(",")

        }
    }

    function valuesUpdated() {
        const newValues = {
            ...updatedValues
        }
        const oldValues = getValues()
        return JSON.stringify(newValues) === JSON.stringify(oldValues)
    }


    useEffect(() => {
        if (linkInfo[0]?.class === 1) {
            if (!updatedValues.title || linkInfo[0].title === updatedValues.title || updatedValues?.selectedApps?.length == 0) {
                return setIsUpdateDisabled(true)
            }

        }
        if (linkInfo[0]?.class === 3) {
            if (!updatedValues?.title || !updatedValues?.callTimeValue || !updatedValues?.selectedApps?.length === 0 || !updatedValues?.linkEndDateTime || !updatedValues?.linkStartDateTime) {
                return setIsUpdateDisabled(true)
            }
            if (valuesUpdated()) return setIsUpdateDisabled(true)
        }
        setIsUpdateDisabled(false)
    }, [updatedValues, linkInfo])

    function calculateMinutes() {
        const differenceInMs = new Date(updatedValues.linkEndDateTime) - new Date(updatedValues.linkStartDateTime);
        const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));
        return differenceInMinutes;
    }

    useEffect(() => {
        touched.current['link_title'] && !updatedValues.linkTitle?.length ? setLinkTitleFLag(true) : setLinkTitleFLag(false)
    }, [updatedValues.linkTitle])

    useEffect(() => {
        let durationInMinutes = calculateMinutes()
        if (new Date(updatedValues.linkEndDateTime) < new Date() || new Date(updatedValues.linkStartDateTime) < new Date()) {
            setErrors(errors => ({ ...errors, durationError: "Start and End Date-Time should be greater than current Date-time" }))
            return
        }
        if (durationInMinutes < 60 || durationInMinutes > 43200) {
            setErrors(errors => ({ ...errors, durationError: "Time Gap between Start and End Date-time should be greater than 1 hour and less than 31 days" }))
            return
        }
        setErrors(errors => ({ ...errors, durationError: "" }))
    }, [updatedValues.linkStartDateTime, updatedValues.linkEndDateTime, updatedValues.newLingoLinkType])


    function handleBlur(name) {
        touched.current = { ...touched.current, [name]: true }
        if (!updatedValues?.linkStartDateTime || !updatedValues?.linkEndDateTime) {
            setErrors(errors => ({ ...errors, durationError: "Both Fields are required!" }))
        }
        touched.current['link_title'] && !updatedValues?.title?.length ? setLinkTitleFLag(true) : setLinkTitleFLag(false)
    }

    const DatetimePickerStyles =
    {
        height: "38.5px",
        padding: "0 16px",
        width: "100%",
        border: ".8px solid #cbd5e0",
        background: "transparent",
        borderRadius: "5px",
        color: buttonText
    }

    const labelStyles = {
        mt: "12",
        ml: "-2.5",
        fontSize: "sm",
    };
    const labelStylesVoice = {
        mt: "1",
        ml: "-1",
        mr: "2.0",
        fontSize: "sm",
    };

    //   Todo:Fetch all projects
    useEffect(() => {
        getLinkDetails();
        collectLinkCustomers();
    }, []);

    useEffect(() => {
        if (currentUseType === 'accountadmin') {
            collectLocationInfo();
        } else {
            setSubAppDetails(['hi', 'bi', 'mi', 'di']);
        }
    }, []);

    const collectLocationInfo = () => {
        isLoading(true);
        let paramObj = {
            key: 'location',
        };
        fetchLocationInfo(paramObj)
            .then(res => {
                isLoading(false);
                if (res?.data[0]?.status) {
                    isLoading(false);
                    setSubAppDetails(res?.data[0]?.info[0]?.apps?.length ? res?.data[0]?.info[0]?.apps?.split(',') : []);
                } else {
                    isLoading(false);
                }
            })
            .catch(err => {
                isLoading(false);
            });
    };

    useEffect(() => {
        const values = getValues()
        setUpdatedValues(values)
    }, [linkInfo])

    const getLinkDetails = () => {
        if (linkUuid != null) {
            isLoading(true);
            let paramObj = {
                link_uuid: linkUuid,
            };
            getSingleLink(paramObj)
                .then((res) => {
                    isLoading(false);
                    if (res?.data[0]?.status) {
                        setLinkInfo(res?.data[0]?.info);
                        setLinkCustomer(res?.data[0]?.customer);
                    }
                })
                .catch((err) => {
                    isLoading(false);
                });
        }
    };

    const refreshDynaLink = (linkUuidx) => {
        if (linkUuidx != null) {
            let objBody = {
                link_uuid: linkUuidx,
            };
            refreshLink(objBody)
                .then((res) => {
                    if (res?.result) {
                        if (res?.data[0]?.status) {
                            getLinkDetails();
                            toast(
                                toastFunctionToaster(
                                    "Link access code is successfully refreshed",
                                    "success"
                                )
                            );
                        } else {
                            toast(
                                toastFunctionToaster(
                                    "Failed to refresh the access code...",
                                    "error"
                                )
                            );
                        }
                    } else {
                        toast(
                            toastFunctionToaster(
                                "Failed to refresh the access code...",
                                "error"
                            )
                        );
                    }
                })
                .catch((err) => {
                    toast(
                        toastFunctionToaster(
                            "Failed to refresh the access code...",
                            "error"
                        )
                    );
                });
        } else {
            toast(
                toastFunctionToaster("Failed to refresh the access code...", "error")
            );
        }
    };

    // Todo:Reset Leader Code
    const refreshLeaderCode = (linkUuidx) => {
        if (linkUuidx != null) {
            let objBody = {
                link_uuid: linkUuidx,
            };
            resetLeaderCode(objBody)
                .then((res) => {
                    if (res?.result) {
                        if (res?.data[0]?.status) {
                            getLinkDetails();
                            toast(
                                toastFunctionToaster(
                                    "Leader access code is successfully refreshed",
                                    "success"
                                )
                            );
                        } else {
                            toast(
                                toastFunctionToaster(
                                    "Failed to refresh the leader access code...",
                                    "error"
                                )
                            );
                        }
                    } else {
                        toast(
                            toastFunctionToaster(
                                "Failed to refresh the leader access code...",
                                "error"
                            )
                        );
                    }
                })
                .catch((err) => {
                    toast(
                        toastFunctionToaster(
                            "Failed to refresh the leader access code...",
                            "error"
                        )
                    );
                });
        } else {
            toast(
                toastFunctionToaster("Failed to refresh the leader access code...", "error")
            );
        }
    };



    const disableSelectedLink = () => {
        const { uuid, code } = activeLink
        let paramObj = {
            link_uuid: uuid,
            link_code: code,
        };
        handleAlertClose()
        disableLink(paramObj)
            .then((res) => {
                if (res?.result && res?.data?.length > 0) {
                    if (res?.data[0]?.status) {
                        getLinkDetails("active");
                        toast(
                            toastFunctionToaster(
                                "Selected LingoLink is successfully disabled.",
                                "success"
                            )
                        );
                    } else {
                        toast(
                            toastFunctionToaster(
                                "Unable to complete the selected request..",
                                "error"
                            )
                        );
                    }
                } else {
                    toast(
                        toastFunctionToaster(
                            "Unable to complete the selected request..",
                            "error"
                        )
                    );
                }
            })
            .catch((err) => {
                toast(
                    toastFunctionToaster(
                        "Unable to complete the selected request...",
                        "error"
                    )
                );
            });
    };

    const collectLinkCustomers = () => {
        let paramObj = {
            records_per_page: 0,
            page_id: 0,
        };
        getLinkCustomerEnabled(paramObj)
            .then((res) => {
                if (res?.data[0]?.count) {
                    isLoading(false);
                    setLinkCustomers(res?.data[0]?.records);
                } else {
                    isLoading(false);
                    setLinkCustomers([]);
                }
            })
            .catch((err) => {
                setLinkCustomers([]);
            });
    };

    const shareDynaLink = () => {
        if (toEmails?.length > 0 && linkUuid != null && (urlGroup == '11' || urlGroup == '10')) {
            let objBody = {
                target_emails: toEmails?.join(","),
                link_uuid: linkUuid,
                host_name: currentHost,
                url_group: urlGroup,
                is_code: sendCode,
            };
            shareLinkViaEmail(objBody)
                .then((res) => {
                    if (res?.result) {
                        toast(toastFunctionToaster(res.message, "success"));
                    } else {
                        toast(toastFunctionToaster(res.message, "error"));
                    }
                })
                .catch((err) => {
                    toast(toastFunctionToaster(err.message, "error"));
                });
        } else {
            toast(
                toastFunctionToaster(
                    "You must provide email addresse and select which url to share..",
                    "error"
                )
            );
        }
    };

    const smsDynaLink = () => {
        if (toSms?.length > 0 && linkUuid != null && (urlGroup == '11' || urlGroup == '10')) {
            let objBody = {
                target_phone: toSms[0],
                link_uuid: linkUuid,
                host_name: currentHost,
                url_group: urlGroup,
                is_code: sendCode,
            };
            shareLinkViaSms(objBody)
                .then((res) => {
                    if (res?.result) {
                        toast(toastFunctionToaster(res.message, "success"));
                    } else {
                        toast(toastFunctionToaster(res.message, "error"));
                    }
                })
                .catch((err) => {
                    toast(toastFunctionToaster(err.message, "error"));
                });
        } else {
            toast(
                toastFunctionToaster(
                    "You must provide Phone number and select which url to send in SMS",
                    "error"
                )
            );
        }
    };

    const shareLinkWithSelectedCustomer = (lnkCustomerUuid) => {
        let paramObj = {
            customerUuid: lnkCustomerUuid,
            linkUuid: linkUuid,
        };
        shareLinkWithCustomer(paramObj)
            .then((res) => {
                if (res?.data[0]?.status) {
                    getLinkDetails();
                    toast(toastFunctionToaster(res.message, "success"));
                } else {
                    toast(toastFunctionToaster(res.message, "error"));
                }
            })
            .catch((err) => {
                toast(toastFunctionToaster('Unable to share LingoLink with selected customer..', "error"));
            });
    };

    const renderAllCustomers = linkCustomers?.map((linkCust, index) => (
        <option
            key={linkCust.uuid}
            value={linkCust.uuid}
            style={{ color: "black" }}
        >
            {linkCust.info}
        </option>
    ));

    const launchLinkPrefix = () => {
        if (window.location.pathname.includes('/account')) {
            return '/account';
        } else if (window.location.pathname.includes('/business')) {
            return '/business';
        }
        return null;
    }

    function handleOpenAlert(uuid, code) {
        setActiveLink({ uuid, code })
        onAlertOpen()
    }

    function handleAlertClose() {
        onAlertClose()
        setActiveLink('')
    }

    function handleSideBarClose() {
        onSideBarClose()
        setErrors(null)
        touched.current = {}
        const values = getValues()
        setUpdatedValues(values)
    }

    function handleUpdateLink() {
        let durationInMinutes = calculateMinutes()
        let locDurationValue = durationInMinutes / 60
        let locCallTimeValue = updatedValues?.isCallDurationUnlimited ? 9999 : updatedValues.callTimeValue
        const paramObj = linkInfo[0]?.class === 3 ? {
            "link_title": updatedValues?.title,
            "start_time": moment(new Date(updatedValues?.linkStartDateTime)).utc().format('YYYY-MM-DD HH:mm:ss'),
            "active_hours": locDurationValue,
            "features": updatedValues?.selectedApps?.join(","),
            "callTime": locCallTimeValue,
            "link_uuid": linkInfo[0]?.uuid
        } : {
            "link_title": updatedValues?.title,
            "start_time": new Date(),
            "active_hours": 0,
            "features": "string",
            "callTime": 0,
            "link_uuid": linkInfo[0]?.uuid
        }

        updatelingoLink(paramObj).then(resp => {
            if (resp?.data[0]?.status) {
                toast(toastFunctionToaster("Link Details updated Successfully", "success"))
                handleSideBarClose()
                getLinkDetails()
            } else {
                toast(toastFunctionToaster("Failed to update link details", "error"))
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to update link details", "error"))
        })
    }

    const renderLinkDetails = linkInfo !== null && linkInfo?.length > 0
        ? linkInfo?.map((item, index) => (
            <Flex w={'100%'} direction={'column'}>
                <Flex w={'100%'} align={'center'}>
                    <Flex w={'100%'} direction={'column'}>
                        <Flex w={'100%'} mb="1" flexWrap='wrap' alignItems="center" p={2} bg={item?.dead ? localCardHeaderNonActiveBg : localCardHeaderActiveBg} roundedTop={'md'} borderBottomWidth={'0.5px'} borderColor={gradientColor} justifyContent="space-between">
                            <Flex gap={1}>
                            {linkInfo[0]?.multichannel
                                ? <Flex w={'50px'}>
                                    <Image h={'40px'} w={'40px'} borderRadius="5px" src={MultichannelBg} />
                                </Flex> : null}
                            <Text
                                rounded={"md"}
                                px={2}

                            >
                                {linkInfo[0]?.dead ? (
                                    <Icon
                                        as={AiOutlineStop}
                                        color={"red.600"}
                                        ml={1}
                                        mr={1}
                                        mt={1}
                                    />
                                ) : null}
                                {linkInfo[0]?.title}
                            </Text>
                            </Flex>
                            <Flex gap={1}>
                                <Flex align={'center'} mr="2" ml="2">
                                    <Text p="4px" w='85px' color={"gray.600"} borderWidth={'0.05px'} fontWeight={"bold"} textAlign="center" bg={"green.100"} borderColor={'green.200'} fontSize={"sm"} borderLeftRadius={'10px !important'} mr='-3'>
                                        {linkInfo[0]?.code}
                                    </Text>
                                    <Button
                                        onClick={() => refreshDynaLink(linkInfo[0]?.uuid)}
                                        size={"sm"}
                                        bg={blueButton}
                                        color={buttonText}
                                        colorScheme="gray"
                                        _hover={{ bg: blueButton, color: buttonText }}
                                        borderRadius={'10px'}
                                    >
                                        <Text as="span" ml="1"> Change Access Code</Text>
                                    </Button>
                                </Flex>
                                {item?.multichannel ? (
                                    <Flex align={'center'}>
                                        <Text p="6px" w='60px' fontWeight={"bold"} pl='10px' bg={"green.200"} borderRadius={'0px'} fontSize={"sm"} color={grayText} borderLeftRadius={'10px !important'} mr='-3'>
                                            {linkInfo[0]?.leader_code}
                                        </Text>
                                        <Button
                                            onClick={() => refreshLeaderCode(linkInfo[0]?.uuid)}
                                            size={"sm"}
                                            bg={blueButton}
                                            color={buttonText}
                                            colorScheme="gray"
                                            buttonText
                                            borderRadius={'10px'}
                                            _hover={{ bg: blueButton, color: buttonText }}
                                        >
                                            <Text as="span" ml="1"> Change Leader Code</Text>
                                        </Button>
                                    </Flex>
                                ) : null}
                                <Button size={'sm'} colorScheme="teal" onClick={onSideBarOpen}>Edit LingoLink</Button>
                                <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Disable Link (Instantly becomes unusable)"
                                >
                                    <Button
                                        ml={1}
                                        bg={redButton}
                                        onClick={() => {
                                            handleOpenAlert(linkInfo[0]?.uuid, linkInfo[0]?.code);
                                        }}
                                        size={"sm"}
                                        color={buttonText}
                                    >
                                        Disable Link
                                    </Button>
                                </Tooltip>
                            </Flex>
                        </Flex>
                        <Flex flexDir="column">
                        {linkInfo[0]?.trial
                            ? <Flex w={"100%"}>
                                <Text w={'200px'} color={localGray500TextColor}>
                                    Link Start Time (UTC):
                                </Text>
                                <Text w={'200px'}>
                                    {moment.utc(linkInfo[0]?.starttime).format("MMM DD,YYYY,HH:mm a")}
                                </Text>
                                <Text w={'200px'} color={localGray500TextColor}>
                                    Link End Time (UTC):
                                </Text>
                                <Text w={'200px'}>
                                    {moment.utc(linkInfo[0]?.endtime).format("MMM DD,YYYY,HH:mm a")}
                                </Text>
                            </Flex> : null}
                        {linkInfo[0]?.trial
                            ? <Flex w={"100%"} >
                                <Text w={'200px'} color={localGray500TextColor}>
                                    Link Assigned Time:
                                </Text>
                                <Text w={'200px'}>
                                    {linkInfo[0]?.hours} Hours
                                </Text>
                                <Text w={'200px'} color={localGray500TextColor}>
                                    Time Left:
                                </Text>
                                <Text w={'300px'}>
                                    {linkInfo[0]?.time_left}
                                </Text>
                            </Flex> : null}
                        </Flex>
                        <Flex w={'100%'} p={2} mt={1} flexWrap={'wrap'}>
                            <Button
                                onClick={() => {
                                    navigate(launchLinkPrefix() + "/linkmananger/sessions", {
                                        state: {
                                            linkUuid: linkInfo[0]?.uuid,
                                            linkCode: linkInfo[0]?.code,
                                        },
                                    });
                                }}
                                bg={blueButtonBg}
                                size="xs"
                            >
                                List all Sessions
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate(launchLinkPrefix() + "/linkmananger/calls", {
                                        state: {
                                            linkUuid: linkInfo[0]?.uuid,
                                            linkCode: linkInfo[0]?.code,
                                            linkKey: linkInfo[0]?.key
                                        },
                                    });
                                }}
                                ml={1}
                                bg={blueButtonBg}
                                size="xs"
                            >
                                List all Calls
                            </Button>
                            <Tooltip
                                hasArrow
                                placement={"top"}
                                bg="teal.500"
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                                color="white"
                                label="Add logo, background image for specific link"
                            >
                                <Button
                                    ml={1}
                                    bg={blueButtonBg}
                                    onClick={() => {
                                        navigate(launchLinkPrefix() + "/linkmananger/configuration", {
                                            state: {
                                                linkUuid: linkInfo[0]?.uuid,
                                                linkKey: linkInfo[0]?.key
                                            },
                                        });
                                    }}
                                    size={"xs"}
                                >
                                    <Icon as={AiOutlineAntDesign} mr={2} />
                                    Configure Link
                                </Button>
                            </Tooltip>
                            <Button ml={1} bg={yellowButtonBg} size={"xs"}
                                onClick={() => {
                                    navigate(launchLinkPrefix() + "/linkmananger/transcripts",
                                        {
                                            state: {
                                                linkDetails: linkInfo
                                            },
                                        }
                                    );
                                }}>
                                <Icon as={AiOutlineAntDesign} mr={2} /> Transcripts
                            </Button>
                            <Button ml={1} bg={orangeBg} size={"xs"} overflow="hidden">
                                <LinkQrPdf linkItem={linkInfo} />
                            </Button>
                            {item?.multichannel ? (
                                <LinkEmailLeaderCode linkItem={item} />
                            ) : null}
                        </Flex>
                    </Flex>
                    {/* <Flex w={'15%'} justifyContent='flex-end'>
                        <Flex maxW={'300px'} ml={1}>
                            <Flex w={'100%'}>
                                <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Share Link and Access Code to anyone over Email"
                                >
                                    <Button
                                        bg={"blue.100"}
                                        onClick={() => {
                                            setCustomerInfo(null);
                                            collectLinkCustomers();
                                            setLinkUrl(linkInfo[0]?.url);
                                            setLinkUrlWithCode(linkInfo[0]?.fullurl);
                                        }}
                                        size={"sm"}
                                    >
                                        <Icon as={AiOutlineMail} mr={2} />
                                        Share
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Disable Link (Instantly becomes unusable)"
                                >
                                    <Button
                                        ml={1}
                                        bg={redButton}
                                        onClick={() => {
                                            handleOpenAlert(linkInfo[0]?.uuid, linkInfo[0]?.code);
                                        }}
                                        size={"sm"}
                                        color={buttonText}
                                    >
                                        Disable Link
                                    </Button>
                                </Tooltip>
                            </Flex>
                        </Flex>
                    </Flex> */}
                </Flex>
                <Flex w={'100%'} p={2}>
                    <Flex
                        w={"100%"}
                        p={1}
                        direction={'column'}
                        //useGrayShadeCardBorderBg
                        rounded={"md"}
                        bg={blackWhiteBg}
                    >
                        <Flex w={"100%"} direction={"column"} >
                            <Flex w={'100%'} mt={1} mb={1} >
                                <Flex w={'100%'} >
                                    <Text mt={2} mr={2} fontSize={"lg"} color={linkColor}>
                                        {linkInfo[0]?.url ? currentHost + linkInfo[0]?.url : "No Url"}
                                    </Text>
                                </Flex>
                                <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Copy Url"
                                >
                                    <Box
                                        w={"35px"}
                                        ml={1}
                                        _hover={{
                                            bg: pillButtonBgHighlight,
                                            cursor: "pointer",
                                            color: 'black'
                                        }}
                                        color={buttonText}
                                        bg={buttonBg}
                                        pr={2}
                                        rounded={"md"}
                                        mr={2}
                                    >
                                        <Icon
                                            onClick={() => navigator.clipboard.writeText(currentHost + linkInfo[0]?.url)}
                                            ml={2}
                                            mt={1}
                                            h={5}
                                            _hover={{ cursor: "pointer" }}
                                            w={5}
                                            as={AiOutlineCopy}
                                        />
                                    </Box>
                                </Tooltip>
                                <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Open Url in new Tab"
                                >
                                    <Link
                                        p={1}
                                        w={'35px'}
                                        _hover={{
                                            bg: pillButtonBgHighlight,
                                            cursor: "pointer",
                                            color: 'black'
                                        }}
                                        color={buttonText}
                                        bg={buttonBg}
                                        rounded={"md"}
                                        mr={2}
                                        align={'center'}
                                        href={currentHost + linkInfo[0]?.url}
                                        target="_blank"
                                    >
                                        <Icon
                                            ml={2}
                                            mt={1}
                                            h={5}
                                            _hover={{ cursor: "pointer" }}
                                            w={5}
                                            as={AiOutlineLink}
                                        />
                                    </Link>
                                </Tooltip>
                            </Flex>
                            <Flex w={'100%'} mt={1} mb={1} borderTopColor={'gray.300'} borderTopWidth={'0.5px'}>
                                <Flex w={'100%'} mt={1}>
                                    <Text mt={2} mr={2} fontSize={"lg"} color={linkColor}>
                                        {linkInfo[0]?.fullurl ? currentHost + linkInfo[0]?.fullurl : "No Url"}
                                    </Text>
                                </Flex>
                                <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Copy Url"
                                >
                                    <Box
                                        mt={1}
                                        w={"35px"}
                                        ml={1}
                                        _hover={{
                                            bg: pillButtonBgHighlight,
                                            cursor: "pointer",
                                            color: 'black'
                                        }}
                                        color={buttonText}
                                        bg={buttonBg}
                                        pr={2}
                                        rounded={"md"}
                                        mr={2}
                                    >
                                        <Icon
                                            onClick={() => navigator.clipboard.writeText(currentHost + linkInfo[0]?.fullurl)}
                                            ml={2}
                                            mt={1}
                                            h={5}
                                            _hover={{ cursor: "pointer" }}
                                            w={5}
                                            as={AiOutlineCopy}
                                        />
                                    </Box>
                                </Tooltip>
                                <Tooltip
                                    hasArrow
                                    placement={"top"}
                                    bg="teal.500"
                                    onMouseEnter={() => setShowTooltip(true)}
                                    onMouseLeave={() => setShowTooltip(false)}
                                    color="white"
                                    label="Open Url in new Tab"
                                >
                                    <Link
                                        mt={1}
                                        p={1}
                                        w={'35px'}
                                        _hover={{
                                            bg: pillButtonBgHighlight,
                                            cursor: "pointer",
                                            color: 'black'
                                        }}
                                        color={buttonText}
                                        bg={buttonBg}
                                        rounded={"md"}
                                        mr={2}
                                        align={'center'}
                                        href={currentHost + linkInfo[0]?.fullurl}
                                        target="_blank"
                                    >
                                        <Icon
                                            ml={2}
                                            mt={1}
                                            h={5}
                                            _hover={{ cursor: "pointer" }}
                                            w={5}
                                            as={AiOutlineLink}
                                        />
                                    </Link>
                                </Tooltip>
                            </Flex>
                            <Flex w={'100%'}>
                                {linkInfo[0]?.trial ? (
                                    (linkInfo[0]?.calls?.expired
                                        ? <Flex w={'100%'} bg={'red.200'} align={'center'} rounded={'md'} p={1} borderWidth={"0.05px"} borderColor={"gray.400"} >
                                            <Box w={'250px'}>
                                                <Text color={"red.700"}>Expired Trial Link</Text>
                                            </Box>
                                            <Flex w={'100%'} direction={'column'}>
                                                <Box w={'100%'}>
                                                    <Icon as={AiOutlineClockCircle} mr={1} mt={1} h={5} w={5} /> Time: <b>{formatSeconds(linkInfo[0]?.calls?.calls[6].toFixed(2))}</b>  | Used: <b>{formatSeconds(linkInfo[0]?.calls?.calls[7].toFixed(2))}</b> | Remaining: <b>{linkInfo[0]?.calls?.calls[8] > 0 ? formatSeconds(linkInfo[0]?.calls?.calls[8].toFixed(2)) : "00:00:00"}</b>
                                                </Box>
                                                <Flex w={'100%'}>
                                                    <Box w={'100%'}>
                                                        <Icon as={AiTwotoneAudio} mr={1} mt={1} h={5} w={5} /> Calls: <b>{linkInfo[0]?.calls?.calls[0]} | Made: {linkInfo[0]?.calls?.calls[1]} | Remaining: {linkInfo[0]?.calls?.calls[2]}</b>
                                                    </Box>
                                                    <Box w={'100%'}>
                                                        <Icon as={AiOutlineVideoCamera} mr={1} mt={1} h={5} w={5} /> Calls: <b>{linkInfo[0]?.calls?.calls[3]} | Made: {linkInfo[0]?.calls?.calls[4]} | Remaining: {linkInfo[0]?.calls?.calls[5]}</b>)
                                                    </Box>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        : <Flex w={'100%'} bg={'green.200'} align={'center'} rounded={'md'} p={1} borderWidth={"0.05px"} borderColor={"gray.400"}>
                                            <Box w={'250px'}>
                                                <Text color={"blue.700"}>Active Trial Link</Text>
                                            </Box>
                                            <Flex w={'100%'} direction={'column'}>
                                                <Box w={'100%'}>
                                                    <Icon as={AiOutlineClockCircle} mr={1} mt={1} h={5} w={5} /> Time: <b>{formatSeconds(linkInfo[0]?.calls?.calls[6].toFixed(2))}</b> | Used: <b>{formatSeconds(linkInfo[0]?.calls?.calls[7].toFixed(2))}</b>  | Remaining: <b>{linkInfo[0]?.calls?.calls[8] > 0 ? formatSeconds(linkInfo[0]?.calls?.calls[8].toFixed(2)) : "00:00:00"} </b>
                                                </Box>
                                                {/* <Flex w={'100%'}>
                                                    <Box w={'100%'}>
                                                        <Icon as={AiTwotoneAudio} mr={1} mt={1} h={5} w={5} /> Calls: <b>{linkInfo[0]?.calls?.calls[0]} | Made: {linkInfo[0]?.calls?.calls[1]} | Remaining: {linkInfo[0]?.calls?.calls[2]}</b>
                                                    </Box>
                                                    <Box w={'100%'}>
                                                        <Icon as={AiOutlineVideoCamera} mr={1} mt={1} h={5} w={5} /> Calls: <b>{linkInfo[0]?.calls?.calls[3]} | Made: {linkInfo[0]?.calls?.calls[4]} | Remaining: {linkInfo[0]?.calls?.calls[5]}</b>)
                                                    </Box>
                                                </Flex> */}
                                            </Flex>
                                        </Flex>
                                    )
                                ) : (
                                    <Flex w={'100%'}>
                                        {/* {linkInfo[0].multichannel === false
                                            ? <Flex w={'100%'} bg={'green.200'} align={'center'} rounded={'md'} p={1} borderWidth={"0.05px"} borderColor={"gray.400"}>
                                                <Text fontSize={'md'} color={"green.700"}>Not a trial/demo link.</Text>
                                            </Flex> : null} */}
                                    </Flex>
                                )}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex w={'100%'} h={'150px'}>
                    <Flex maxW={'250px'}>
                        <Flex w={'100%'} direction={'column'} p={4} align={'center'}>
                            <Box w={'100%'}
                                onClick={() => {
                                    setModalQrCode(linkInfo[0]?.url);
                                    onOpenQr();
                                }}
                                _hover={{ cursor: 'pointer', borderColor: 'red.600', borderWidth: '1px' }}
                                p={1} borderColor={'gray.400'} borderWidth={'1px'} rounded={'md'}>
                                <QRCode
                                    size={90}
                                    style={{ height: "auto", maxWidth: "100", width: "100" }}
                                    value={linkInfo[0]?.url}
                                    viewBox={`0 0 90 90`}
                                />
                            </Box>
                            <Text>QR(Url)</Text>
                        </Flex>
                    </Flex>
                    <Flex maxW={'250px'}>
                        <Flex w={'100%'} direction={'column'} p={4} align={'center'}>
                            <Box w={'100%'}
                                onClick={() => {
                                    setModalQrCode(linkInfo[0]?.fullurl);
                                    onOpenQr();
                                }}
                                _hover={{ cursor: 'pointer', borderColor: 'red.600', borderWidth: '1px' }}
                                p={1} borderColor={'gray.400'} borderWidth={'1px'} rounded={'md'}>
                                <QRCode
                                    size={90}
                                    style={{ height: "auto", maxWidth: "100", width: "100" }}
                                    value={linkInfo[0]?.fullurl}
                                    viewBox={`0 0 90 90`}
                                />
                            </Box>
                            <Text>QR(Url+Code)</Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex w={'100%'} h={'300px'}>
                    {linkInfo[0]?.design?.logo
                        ? <Flex w={'300px'} h={'100px'} p={5} direction={'column'} align={'center'}>
                            <Text>Lingolink Logo</Text>
                            <Image src={linkInfo[0]?.design?.logo} w={'160px'} />
                        </Flex> : null}
                    {linkInfo[0]?.design?.background
                        ? <Flex w={'100%'} p={5} direction={'column'} align={'center'}>
                            <Text>Lingolink Background</Text>
                            <Image src={linkInfo[0]?.design?.background} minW={'400px'} minH={'200px'} />
                        </Flex> : null}
                </Flex>
            </Flex>)) : null;

	// Todo:Tab Change
	const handleTabChange = index => {
		setActiveTab(index);
	};

    return (
        <>
            <Box direction="column" pt={{ sm: "125px", lg: "75px" }}>
                <Flex
                    p="3"
                    alignItems="center"
                    borderBottomWidth="1px"
                    justifyContent={"space-between"}>
                    <Text fontWeight={"500"} fontSize={"18px"}>
                        Manage your LingoLink
                    </Text>
                    <Spacer />
                    <Flex w={'150px'}>
                        <Button ml={1} bg={buttonBg} size="sm" color={buttonText}
                            onClick={() => {
                                navigate(launchLinkPrefix() + "/linklisting", {
                                });
                            }}>
                            Back to Link Listing
                        </Button>
                    </Flex>
                </Flex>
                {loading ? (
                    <Flex alignItems="center" justifyContent="center" h="50vh">
                        <Spinner size="xl" />
                    </Flex>) :
                    linkInfo?.length > 0
                        ? <Flex
                            p={0}
                            w={"100%"}
                            display="flex"
                            flexDirection="row"
                            mt={2}
                            rounded={'md'}
                            // borderWidth={"0.05px"}
                        >
                            <Card w={'100%'} p={0} rounded={'md'} 
                                boxShadow={'base'}
                                //borderWidth={'1px'} borderColor={'gray.200'}
                                >
                                {renderLinkDetails}
                            </Card>
                            <Card ml={2}  h={'100%'} boxShadow={'base'} minW={'400px'} maxW={'400px'}
                                direction={'column'} p={2} 
                                //borderWidth={'1px'} borderColor={'gray.200'}
                                rounded={'md'} >
                                <Tabs
                                    w="100%"
                                    borderBottom={"0px"}
                                    mx="auto"
                                    onChange={handleTabChange}
                                    index={activeTab}
                                >
                                    <TabList border="0px" w="100%" h="5%">
                                        <Flex h={'30px'}>
                                            <Tab
                                                _selected={{
                                                    color: defaultThemeColor,
                                                    fontWeight: 600,
                                                    borderBottomColor: defaultThemeColor,
                                                }}
                                                fontSize="16px"
                                                px="6"
                                                py="2"
                                            >
                                                <Icon as={AiOutlineBulb} mr={1} />
                                                Email LingoLink
                                            </Tab>
                                            <Tab
                                                _selected={{
                                                    color: defaultThemeColor,
                                                    fontWeight: 600,
                                                    borderBottomColor: defaultThemeColor,
                                                }}
                                                fontSize="16px"
                                                px="6"
                                                py="2"
                                            >
                                                <Icon as={AiOutlineBulb} mr={1} />
                                                SMS LingoLink
                                            </Tab>
                                        </Flex>
                                    </TabList>
                                    <TabPanels mt="1" h="100%">
                                        <TabPanel h="100%" p={2}>
                                            <Flex w={'100%'} size={'xl'} direction={'column'}>
                                                <Text>{"Email LingoLink to Subscriber(s)"}</Text>
                                            </Flex>
                                            <Text mt={5} mb="2" fontSize={"sm"}>
                                                Please select which URL you want to share in  this email:
                                            </Text>
                                            <RadioGroup onChange={setUrlGroup} value={urlGroup}>
                                                <Stack direction='column'>
                                                    <Flex w={'100%'} direction={'column'} rounded={'md'} p={2} borderWidth={'0.5px'} borderColor={'green.500'}>
                                                        <Radio value={'10'}>{currentHost + linkInfo[0].url}</Radio>
                                                        <Text mt={1} fontSize={'sm'}>Note: Sharing this link URL will require you to share Link Access Code seperately to target user. Without code LingoLink is not accessible.</Text>
                                                    </Flex>
                                                    <Flex w={'100%'} direction={'column'} rounded={'md'} p={2} borderWidth={'0.5px'} borderColor={'green.500'}>
                                                        <Radio value={'11'}>{currentHost + linkInfo[0].fullurl}</Radio>
                                                        <Text mt={1} fontSize={'sm'}>Note: If you share this LingoLink, target user can access it without the code, because the code is embedded into the LingoLink.</Text>
                                                    </Flex>
                                                </Stack>
                                            </RadioGroup>
                                            <Text mb="2" fontSize={"md"} color={"blue.500"}>

                                            </Text>
                                            <FormLabel mt="3" ml="2" required>
                                                <Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
                                                    To Email Address(es) (Maxium 5 Emails):
                                                </Text>
                                                <TagsInput
                                                    style={{ width: '100%' }}
                                                    maxTags={5}
                                                    value={toEmails}
                                                    onChange={tags => setToEmails(tags)}
                                                    inputProps={{ placeholder: 'Enter Email' }}
                                                />
                                                <Flex w={'100%'}>
                                                    <Text
                                                        textAlign={"right"}
                                                        fontWeight={"500"}
                                                        fontSize={"sm"}
                                                        mt="2"
                                                    >
                                                        Please click ENTER at the end of email to add.
                                                    </Text>
                                                    <Spacer />
                                                    <Text
                                                        textAlign={"right"}
                                                        fontWeight={"500"}
                                                        fontSize={"sm"}
                                                        mt="2"
                                                    >
                                                        {JSON.stringify(toEmails)?.length}/100
                                                    </Text>
                                                </Flex>
                                                <Text mt={1}>
                                                    Note: An email will be sent to all above emails address with the
                                                    link
                                                </Text>
                                            </FormLabel>
                                            <FormLabel mt="3" ml="2" required>
                                                <Flex w={"100%"}>
                                                    <Checkbox
                                                        onChange={() => setSendCode(!sendCode)}
                                                        checked={sendCode}
                                                    ></Checkbox>
                                                    <Text ml={1}>
                                                        Do you want to add Link Access Code with Email too?
                                                    </Text>
                                                </Flex>
                                            </FormLabel>
                                            <Flex w={'100%'} mt={5}>
                                                <Button
                                                    colorScheme="blue"
                                                    onClick={() => {
                                                        shareDynaLink();
                                                    }}
                                                    isDisabled={
                                                        toEmails === null || toEmails?.length === 0 ? true : false
                                                    }
                                                    isLoading={saveDetailsLoader ? true : false}
                                                >
                                                    Send Email
                                                </Button>
                                            </Flex>                                            
                                        </TabPanel>
                                        <TabPanel p={2} h="100%">
                                            <Flex w={'100%'} size={'xl'} direction={'column'}>
                                                <Text>{"SMS LingoLink to Subscriber(s)"}</Text>
                                            </Flex>
                                            <Text mt={5} mb="2" fontSize={"sm"}>
                                                Please select which URL you want to share in  this SMS:
                                            </Text>
                                            <RadioGroup onChange={setUrlGroup} value={urlGroup}>
                                                <Stack direction='column'>
                                                    <Flex w={'100%'} direction={'column'} rounded={'md'} p={2} borderWidth={'0.5px'} borderColor={'green.500'}>
                                                        <Radio value={'10'}>{currentHost + linkInfo[0].url}</Radio>
                                                        <Text mt={1} fontSize={'sm'}>Note: Sharing this link URL will require you to share Link Access Code seperately to target user. Without code LingoLink is not accessible.</Text>
                                                    </Flex>
                                                    <Flex w={'100%'} direction={'column'} rounded={'md'} p={2} borderWidth={'0.5px'} borderColor={'green.500'}>
                                                        <Radio value={'11'}>{currentHost + linkInfo[0].fullurl}</Radio>
                                                        <Text mt={1} fontSize={'sm'}>Note: If you share this LingoLink, target user can access it without the code, because the code is embedded into the LingoLink.</Text>
                                                    </Flex>
                                                </Stack>
                                            </RadioGroup>
                                            <FormLabel mt="3" ml="2" required>
                                                <Text mb="2" fontWeight={"bold"} fontSize={"sm"}>
                                                    Phone Number (+1):
                                                </Text>
                                                <TagsInput
                                                    style={{ width: '100%' }}
                                                    maxTags={1}
                                                    value={toSms}
                                                    onChange={tags => setToSms(tags)}
                                                    inputProps={{ placeholder: 'Enter Phone Number' }}
                                                />
                                                <Flex w={'100%'}>
                                                    <Text
                                                        textAlign={"right"}
                                                        fontWeight={"500"}
                                                        fontSize={"sm"}
                                                        mt="2"
                                                    >
                                                        Please click ENTER at the end of Phone number.
                                                    </Text>
                                                    <Spacer />
                                                    <Text
                                                        textAlign={"right"}
                                                        fontWeight={"500"}
                                                        fontSize={"sm"}
                                                        mt="2"
                                                    >
                                                        {JSON.stringify(toSms)?.length}/20
                                                    </Text>
                                                </Flex>
                                                <Text mt={1}>
                                                    Note: A SMS will be sent to above phone number with selected link.
                                                </Text>
                                            </FormLabel>    
                                            <Flex w={'100%'} mt={20}>
                                                <Button
                                                    colorScheme="blue"
                                                    onClick={() => {
                                                        smsDynaLink();
                                                    }}
                                                    isDisabled={
                                                        toSms === null || toSms?.length === 0 ? true : false
                                                    }
                                                    isLoading={saveDetailsLoader ? true : false}
                                                >
                                                    Send SMS
                                                </Button>
                                            </Flex>                                         
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                                {/* <Tabs>
                                    <TabList>
                                        <Tab>Assign Customer</Tab>
                                        <Tab>Email Customer</Tab>
                                    </TabList>
                                    <TabPanels>
                                        <TabPanel>
                                            {linkCustomer?.length > 0
                                            ?<Flex w={'100%'} direction ={'column'} bg={'green.100'} rounded={'md'} p={2}>
                                                <Text w={'100%'}>Link is already shared with {linkCustomer?.length > 0 ? linkCustomer[0]?.email : 'xxx'}</Text>
                                            </Flex>
                                            :<Flex w={'100%'} direction ={'column'}>
                                                <Flex w={'100%'} direction={'column'}>
                                                    <Text>{"Assign LingoLink with Customers"}</Text>
                                                </Flex>
                                                <Flex w={'100%'} direction={'column'}>
                                                    {linkCustomers?.length > 0
                                                    ?<Flex w={'100%'} p={2}
                                                        rounded={'md'} borderWidth={'1px'} borderColor={'gray.200'}
                                                        direction={'column'}>
                                                        <Text mb={4} py={2}>Select customer from list below to share Lingolink:</Text>
                                                        <Select
                                                            value={customerInfo}
                                                            name="customerInfo"
                                                            id={'customerInfo'}
                                                            placeholder="Select Customer"
                                                            size="md"
                                                            // color={textColor}
                                                            borderRadius="16px"
                                                            onChange={e => {
                                                            setCustomerInfo(e.target.value);
                                                            }}
                                                        >
                                                        {renderAllCustomers}
                                                        </Select>
                                                        {customerInfo
                                                        ?<Button mt={4}  my={4} bg={'blue.100'} 
                                                        onClick={() => shareLinkWithSelectedCustomer(customerInfo)}
                                                        rounded={'md'} size={'sm'}>Share Link with above customer</Button>:null}
                                                    </Flex>
                                                    :<Text color={'red.300'}><Icon as={AiOutlineStop} mt={1} mr={1} /> Please add customers first to share LingoLink</Text>}
                                                </Flex>
                                            </Flex>}
                                        </TabPanel>
                                        <TabPanel>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs> */}
                            </Card>
                            <AlertDialog
                                isOpen={isAlertOpen}
                                leastDestructiveRef={cancelRef}
                                onClose={onAlertClose}
                            >
                                <AlertDialogOverlay>
                                    <AlertDialogContent>
                                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                            Disable Link
                                        </AlertDialogHeader>

                                        <AlertDialogBody>
                                            Are you sure? Disabled link instantly becomes unusable
                                        </AlertDialogBody>

                                        <AlertDialogFooter>
                                            <Button ref={cancelRef} onClick={onAlertClose}>
                                                Cancel
                                            </Button>
                                            <Button colorScheme='red' onClick={disableSelectedLink} ml={3}>
                                                Disable Link
                                            </Button>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>
                        </Flex>
                        : <Flex w={'100%'} p={2} bg={'red.100'}>
                            <Text color={ErrorText}>Error collecting Selected Link Details</Text>
                        </Flex>}
            </Box>
            <Modal finalFocusRef={qrRef} isOpen={isOpenQr} onClose={onCloseQr}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>QR Code for the LingoLink</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {modalQrCode != null
                            ? <Flex w={'100%'} direction={'column'}>
                                <QRCode
                                    title={currentHost + modalQrCode}
                                    size={90}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={currentHost + modalQrCode}
                                    viewBox={`0 0 90 90`}
                                />
                                <Text p={1} bg={'blue.100'}>{currentHost + modalQrCode}</Text>
                            </Flex>
                            : <Text color={'red.400'}>There is some issue with selected URL, please try again..</Text>}
                    </ModalBody>
                    <ModalFooter>
                        <Button size={'sm'} colorScheme='blue'
                            onClick={() => {
                                setModalQrCode(null);
                                onCloseQr();
                            }}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Drawer
                isOpen={isSideBarOpen}
                placement='right'
                onClose={handleSideBarClose}
                finalFocusRef={btnRef}
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Edit LingoLink</DrawerHeader>

                    <DrawerBody>
                        <FormControl>
                            <FormLabel>Email address</FormLabel>
                            <Flex w={'100%'} rounded={'md'}
                                mt={2} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
                                <InputField
                                    mb="0"
                                    me="30px"
                                    id="link_title"
                                    label="LingoLink Name"
                                    value={updatedValues.title}
                                    name="link_title"
                                    required="true"
                                    borderRadius="5px"
                                    bg="inherit"
                                    onChange={e => {
                                        setUpdatedValues(values => ({ ...values, title: e.target.value }));
                                    }}
                                    onBlur={e => handleBlur(e.target.name)}
                                    placeholder="Enter Link Name"
                                    w="100%"
                                    maxlength={100}
                                />
                                <Flex alignItems={"center"} justifyContent={"space-between"}>
                                    {linkTitleFlag ? (
                                        <Text fontSize="sm" color={"red"} mt="1">
                                            LingoLink Name is Required
                                        </Text>
                                    ) : null}
                                    <Spacer />
                                    <Text textAlign={"right"} fontWeight={"100"} fontSize={"sm"} mt="2">
                                        {updatedValues?.title?.length}/100
                                    </Text>
                                </Flex>
                            </Flex>
                            {
                                linkInfo[0]?.class === 3 && (
                                    <>
                                        <Flex w="full" p={3} mt={3} direction="column">
                                            <Flex w="full" direction="column">
                                                <Flex w="full" justifyContent="space-between">
                                                    <FormControl w="45%">
                                                        <FormLabel htmlFor="startDate" >Start Date-Time<Text color="red.400" as="span">*</Text></FormLabel>
                                                        {/* <Input required type="datetime-local" variant="outline" value={linkStartDateTime} onChange={e => setLinkStartDatetime(e.target.value)} /> */}
                                                        <Flatpickr options={{
                                                            enableTime: true,
                                                            dateFormat: "Y-m-d H:i",
                                                            minuteIncrement: 15,
                                                            minDate: 'today',
                                                        }}
                                                            altInputClass="flat-picker"
                                                            value={updatedValues?.linkStartDateTime}
                                                            name="link_start_date_time"
                                                            onBlur={(e) => {
                                                                handleBlur(e.target.name)
                                                            }}
                                                            onChange={value => setUpdatedValues(values => ({ ...values, linkStartDateTime: value[0] }))}
                                                            style={DatetimePickerStyles}
                                                            placeholder="Select Date Range"
                                                            id="startDate"
                                                        />
                                                    </FormControl>
                                                    <FormControl w="45%">
                                                        <FormLabel htmlFor="endDate" >End Date-Time<Text color="red.400" as="span">*</Text></FormLabel>
                                                        {/* <Input required type="datetime-local" variant="outline" value={linkStartDateTime} onChange={e => setLinkStartDatetime(e.target.value)} /> */}
                                                        <Flatpickr options={{
                                                            enableTime: true,
                                                            dateFormat: "Y-m-d H:i",
                                                            minuteIncrement: 15,
                                                            minDate: 'today',
                                                        }}
                                                            altInputClass="flat-picker"
                                                            onBlur={e => handleBlur(e.target.name)}
                                                            name="link_end_date_time"
                                                            value={updatedValues?.linkEndDateTime}
                                                            onChange={value => setUpdatedValues(values => ({ ...values, linkEndDateTime: value[0] }))}
                                                            style={DatetimePickerStyles}
                                                            placeholder="Select Date Range"
                                                            id="endDate"
                                                        />
                                                    </FormControl>
                                                </Flex>


                                                {errors?.durationError ? <Text color="red.600" fontSize="sm" mt={2}>{errors?.durationError}</Text> : null}
                                            </Flex>
                                            <Flex
                                                mt="3"
                                                w={"100%"}
                                                h={"140px"}
                                                direction={"column"}
                                                py={1}
                                                px={5}
                                                rounded={"md"}
                                                borderWidth={"0.5px"}
                                                borderColor={"gray.200"}>
                                                <Text>
                                                    Maximum minutes available in this call: <b>{updatedValues?.isCallDurationUnlimited ? 'unlimited' : updatedValues?.callTimeValue}</b>{" "}
                                                    minutes.
                                                </Text>
                                                <Checkbox mt='2' isChecked={updatedValues?.isCallDurationUnlimited} onChange={e => setUpdatedValues(values => ({ ...values, isCallDurationUnlimited: !values.isCallDurationUnlimited, callTimeValue: values.isCallDurationUnlimited ? values.callTimeValue : 9999 }))}>Make it unlimited</Checkbox>

                                                <Slider
                                                    isDisabled={updatedValues?.isCallDurationUnlimited}
                                                    mt={5}
                                                    aria-label="slider-ex-1"
                                                    colorScheme="teal"
                                                    min={5}
                                                    max={600}
                                                    step={5}
                                                    defaultValue={5}
                                                    onChange={v => setUpdatedValues(values => ({ ...values, callTimeValue: v }))}
                                                    onMouseEnter={() => setShowTooltip(true)}
                                                    onMouseLeave={() => setShowTooltip(false)}>
                                                    <SliderMark value={5} {...labelStylesVoice}>
                                                        5
                                                    </SliderMark>
                                                    <SliderMark value={100} {...labelStylesVoice}>
                                                        100
                                                    </SliderMark>
                                                    <SliderMark value={200} {...labelStylesVoice}>
                                                        200
                                                    </SliderMark>
                                                    <SliderMark value={300} {...labelStylesVoice}>
                                                        300
                                                    </SliderMark>
                                                    <SliderMark value={400} {...labelStylesVoice}>
                                                        400
                                                    </SliderMark>
                                                    <SliderMark value={500} {...labelStylesVoice}>
                                                        500
                                                    </SliderMark>
                                                    <SliderMark value={600} {...labelStylesVoice}>
                                                        600
                                                    </SliderMark>
                                                    <SliderTrack>
                                                        <SliderFilledTrack />
                                                    </SliderTrack>
                                                    <Tooltip
                                                        hasArrow
                                                        bg="teal.500"
                                                        color="white"
                                                        placement="top"
                                                        isOpen={showTooltip}
                                                        label={`${updatedValues?.callTimeValue}`}
                                                    >
                                                        <SliderThumb />
                                                    </Tooltip>
                                                </Slider>
                                            </Flex>
                                        </Flex>
                                        <Flex w={'100%'} rounded={'md'}
                                            mt={5} direction={'column'} p={2} borderWidth={'0.5px'} borderColor={'gray.200'}>
                                            <Text fontSize={'17px'} my={2} fontWeight={'bold'}>Select LingoLink Applications</Text>
                                            <CheckboxGroup colorScheme='blue' value={updatedValues?.selectedApps} onChange={values => setUpdatedValues(preValues => ({ ...preValues, selectedApps: values }))}>
                                                <SimpleGrid columns={2} gap="2px" mt="2" >
                                                    <Checkbox value='hi' display={subAppDetails?.includes('hi') ? 'flex' : 'none'} isChecked={updatedValues?.selectedApps?.indexOf('hi') > -1}>
                                                        <Flex alignItem='center'>
                                                            <Image h="40px" w="40px" mx="1	" borderRadius="50px" src={HumanAIBg} />
                                                            <Text as="span" >
                                                                Human Interpretation
                                                            </Text>
                                                        </Flex>
                                                    </Checkbox>
                                                    <Checkbox value='bi' display={subAppDetails?.includes('bi') ? 'flex' : 'none'} isChecked={updatedValues?.selectedApps?.indexOf('bi') > -1}>
                                                        <Flex alignItem='center'>
                                                            <Image h="40px" w="40px" mx="1	" borderRadius="50px" src={BasicAIBg} />
                                                            <Text as="span">
                                                                Secure AI
                                                            </Text>
                                                        </Flex>
                                                    </Checkbox>
                                                    {/* <Checkbox value='pi' display={subAppDetails?.includes('pi') ? 'flex' : 'none'} isChecked={updatedValues?.selectedApps?.indexOf('pi') > -1}>
                                                        <Flex alignItem='center'>
                                                            <Image h="40px" w="40px" mx="1" borderRadius="50px" src={PremiumAIBg} />
                                                            <Text as="span">
                                                                Premium AI
                                                            </Text>
                                                        </Flex>
                                                    </Checkbox> */}
                                                    <Checkbox value='di' display={subAppDetails?.includes('di') ? 'flex' : 'none'} isChecked={updatedValues?.selectedApps?.indexOf('di') > -1}>
                                                        <Flex alignItem='center'>
                                                            <Image h="40px" w="40px" mx="1" borderRadius="50px" src={DocumentAiLightBg} />
                                                            <Text as="span">
                                                                Document AI
                                                            </Text>
                                                        </Flex>
                                                    </Checkbox>
                                                </SimpleGrid>
                                            </CheckboxGroup>
                                        </Flex>
                                    </>
                                )
                            }
                        </FormControl>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant='outline' mr={3} onClick={handleSideBarClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='blue' isDisabled={isUpdateDisabled} onClick={handleUpdateLink}>Update</Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
}

export default LinkManager;
