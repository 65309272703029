import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Chakra Import
import {
	Box,
	Flex,
	Text,
    Icon,
	Select,
    Spacer,
	Button,
	Alert,
	Drawer,
	Spinner,
	AlertIcon,
	FormLabel,
	SimpleGrid,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useColorModeValue,
	useDisclosure,
	Textarea,
	useToast,
	Heading,
	Tooltip,
} from "@chakra-ui/react";
import InputField from "../../../components/fields/InputField";
import moment from "moment";
import Card from "../../../components/card/Card";
import Pagination from '../../../components/pagination/Pagination'
import {
	AiOutlineCopy,
    AiFillCaretRight,
    AiOutlineLink,
} from "react-icons/ai";

// Api Call Imports
import {
	fetchGenDevConnLogs,
} from "../../../services/businessServices";

// Component Imports
import { toastFunctionToaster } from "../../../utils/toastFunction";

import { useGreenBg, useLinkColor, useTextColor, 
    usePillButtonTextColorHover,usePillButtonHighLight,
    usePillButtonTextColorSelected, usePillButtonBg, usePillButtonBgHover } from "../../../theme/globalColorTheme";


function DevicesLogs() {
	const toast = useToast();
	const state = useState();
	const navigate = useNavigate();
	const textColor = useColorModeValue("secondaryGray.900", "white");

    const pillButtonBgSelected = usePillButtonTextColorSelected()
	const pillButtonBg = usePillButtonBg()
	const pillButtonBgHover = usePillButtonBgHover()
	const pillButtonTextColorHover = usePillButtonTextColorHover()
	const pillButtonText = useTextColor()
    const pillButtonBgHighlight = usePillButtonHighLight()

    const [devicesListLoading, setDevicesListLoading] = useState(false)
    const [devicesConnLogs, setDevicesConnLogs] = useState([])

    const [currPage, setCurrPage] = useState(1)
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [totalPages, setTotalPages] = useState(1)


    useEffect(() => {
        fetchGenDeviceLogs()
    }, [recordsPerPage, currPage])

    useEffect(() => {
        setCurrPage(1)
    }, [recordsPerPage])

    function fetchGenDeviceLogs() {
        setDevicesListLoading(true)
        let paramObj = {
            filter_key: null,
            filter_value: null,
            records_per_page:recordsPerPage,
            page_id:currPage
        }
        fetchGenDevConnLogs(paramObj)
        .then(res => {
            if (res?.data[0]?.status) {
                setDevicesConnLogs(res?.data[0]?.data)
                setTotalPages(res?.data[0]?.total_pages)
            } else {
                toast(toastFunctionToaster("Failed to get device connect logs due to no serial or mac info", "error"))
                setDevicesConnLogs([])
                setTotalPages(0)
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to get device connect logs due to no serial or mac info", "error"))
            setDevicesConnLogs([])
            setTotalPages(0)
        }).finally(() => {
            setDevicesListLoading(false)
        })
    }

    const deviceLogListing = devicesConnLogs?.length > 0 
    ? devicesConnLogs?.map((item, index) => (
        <Flex w={'100%'} 
            fontSize={'sm'} direction={'column'}
            key={index}  mt={2} borderBottomWidth={'0.05px'} borderBottomColor={'gray.200'}>
                <Flex w={'100%'}>
                    <Flex minW={'200px'} align={'center'}>
                        <Icon as={AiFillCaretRight} color={item?.is_connected ? 'green'  : 'red' } mr={1} />
                        {moment(item?.datetime).format("MMM DD,YYYY,HH:mm:ss a")}
                    </Flex>
                    <Flex minW={'50px'}>
                        {item?.stage}
                    </Flex>
                    <Flex minW={'100px'}>
                        {item?.type}
                    </Flex>
                    <Flex minW={'150px'}>
                        {item?.serial}
                    </Flex>
                    <Flex minW={'150px'}>
                        {item?.mac}
                    </Flex>
                    <Flex minW={'100%'}>
                        {item?.response}
                    </Flex>
                </Flex>
        </Flex>
        ))
    : <Text bg={'red.100'} px={2} rounded={'lg'}>This device does not have any connection logs...</Text>;

    const [filterValue, setFilterValue] = useState(null);
    const [filterKey, setFilterKey] = useState(null);



    return (
        <Flex w={'100%'} direction={'column'} pt={{ sm: '125px', lg: '75px' }} pl={3}>
            <Card  direction={'row'} w={'100%'} mt={2} p={2} h={'60px'}>
                <Flex w={'100%'} direction={'row'} align={'center'}>
                    <Select
                        w={'200px'}
                        value={filterValue}
                        name="filterValue"
                        id={'filterValue'}
                        placeholder="Search Text"
                        size="md"
                        color={textColor}
                        borderRadius="lg"
                        onChange={e => {
                            setFilterKey(e);
                        }}
                    >
                        <option value="mac">Mac Address</option>
                        <option value="serial">Serial Number</option>
                        <option value="log">Log Message</option>
                    </Select>
                    <InputField
                        me="230px"
                        name="filterValue"
                        id="filterValue"
                        value={filterValue}
                        onChange={() => setFilterValue()}
                        // label="Device  Search Text"
                        // required="true"
                        borderRadius="5px"
                        placeholder="Enter Search Text"
                        w="100%"
                        maxlength={50}
                    />
                    <Spacer />
                        <Button 
                            _hover={{ bg: pillButtonBgHighlight, color: "black" }}
                            color={pillButtonText}
                            bg={pillButtonBg}
                            onClick={() => fetchGenDeviceLogs()} 
                            rounded={'lg'} 
                            size={'xs'}>Reload</Button>
                        {/* <Button 
                            _hover={{ bg: pillButtonBgHighlight, color: "black" }}
                            color={pillButtonText}
                            bg={pillButtonBg}
                            onClick={() => {
                                navigate('/account/assigned_devices', {
                                    state: {
                                    },
                                });
                            }} 
                            rounded={'lg'} 
                            size={'xs'}>Back to Devices</Button> */}
                </Flex>
            </Card>
            <Flex w={'100%'} h={'100%'} mt={2}  direction="column" >
                <Card w={'100%'} mt={2} p={2}>
                    <Flex w={'100%'} h='calc(100vh - 250px)' overflow="auto" direction={'column'}>
                        {deviceLogListing}
                    </Flex>
                    {devicesConnLogs.length 
                        ?<Pagination currentPage={currPage} 
                                    recordsPerPage={recordsPerPage} 
                                    setCurrentPage={setCurrPage} 
                                    setRecordsPerPage={setRecordsPerPage} 
                                    totalPages={totalPages} /> 
                        : null}
                </Card>
            </Flex>
        </Flex>
    )
}

export default DevicesLogs