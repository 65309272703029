// Chakra React Imports
import React, { useState } from "react";
import {
	Box,
	Icon,
	Flex,
	Tab,
	Tabs,
	TabList,
	TabPanel,
	TabPanels,
} from "@chakra-ui/react";

import { AiOutlineBulb } from "react-icons/ai";

// Component Imports
import Card from "../../../components/card/Card";
import { defaultThemeColor } from "../../../constants";
import { fetchLocationsAndSubs } from "../../../services/accountServices";
import { useEffect } from "react";
import LocationEventsTab from "./reportingTab/LocationEventsTab";
import LingoCardCallsTab from "./reportingTab/locationCallHistory/lingoCardCallsTab";
import LocationCallHistoryTab from "./reportingTab/locationCallHistory/LocationCallHistoryTab";
import LocationSubscriberDashboard from "./reportingTab/subscriberDashboard/SubscriberDashboard";

function LocationReporting() {
	const [activeTab, setActiveTab] = useState(0);
	const [locationLoading, isLocationLoading] = useState(false)
	const [locationList, setLocationList] = useState([])
	// Todo:Tab Change
	const handleTabChange = index => {
		setActiveTab(index);
	};

	const collectLocationWithSubs = () => {
		isLocationLoading(true);
		let paramObj = {
			link_account_uuid: null,
			key: null,
			records_per_page: 0,
			page_id: 0,
		};
		fetchLocationsAndSubs(paramObj)
			.then(res => {
				isLocationLoading(false);
				if (res?.data[0]?.locations) {
					setLocationList(res?.data[0]?.locations);
				} else {
					setLocationList([]);
				}
				isLocationLoading(false)
			})
			.catch(err => {
				isLocationLoading(false);
				setLocationList([]);
			});
	};

	useEffect(() => {
		collectLocationWithSubs()
	}, [])

	return (
		<Flex direction="column" pt={{ sm: "125px", lg: "75px" }} mt="4">
			{/* <Card w={"100%"} rounded={"md"} p={1} mb={2} mx={3}>
				<Box textAlign="right">
					<ReportingPanel />
				</Box>
			</Card> */}
			<Card w={"100%"} h={'100%'} rounded={"md"} p={1} mx={3}>
				<Tabs
					w="100%"
					borderBottom={"0px"}
					mx="auto"
					onChange={handleTabChange}
					index={activeTab}
				>
					<TabList border="0px" w="100%">
						<Flex justifyContent={"end"} w="100%">
							<Flex>
							<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Subscriber Dashboard
								</Tab>

								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Call History
								</Tab>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Lingo Calls
								</Tab>
								<Tab
									_selected={{
										color: defaultThemeColor,
										fontWeight: 600,
										borderBottomColor: defaultThemeColor,
									}}
									fontSize="16px"
									px="6"
									py="2"
								>
									<Icon as={AiOutlineBulb} mr={1} />
									Activity (Events)
								</Tab>
							</Flex>
						</Flex>
					</TabList>
					<TabPanels mt="1" h="100%">
					<TabPanel p="0">
							<LocationSubscriberDashboard locationLoading={locationLoading} locationList={locationList} setLocationList={setLocationList}/>
						</TabPanel>
						<TabPanel p="0">
							<LocationCallHistoryTab locationLoading={locationLoading} locationList={locationList} setLocationList={setLocationList}/>
						</TabPanel>
						<TabPanel p="0" h="100%">
							<LingoCardCallsTab locationLoading={locationLoading} locationList={locationList} setLocationList={setLocationList}/>
						</TabPanel>
						<TabPanel p="0" h="100%">
							<LocationEventsTab 
								locationLoading={locationLoading} locationList={locationList} setLocationList={setLocationList}/>
							{/* <LocationEventsTab /> */}
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Card>
		</Flex>
	);
}

export default LocationReporting;
