// Chakra imports
import {
  Icon,
  Portal,
  Box,
  useDisclosure,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Footer from "../components/footer/FooterAdmin";
// Layout components
import Navbar from "../components/navbar/NavbarAdmin";
import Sidebar from "../components/sidebar/Sidebar";
import { SidebarContext } from "../contexts/SidebarContext";
import { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { BiCaretRightCircle, BiCaretLeftCircle } from "react-icons/bi";

// Avkash I have replaced this with the Account Routes
import AccountRoutes from "./AccountRoutes";
import { fetchLocationInfo } from "../services/accountServices";
import { toastFunctionToaster } from "../utils/toastFunction";
import { connect } from "react-redux";
import { ACCOUNT_ACCESS_CLASS } from "../komponents/business/accounts/accountmanagement/constants";

// Custom Chakra theme
function AccountLayouts(props) {
  const { ...rest } = props;
  const location = useLocation();

  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [mini, setMini] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [isLspAccountType, setIsLspAccountType] = useState(false)
  const [accountType, setAccountType] = useState(ACCOUNT_ACCESS_CLASS.BASIC)
  const [routes, setRoutes] = useState(AccountRoutes())
  const [LogoUrl, setLogoUrl] = useState(null)
  const [deviceSupport, setDeviceSupport] = useState(false)
  const [schedulerSupport, setSchedulerSupport] = useState(false)
  const [translationSupport, setTranslationSupport] = useState(false)

  const toast = useToast()
  const fetchAccountDetails = () => {
    let paramObj = {
      key: 'location',
    };
    fetchLocationInfo(paramObj).then(resp => {
      setLogoUrl(resp?.data?.[0]?.info?.[0]?.logo)
      if (resp?.data[0]?.info[0]?.account_associate_support) {
        setIsLspAccountType(true)
        // toast(toastFunctionToaster(`Language Associate disabled for accounts other than "LSP"`, "error"))
      } else {
        setIsLspAccountType(false)
      }
      if(resp?.data[0]?.info[0]?.account_access_class) setAccountType(resp?.data[0]?.info[0]?.account_access_class)
      if(resp?.data[0]?.info[0]?.translation_support) setTranslationSupport(resp?.data[0]?.info[0]?.translation_support)
      if(resp?.data[0]?.info[0]?.device_support) setDeviceSupport(resp?.data[0]?.info[0]?.device_support)
      if(resp?.data[0]?.info[0]?.scheduler_support) setSchedulerSupport(resp?.data[0]?.info[0]?.scheduler_support)
    }).catch(err => {
      setIsLspAccountType(false)
    })
  }
  useEffect(() => {
    fetchAccountDetails()
  }, [props.accountUploadFlag])

  useEffect(()=>{
    setRoutes(AccountRoutes(isLspAccountType, accountType,translationSupport, deviceSupport, schedulerSupport ))
  },[isLspAccountType, accountType, deviceSupport, translationSupport, schedulerSupport])


  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/account/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Account";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };  
  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.layout === "/account") {
        return (
          <Route path={`${route.path}`} element={route.component} key={key} />
        );
      }
      if (route.collapse) {
        return getRoutes(route.items);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  useEffect(() => {
    getActiveRoute(routes);
  }, [location]);
  const { onOpen } = useDisclosure();
  const bg = useColorModeValue("background.100", "background.900");
  return (
    <Box bg={bg} h="100vh" w="100vw" position={"relative"}>
      {/* The following section is the previous Left side Menu Minimize Icon */}
      {/* <Icon
        onClick={() => {
          setMini(!mini);
        }}
        fontSize="30px"
        cursor={"pointer"}
        color="gray.400"
        position="absolute"
        as={mini === true ? BiCaretRightCircle : BiCaretLeftCircle}
        zIndex={"9"}
        top="8%"
        left={mini === true ? "6%" : "15%"}
        display={{
          base: "none",
          md: "none",
          xl: "block",
          "2xl": "block",
        }}
      ></Icon> */}
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar
          bg={'red'}
          hovered={hovered}
          setHovered={setHovered}
          mini={mini}
          routes={routes}
          display="none"
          logoUrl={LogoUrl}
          setMini={setMini}
          {...rest}
        />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={
            mini === false
              ? { base: "100%", xl: "calc( 100% - 290px )" }
              : mini === true && hovered === true
                ? { base: "100%", xl: "calc( 100% - 290px )" }
                : { base: "100%", xl: "calc( 100% - 120px )" }
          }
          maxWidth={
            mini === false
              ? { base: "100%", xl: "calc( 100% - 290px )" }
              : mini === true && hovered === true
                ? { base: "100%", xl: "calc( 100% - 290px )" }
                : { base: "100%", xl: "calc( 100% - 120px )" }
          }
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                hovered={hovered}
                setMini={setMini}
                mini={mini}
                onOpen={onOpen}
                logoText={"Horizon UI Dashboard PRO"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                theme={props.theme}
                setTheme={props.setTheme}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: "20px", md: "30px" }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Routes>
                {getRoutes(routes)}
                <Route
                  path="/"
                  element={<Navigate to="/account/dashboard" replace />}
                />
              </Routes>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  accountUploadFlag: state.notificationHeader.accountUploadFlag,
});

export default connect(mapStateToProps)(AccountLayouts)
