// Chakra React Imports
import React, { useState } from "react";
import { Text, Box, Flex, Spinner, useColorModeValue, Input, TableContainer, Heading, Menu, MenuButton, Button, MenuList, MenuItem, Tabs, TabList, Tab, TabPanels, TabPanel, FormControl, FormLabel, Switch, useToast, Icon } from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle, AiOutlineCheckCircle } from "react-icons/ai";
// import Markdown from 'react-markdown';

// Component Imports
import { useBrandBg, useGreenBg, useGreenButton, useRedBg, useRedButton, useTextColor } from "../../../../../theme/globalColorTheme";
import Card from "../../../../../components/card/Card";
import LocationAndSubscribers from "../../components/LocationAndSubscribers";
import { getSubsLingoCards } from "../../../../../services/accountServices";
import { LOC_CALL_HISTORY } from "../constants";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import { useEffect } from "react";
import { getAccAdminCardCallList } from "../../../../../services/businessServices";
import LingoCardHistory from "../../components/LingoCardHistory";

function LingoCardCallsTab({ locationList, setLocationList, locationLoading }) {
    const brandBg = useBrandBg();
    const [configurationCollapseFlag, setConfiguationCollapseFlag] =
        useState(false);
    const [subsId, setSubsId] = useState(null)
    const [subscriber, setSubscriber] = useState(null)
    const blackWhiteColor = useTextColor()
    const pillButtonBg = useColorModeValue("blue.100", "gray.600");
    const pillButtonBgHighlight = useColorModeValue("blue.300", "gray.100");
    const [splitTabs, setSplitTabs] = useState(false)
    const [cardsPageId, setCardsPageId] = useState(1)
    const [cards, setCards] = useState(null)
    const [cardsLoading, setCardsLoading] = useState(false)
    const [showLoadMoreLoading, setShowLoadMoreLoading] = useState(false)
    const [isLoadMoreDisabled, setLoadMoreDisabled] = useState(false)
    const [cardFilter, setCardFilter] = useState(LOC_CALL_HISTORY.LINGO_CARDS_FILTER.ACTIVE)
    const toast = useToast()
    const [selectedLingoCard, setSelectedLingoCard] = useState('')
    const [duration, setDuration] = useState(LOC_CALL_HISTORY.DURATION.TODAY)
    const [cardHistoryLoader, setCardHistoryLoader] = useState(false)
    const [cardSessions, setCardSessions] = useState(null)
    const [totalCalls, setTotalCalls] = useState(0);
    const [callsFailed, setCallsFailed] = useState(0);
    const [validationFailed, setValidationFailed] = useState(0);
    const [totalTime, setTotalTime] = useState("");


    function onSubscriberClick(locationData, subsData) {
        setSubscriber({
            location: locationData,
            subscriber: subsData
        })
        setConfiguationCollapseFlag(true)
    }

    async function getAllLingoCards() {
        if (!subscriber) return
        try {
            const paramObj = {
                link_account_uuid: subscriber?.location?.link_account_uuid,
                link_location_uuid: subscriber?.location?.link_location_uuid,
                subscriber_uuid: subscriber?.subscriber?.sub_uuid,
                page_id: cardsPageId,
                key: cardFilter,
                records_per_page: 10,
            };

            cards?.length ? setShowLoadMoreLoading(true) : setCardsLoading(true);

            const resp = await getSubsLingoCards(paramObj);

            const records = resp.data[0]?.records;

            if (!records?.length) {
                setLoadMoreDisabled(true);
                setCardsLoading(false);
                setShowLoadMoreLoading(false);
                return;
            }
            setLoadMoreDisabled(false);
            setCards(prevLinks => cards?.length ? [...prevLinks, ...records] : records);


        } catch (err) {
            toast(toastFunctionToaster("Failed to get lingoCards", "error"));
            setCards([]);
        } finally {
            setCardsLoading(false);
            setShowLoadMoreLoading(false);
        }
    }

    function handleLoadMoreLinks() {
        setCardsPageId(pageId => pageId + 1)
    }

    function handleShowCardCalls(item) {
        setSelectedLingoCard(item?.lingo_card_uuid)
        getCardCallHistory(item?.lingo_card_uuid)
    }

    function formatMinutesToHoursAndMinutes(totalMinutes) {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        let result = '';
        if (hours > 0) {
            result += `${hours} hour${hours > 1 ? 's' : ''}`;
        }
        if (minutes > 0) {
            result += (result ? ', ' : '') + `${minutes} minute${minutes > 1 ? 's' : ''}`;
        }

        return result || '0 minutes';
    }

    function getCardCallHistory(uuid) {
        const paramObj = {
            account_uuid: null,
            lingo_card_uuid: uuid,
            duration: duration,
            page_id: 1,
            records_per_page: 10,
        }
        setCardHistoryLoader(true)
        getAccAdminCardCallList(paramObj).then(res => {
            if (res?.data?.[0]?.records?.length) {
                const filteredCardCalls = res?.data?.[0]?.records
                setCardSessions(filteredCardCalls);
                setTotalCalls(filteredCardCalls.length);
                setCallsFailed(filteredCardCalls?.filter(item => item?.lingo_card_call_status !== "Completed").length);
                setValidationFailed(filteredCardCalls?.filter(item => !item?.pin_code_validation).length);

                const totalTimeInMinutes = filteredCardCalls?.reduce((acc, t) => acc += t?.call_duration_minute, 0);
                setTotalTime(formatMinutesToHoursAndMinutes(totalTimeInMinutes));
                // setLinkReport(res?.data[0]?.report);
            } else {
                setCardSessions([])
            }
        }).catch(err => {
            toast(toastFunctionToaster("Failed to get Call history", "error"))
            setCardSessions([])

        }).finally(() => {
            setCardHistoryLoader(false)
        })
    }

    useEffect(() => {
        getAllLingoCards()
    }, [subscriber, cardFilter, cardsPageId])

    useEffect(() => {
        setCards([])
    }, [cardFilter])

    return (
        <Flex h="75vh">
            <Flex
                flex="1"
                bg={brandBg}
                borderRadius={"10px"}
                h="full"
                p={1}
            >
                <Box w={!configurationCollapseFlag ? "95%" : "80%"}>
                    <Card p='1' h="full">
                        {
                            cardHistoryLoader ? <Flex w="100%" justifyContent="center">
                                <Spinner
                                    thickness='2px'
                                    speed='0.65s'
                                    size='xl'
                                />
                            </Flex> : <LingoCardHistory callsFailed={callsFailed} cardSessions={cardSessions} selectedLingoCard={selectedLingoCard} totalCalls={totalCalls} totalTime={totalTime} validationFailed={validationFailed} />
                        }
                    </Card>
                </Box>
                {configurationCollapseFlag ? (
                    <Box
                        h="full"
                        w="20%"
                        position="relative"
                        borderRadius="15px"
                        bg={brandBg}
                        ml="2"
                    >
                        <Flex w={'100%'}
                            p={2}
                            h="8%"
                            //bg={'gray.100'}
                            borderBottomWidth={'0.5px'}
                            borderBottomColor={'gray.300'}
                        >
                            <Box w={'80%'}>
                                <FormControl display='flex' alignItems='center' mb={1} mt="1">
                                    <FormLabel htmlFor='splitTabs' mb='0'>
                                        Split Tabs
                                    </FormLabel>
                                    <Switch id='splitTabs' value={splitTabs} onChange={() => setSplitTabs(splitTab => !splitTab)} />
                                </FormControl>
                            </Box>
                            <Box
                                align={'end'}
                                w={'20%'}
                                position="absolute"
                                top="0"
                                right="0"
                                mr="2"
                                mt="3"
                                zIndex="999"
                            >
                                <AiFillRightCircle
                                    cursor="pointer"
                                    size="20px"
                                    onClick={() => {
                                        setConfiguationCollapseFlag(
                                            !configurationCollapseFlag
                                        );
                                    }}
                                />
                            </Box>
                        </Flex>
                        {splitTabs ?
                            <Flex direction="column" h="91%" p={2} >
                                <Card w="full" p={1} mt="1" flex="1">
                                    <Flex flexDir="column" w="full" alignItems="center" h="100%" overflow="hidden">
                                        <Flex flexDir="column" overflow="auto" w="100%" h="89%" p='1' mt={2} className="custom-scrollbar"
                                        >
                                            <Flex flexDir="column" minH="100%">
                                                {
                                                    cardsLoading ? <Flex w="100%" justifyContent="center">
                                                        <Spinner
                                                            thickness='2px'
                                                            speed='0.65s'
                                                            size='xl'
                                                        />
                                                    </Flex> :
                                                        <Flex flexDir="column">
                                                            {
                                                                (cards && cards?.length) ? cards.map((card) => (
                                                                    <Flex flexDir="column" gap={1} p="2"
                                                                        cursor="pointer"
                                                                        _hover={{ bg: "blue.100", color: 'black' }}
                                                                        mb="2"
                                                                        borderWidth="1px"
                                                                        borderColor="#d7d7d7"
                                                                        borderRadius="5px"
                                                                        bg={selectedLingoCard === card.lingo_card_uuid ? useGreenButton : null}
                                                                        onClick={() => handleShowCardCalls(card)}
                                                                    >
                                                                        <Text fontWeight="bold">{card?.lingo_card_title}</Text>
                                                                        {/* <Text fontSize="smaller">{card?.url}</Text> */}
                                                                    </Flex>
                                                                )) : <Flex
                                                                    w={"100%"}
                                                                    p={5}
                                                                    bg={'red.100'}
                                                                    borderLeftWidth={"5px"}
                                                                    borderLeftColor={"red.500"}
                                                                >
                                                                    <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                                                                        No LingoCard Found!
                                                                    </Heading>
                                                                </Flex>
                                                            }
                                                            {(cards && cards?.length) ? <Button colorScheme="green" p={3} rounded="md" isLoading={showLoadMoreLoading} onClick={handleLoadMoreLinks} isDisabled={isLoadMoreDisabled}>Load more</Button> : null}
                                                        </Flex>
                                                }
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Card>

                                <Card w="full" p={1} mt="4" h="45%">
                                    <Flex flexDir="column" w="full" alignItems="center" h="full" overflow="hidden">
                                        <Flex flexDir="column" w="full" alignItems="center" h="100%" overflowY="auto" className="custom-scrollbar">
                                            <Flex align={"center"} p={2} w="full">
                                                <Box
                                                    p={1}
                                                    align={"center"}
                                                    w={"100px"}
                                                    _hover={{ cursor: "pointer" }}
                                                    roundedLeft={"lg"}
                                                    bg={cardFilter === LOC_CALL_HISTORY.LINGO_CARDS_FILTER.ACTIVE ? "green.600" : useGreenButton}
                                                    color={cardFilter === LOC_CALL_HISTORY.LINGO_CARDS_FILTER.ACTIVE ? "white" : "inherit"}
                                                    size="sm"
                                                    onClick={() => {
                                                        setCardFilter(LOC_CALL_HISTORY.LINGO_CARDS_FILTER.ACTIVE);
                                                    }}>
                                                    {cardFilter === LOC_CALL_HISTORY.LINGO_CARDS_FILTER.ACTIVE ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                    Active
                                                </Box>

                                                <Box
                                                    p={1}
                                                    align={"center"}
                                                    w={"100px"}
                                                    _hover={{ cursor: "pointer" }}
                                                    roundedRight={"lg"}
                                                    bg={cardFilter === LOC_CALL_HISTORY.LINGO_CARDS_FILTER.CLOSED ? "red.400" : useRedButton}
                                                    color={cardFilter === LOC_CALL_HISTORY.LINGO_CARDS_FILTER.CLOSED ? "white" : "inherit"}
                                                    size="sm"
                                                    onClick={() => {
                                                        setCardFilter(LOC_CALL_HISTORY.LINGO_CARDS_FILTER.CLOSED);
                                                    }}>
                                                    {cardFilter === LOC_CALL_HISTORY.LINGO_CARDS_FILTER.CLOSED ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                    Closed
                                                </Box>
                                            </Flex>
                                            <Flex direction="column" w="100%" pt="1" borderTop="1px" mt={2}>
                                                <Text fontWeight="bold" mb="3">Duration</Text>
                                                <Flex alignItems="center" w="100%">
                                                    <Box
                                                        h="full"
                                                        p={1}
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        w={"100px"}
                                                        _hover={{ cursor: "pointer" }}
                                                        roundedLeft={"lg"}
                                                        bg={duration === LOC_CALL_HISTORY.DURATION.TODAY ? "green.600" : useGreenBg}
                                                        color={duration === LOC_CALL_HISTORY.DURATION.TODAY ? "white" : "inherit"}
                                                        size="sm"
                                                        fontSize="sm"
                                                        onClick={() => { setDuration(LOC_CALL_HISTORY.DURATION.TODAY) }}
                                                    >
                                                        {duration === LOC_CALL_HISTORY.DURATION.TODAY ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}

                                                        Today
                                                    </Box>
                                                    <Box
                                                        h="full"
                                                        p={1}
                                                        borderRight="1px"
                                                        borderLeft="1px"
                                                        borderColor="white"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        w={"100px"}
                                                        _hover={{ cursor: "pointer" }}
                                                        bg={duration === LOC_CALL_HISTORY.DURATION.WEEK ? "green.600" : useGreenBg}
                                                        color={duration === LOC_CALL_HISTORY.DURATION.WEEK ? "white" : "inherit"}
                                                        size="sm"
                                                        fontSize="sm"
                                                        onClick={() => { setDuration(LOC_CALL_HISTORY.DURATION.WEEK) }}
                                                    >
                                                        {duration === LOC_CALL_HISTORY.DURATION.WEEK ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                        This Week
                                                    </Box>
                                                    <Box
                                                        p={1}
                                                        h="full"
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        w={"100px"}
                                                        _hover={{ cursor: "pointer" }}
                                                        roundedRight={"lg"}
                                                        fontSize="sm"
                                                        bg={duration === LOC_CALL_HISTORY.DURATION.MONTH ? "green.600" : useGreenBg}
                                                        color={duration === LOC_CALL_HISTORY.DURATION.MONTH ? "white" : "inherit"}
                                                        size="sm"
                                                        onClick={() => { setDuration(LOC_CALL_HISTORY.DURATION.MONTH) }}
                                                    >
                                                        {duration === LOC_CALL_HISTORY.DURATION.MONTH ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                        This Month
                                                    </Box>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        <Button size={'sm'} ml={1}
                                            onClick={()=>getCardCallHistory(selectedLingoCard)}
                                            _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
                                            bg={pillButtonBg}>Get History</Button>
                                    </Flex>
                                </Card>
                            </Flex> :
                            <Box w="100%" h="92%" bg={brandBg}>
                                <Tabs variant="enclosed-colored" colorScheme="green" mt="1" h="full" isFitted>
                                    <TabList>
                                        <Tab fontSize="xs" fontWeight="bold">Lingocards</Tab>
                                        <Tab fontSize="xs" fontWeight="bold">Filters</Tab>
                                    </TabList>
                                    <TabPanels h="98%">
                                        <TabPanel p="0" h="full" mt="2">
                                            <Card
                                                w="full"
                                                h="92%"
                                                p="2"
                                                overflowY="auto"
                                                pt="3"

                                            >
                                                <Flex flexDir="column" w="full" alignItems="center" h="100%">
                                                    <Flex flexDir="column" overflow="auto" w="100%" h="89%" p='1' mt={2} className="custom-scrollbar"
                                                    >
                                                        <Flex flexDir="column" minH="100%">
                                                            {
                                                                cardsLoading ? <Flex w="100%" justifyContent="center">
                                                                    <Spinner
                                                                        thickness='2px'
                                                                        speed='0.65s'
                                                                        size='xl'
                                                                    />
                                                                </Flex> :
                                                                    <Flex flexDir="column">
                                                                        {
                                                                            (cards && cards?.length) ? cards.map((card) => (
                                                                                <Flex flexDir="column" gap={1} p="2"
                                                                                    cursor="pointer"
                                                                                    _hover={{ bg: "blue.100", color: 'black' }}
                                                                                    mb="2"
                                                                                    borderWidth="1px"
                                                                                    borderColor="#d7d7d7"
                                                                                    borderRadius="5px"
                                                                                    bg={selectedLingoCard === card.lingo_card_uuid ? useGreenButton : null}
                                                                                    onClick={() => handleShowCardCalls(card)}
                                                                                >
                                                                                    <Text fontWeight="bold">{card?.lingo_card_title}</Text>
                                                                                    {/* <Text fontSize="smaller">{card?.url}</Text> */}
                                                                                </Flex>
                                                                            )) : <Flex
                                                                                w={"100%"}
                                                                                p={5}
                                                                                bg={'red.100'}
                                                                                borderLeftWidth={"5px"}
                                                                                borderLeftColor={"red.500"}
                                                                            >
                                                                                <Heading w={"100%"} fontSize={"16px"} fontWeight={"normal"} color="black">
                                                                                    No LingoCard Found!
                                                                                </Heading>
                                                                            </Flex>
                                                                        }
                                                                        {(cards && cards?.length) ? <Button colorScheme="green" p={3} rounded="md" isLoading={showLoadMoreLoading} onClick={handleLoadMoreLinks} isDisabled={isLoadMoreDisabled}>Load more</Button> : null}
                                                                    </Flex>
                                                            }

                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            </Card>
                                        </TabPanel>
                                        <TabPanel p="0" h="full" mt="2">
                                            <Card h="92%" w="full" p={1}>
                                                <Flex flexDir="column" w="full" alignItems="center" h="90%" overflowY="auto">
                                                    <Flex align={"center"} p={2} w="full">
                                                        <Box
                                                            p={1}
                                                            align={"center"}
                                                            w={"100px"}
                                                            _hover={{ cursor: "pointer" }}
                                                            roundedLeft={"lg"}
                                                            bg={cardFilter === LOC_CALL_HISTORY.LINGO_CARDS_FILTER.ACTIVE ? "green.600" : useGreenButton}
                                                            color={cardFilter === LOC_CALL_HISTORY.LINGO_CARDS_FILTER.ACTIVE ? "white" : "inherit"}
                                                            size="sm"
                                                            onClick={() => {
                                                                setCardFilter(LOC_CALL_HISTORY.LINGO_CARDS_FILTER.ACTIVE);
                                                            }}>
                                                            {cardFilter === LOC_CALL_HISTORY.LINGO_CARDS_FILTER.ACTIVE ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                            Active
                                                        </Box>

                                                        <Box
                                                            p={1}
                                                            align={"center"}
                                                            w={"100px"}
                                                            _hover={{ cursor: "pointer" }}
                                                            roundedRight={"lg"}
                                                            bg={cardFilter === LOC_CALL_HISTORY.LINGO_CARDS_FILTER.CLOSED ? "red.400" : useRedButton}
                                                            color={cardFilter === LOC_CALL_HISTORY.LINGO_CARDS_FILTER.CLOSED ? "white" : "inherit"}
                                                            size="sm"
                                                            onClick={() => {
                                                                setCardFilter(LOC_CALL_HISTORY.LINGO_CARDS_FILTER.CLOSED);
                                                            }}>
                                                            {cardFilter === LOC_CALL_HISTORY.LINGO_CARDS_FILTER.CLOSED ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                            Closed
                                                        </Box>
                                                    </Flex>
                                                    <Flex direction="column" w="100%" pt="1" borderTop="1px" mt={2}>
                                                        <Text fontWeight="bold" mb="3">Duration</Text>
                                                        <Flex alignItems="center" w="100%">
                                                            <Box
                                                                h="full"
                                                                p={1}
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                w={"100px"}
                                                                _hover={{ cursor: "pointer" }}
                                                                roundedLeft={"lg"}
                                                                bg={duration === LOC_CALL_HISTORY.DURATION.TODAY ? "green.600" : useGreenBg}
                                                                color={duration === LOC_CALL_HISTORY.DURATION.TODAY ? "white" : "inherit"}
                                                                size="sm"
                                                                fontSize="sm"
                                                                onClick={() => { setDuration(LOC_CALL_HISTORY.DURATION.TODAY) }}
                                                            >
                                                                {duration === LOC_CALL_HISTORY.DURATION.TODAY ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}

                                                                Today
                                                            </Box>
                                                            <Box
                                                                h="full"
                                                                p={1}
                                                                borderRight="1px"
                                                                borderLeft="1px"
                                                                borderColor="white"
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                w={"100px"}
                                                                _hover={{ cursor: "pointer" }}
                                                                bg={duration === LOC_CALL_HISTORY.DURATION.WEEK ? "green.600" : useGreenBg}
                                                                color={duration === LOC_CALL_HISTORY.DURATION.WEEK ? "white" : "inherit"}
                                                                size="sm"
                                                                fontSize="sm"
                                                                onClick={() => { setDuration(LOC_CALL_HISTORY.DURATION.WEEK) }}
                                                            >
                                                                {duration === LOC_CALL_HISTORY.DURATION.WEEK ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                                This Week
                                                            </Box>
                                                            <Box
                                                                p={1}
                                                                h="full"
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                w={"100px"}
                                                                _hover={{ cursor: "pointer" }}
                                                                roundedRight={"lg"}
                                                                fontSize="sm"
                                                                bg={duration === LOC_CALL_HISTORY.DURATION.MONTH ? "green.600" : useGreenBg}
                                                                color={duration === LOC_CALL_HISTORY.DURATION.MONTH ? "white" : "inherit"}
                                                                size="sm"
                                                                onClick={() => { setDuration(LOC_CALL_HISTORY.DURATION.MONTH) }}
                                                            >
                                                                {duration === LOC_CALL_HISTORY.DURATION.MONTH ? <Icon as={AiOutlineCheckCircle} mr={1} /> : null}
                                                                This Month
                                                            </Box>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                <Button size={'sm'} ml={1}
                                                    onClick={()=>getCardCallHistory(selectedLingoCard)}
                                                    _hover={{ bg: pillButtonBgHighlight, color: "black" }} color={blackWhiteColor}
                                                    bg={pillButtonBg}>Get History</Button>
                                            </Card>
                                        </TabPanel>
                                    </TabPanels>
                                </Tabs>
                            </Box>}

                    </Box>
                ) : (
                    <Card
                        w="5%"
                        p="3"
                        borderRadius="10px"
                        h="100%"
                        ml="2"
                        pl="5"
                    >
                        <Flex w={"100%"} h="100%" direction="column" alignItems="center">
                            <AiFillLeftCircle
                                cursor="pointer"
                                onClick={() => {
                                    setConfiguationCollapseFlag(
                                        !configurationCollapseFlag
                                    );
                                }}
                                size="20px"
                            />
                            <Text
                                fontSize="16px"
                                mt="2"
                                css={{
                                    writingMode: "vertical-lr",
                                    textOrientation: "mixed",
                                }}
                            >
                                Expand Report Settings
                            </Text>
                        </Flex>
                    </Card>
                )}
            </Flex>
            <LocationAndSubscribers loading={locationLoading} locationList={locationList} setLocationList={setLocationList} subsId={subscriber?.subscriber?.user_uuid} onClick={onSubscriberClick} />
        </Flex>
    );
}

export default LingoCardCallsTab;
